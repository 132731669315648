import { FC, useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
// kendo
import { Button } from "@/components";
import CommonModal from '@/mui/components/CommonModal';
// components
import EmailModal from './emailActivity/emailModal';
import EmailContentsModal from './emailActivity/EmailContentsModal';
import { AccountsMainPanel } from '@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel';
import { LogCallModal } from './logCallActivity/LogCallModal';
import { ChatBox } from './chatBox';
import { NotesTextBox } from './noteActivity/notesTextBox';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { notesService } from '@/services/notesService';
import { accountsService } from '@/services/accountsService';
import { Email, Note, Payment, Messages, customerService, Call } from '@/services/customerService';
import { formatDate } from '@/utils/helpers/general';
import { getRouteParamNum } from '@/utils/routing/formatting';
import QuickActionsModal from '@/features/Accounts/accountsSubviews/AccountDetail/components/quickActionsModal/QuickActionsModal';
// interfaces
import { CustomerActivity, ActivityType } from './activityPanelInterfaces';
import { ActivityLog } from '@/interfaces';
// style
import styles from './activityPanel.module.scss';
import { companyService } from '@/services/companyService';
import EmailActivityCard from './emailActivity/EmailActivityCard';
import LogCallActivityCard from './logCallActivity/LogCallActivityCard';
import SmsActivityCard from './smsActivity/SmsActivityCard';
import NoteActivityCard from './noteActivity/NoteActivityCard';
import PaymentActivityCard from './paymentActivity/PaymentActivityCard';
import LogActivityCard from './logActivity/LogActivityCard';
import useReq from '@utils/useReq';
import dayjs from 'dayjs';

const ActivityPanel: FC = () => {
  const colRecId = getRouteParamNum(useParams().colRecId);

  // Basic state
  const [callerPhoneNumber, setCallerPhoneNumber] = useState('');
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const contactInformation = useAccountSelector((s) => s.contactInformation);

  const [searchParams, setSearchParams] = useSearchParams();
  const panelRef = useRef<HTMLDivElement>(null);
  const [panelHeight, setPanelHeight] = useState(0);

  const [activityData, setActivityData] = useState<CustomerActivity[] | undefined>(undefined);
  const [pinnedNotes, setPinnedNotes] = useState<Note[] | undefined>(undefined);
  const [logData, setLogData] = useState<CustomerActivity[]>([]);
  const [loadingLogData, setLoadingLogData] = useState(false);

  const [selectedEmailRecId, setSelectedEmailRecId] = useState<number | null>(null);
  const [selectedLog, setSelectedLog] = useState<ActivityLog | null>(null);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [logCallModalOpen, setLogCallModalOpen] = useState(false);

  const [errors, setErrors] = useState<{ error: boolean; errorMessage: string }>({
    error: false,
    errorMessage: '',
  });

  // Some derived data
  const buyer = accountInformation?.buyer;
  const isLegal = !!accountInformation?.legalStatus;
  const appBuyerRecId = buyer?.appBuyerRecId ?? null;
  const accountnum = accountInformation?.accountNum;
  const cannotContactBuyerAndCobuyer =
    !!contactInformation?.buyer?.noEmail && !!contactInformation?.coBuyer?.noEmail;

  /** @deprecated convert to react router */
  const currentTab =
    (searchParams.get('activityType') as ActivityType | undefined) ?? ActivityType.enum.All;
  const setCurrentTab = (activityType: ActivityType) => {
    searchParams.set('activityType', activityType);
    setSearchParams(searchParams);
  };

  // 1) We use useReq to load the data, same as before
  const {
    isLoading: loadingActivity,
    load: loadActivity,
    value: loadedActivity,
  } = useReq<CustomerActivity[]>(
    async () => {
      if (!colRecId) return [];
      const customerActivity = await customerService.getCustomerActivity(colRecId);
      const activityArr: CustomerActivity[] = [];

      Object.entries(customerActivity).forEach(([key, val]) => {
        if (key === 'emails') {
          (val as Email[]).forEach((email) => {
            activityArr.push({
              id: email.recId,
              activityType: ActivityType.enum.Email,
              activity: email,
              timestamp: email.timestamp || '',
            });
          });
        } else if (key === 'messages') {
          (val as Messages[]).forEach((message) => {
            activityArr.push({
              id: message.ecomRecId,
              activityType: ActivityType.enum.Message,
              activity: message,
              timestamp: message.timestamp || '',
            });
          });
        } else if (key === 'notes') {
          const newPinnedNotes = (val as Note[]).filter((note) => note.pinned);
          setPinnedNotes(newPinnedNotes);

          (val as Note[]).forEach((note) => {
            activityArr.push({
              id: note.recId!,
              activityType: ActivityType.enum.Note,
              activity: note,
              timestamp: note.updatedUtc || '',
            });
          });
        } else if (key === 'payments') {
          (val as Payment[]).forEach((payment) => {
            activityArr.push({
              id: payment.recId!,
              activityType: ActivityType.enum.Payment,
              activity: payment,
              timestamp: payment.timestamp || '',
            });
          });
        } else if (key === 'calls') {
          (val as Call[]).forEach((call) => {
            activityArr.push({
              id: call.recId,
              activityType: ActivityType.enum.Call,
              activity: call,
              timestamp: call.timestamp || '',
            });
          });
        }
      });

      return activityArr.sort((a, b) => 
        dayjs(b.timestamp).valueOf() - dayjs(a.timestamp).valueOf()
      );
    },
    false,
    [],
    (error) => {
      console.error(error);
      setErrors({ error: true, errorMessage: 'There was an issue loading the customer activity' });
    }
  );

  // 2) Define initData as stable, so that references to it never change
  const initData = useCallback(async () => {
    await loadActivity();
  }, [loadActivity]);

  // 3) Decide how often to call initData automatically
  //    If you want to load once on mount:
  useEffect(() => {
    if (colRecId) {
      initData();
    }
    // No [initData] dependency => only runs when colRecId changes or on mount
  }, [colRecId]);

  // Or if you want to run it exactly once on mount, do
  // useEffect(() => { initData(); }, []);

  // 4) Whenever loadedActivity changes, place it in local state
  useEffect(() => {
    if (loadedActivity) {
      setActivityData(loadedActivity);
    }
  }, [loadedActivity]);

  // fetchCompanyDetails
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companyId = accountInformation?.compId;
        if (companyId) {
          const companyDetails = await companyService.getCompanyDetails(companyId);
          const { phone, phone800 } = companyDetails;

          const validPhoneNumber =
            phone && phone !== '0' && phone.trim() !== ''
              ? phone
              : phone800 && phone800 !== '0' && phone800.trim() !== ''
              ? phone800
              : '';

          setCallerPhoneNumber(validPhoneNumber);
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    fetchCompanyDetails();
  }, [accountInformation]);

  // fetch log data if currentTab === Log
  useEffect(() => {
    if (currentTab === ActivityType.enum.Log && colRecId) {
      setLoadingLogData(true);
      accountsService
        .getActivityLog(colRecId)
        .then((res) => {
          const activityLog = res.map((log) => ({
            id: log.recId,
            activityType: ActivityType.enum.Log,
            activity: log,
            timestamp: log.contactOn || '',
          }));
          setLogData(activityLog);
        })
        .finally(() => {
          setLoadingLogData(false);
        });
    }
  }, [currentTab, colRecId]);

  // set panelHeight
  useEffect(() => {
    if (panelRef.current?.clientHeight) {
      setPanelHeight(panelRef.current.clientHeight);
    }
  }, [panelRef, activityData]);

  if (errors.error) return <div>{errors.errorMessage}</div>;
  if (!accountInformation || !colRecId || !appBuyerRecId) return null;

  const displayAll = currentTab !== ActivityType.enum.Message;
  const navBarItems = ActivityType.options.map((type) => ({
    title: type,
    isActive: currentTab === type,
    onClick: () => setCurrentTab(type),
  }));

  const isLoading =
    (currentTab === ActivityType.enum.Log && loadingLogData) ||
    (currentTab !== ActivityType.enum.Log && loadingActivity && !errors.error);

  // handles new note insertion
  const handleNoteSubmit = async (newNote: string): Promise<boolean> => {
    if (!appBuyerRecId || !colRecId || newNote === '') return false;
    try {
      await notesService.insertNote(appBuyerRecId, colRecId, newNote);
      await initData(); // refresh
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // pinned note logic, unchanged
  const updatePinnedNote = async (note: Note) => {
    const pinnedNoteActivityData = activityData?.find(
      (value) => value.activityType === ActivityType.enum.Note && value.activity === note
    );
    const pinnedNote = pinnedNoteActivityData?.activity as Note;
    await customerService
      .updatePinnedNote({
        recId: note.recId!,
        pinned: !note.pinned,
      })
      .then((res) => {
        if (!note.pinned) {
          setActivityData((prevActivityData) => {
            if (prevActivityData) {
              const newActivityData = prevActivityData!.filter((value) => value.id !== note.recId);
              return [
                ...newActivityData,
                {
                  id: res.recId,
                  activity: {
                    ...note,
                    pinned: res.pinned,
                    pinnedOn: res.pinnedBy,
                    pinnedOnUtc: formatDate(res.pinnedOnUtc),
                  },
                  activityType: ActivityType.enum.Note,
                  timestamp: note.updatedUtc,
                } as CustomerActivity,
              ].sort!((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
            }
            return [
              {
                id: res.recId,
                activity: {
                  ...note,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
                activityType: ActivityType.enum.Note,
                timestamp: note.updatedUtc,
              } as CustomerActivity,
            ].sort!((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
          });
          setPinnedNotes((prevNotes) => {
            if (prevNotes) {
              return [
                ...prevNotes,
                {
                  ...pinnedNote,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
              ].sort!((a, b) => (a.pinnedOnUtc! > b.pinnedOnUtc! ? -1 : 1));
            }

            return [
              {
                ...pinnedNote,
                pinned: res.pinned,
                pinnedOn: res.pinnedBy,
                pinnedOnUtc: formatDate(res.pinnedOnUtc),
              },
            ];
          });
        } else {
          setPinnedNotes((prevNotes) =>
            prevNotes?.filter((prevNote) => prevNote.recId !== note.recId)
          );

          setActivityData((prevActivityData) => {
            if (prevActivityData) {
              const newActivityData = prevActivityData.filter((value) => value.id !== note.recId);
              return [
                ...newActivityData,
                {
                  id: res.recId,
                  activity: {
                    ...note,
                    pinned: res.pinned,
                    pinnedOn: res.pinnedBy,
                    pinnedOnUtc: formatDate(res.pinnedOnUtc),
                  },
                  activityType: ActivityType.enum.Note,
                  timestamp: note.updatedUtc,
                } as CustomerActivity,
              ].sort!((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
            }
            return [
              {
                id: res.recId,
                activity: {
                  ...note,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
                activityType: ActivityType.enum.Note,
                timestamp: note.updatedUtc,
              } as CustomerActivity,
            ].sort!((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
          });
        }
      });
  };

  return (
    <AccountsMainPanel
      navBarTitle="Activity"
      navBarItems={navBarItems}
      navBarItemsStyle={{ justifyContent: 'center', gap: '5%' }}
      loading={isLoading}
    >
      <>
        {currentTab === ActivityType.enum.Note && (
          <NotesTextBox handleNoteSubmit={handleNoteSubmit} />
        )}

        {currentTab === ActivityType.enum.Message && (
          <ChatBox
            activityData={activityData ?? []}
            appBuyerRecId={appBuyerRecId}
            initData={initData}
            height={panelHeight}
            loading={activityData === undefined && !errors.error}
            isLegal={isLegal}
            canTextBuyer={!contactInformation?.buyer?.noText}
            canTextCoBuyer={!contactInformation?.coBuyer?.noText}
          />
        )}
        {/* Email Compose Button */}
        {currentTab === ActivityType.enum.Email && (
          <div className={styles.buttonHeader}>
            <div className={styles.button}>
              <Button
                label="Compose Email"
                disabled={isLegal || cannotContactBuyerAndCobuyer}
                onClick={() => setEmailModalOpen(true)}
                loading={activityData === undefined}
              />
              <div className={styles.disclaimer}>
                {isLegal
                  ? 'Cannot contact customer with legal status'
                  : cannotContactBuyerAndCobuyer
                  ? 'Both buyer and co-buyer have no email preference on'
                  : undefined}
              </div>
            </div>
          </div>
        )}
        {/* Log Call Button */}
        {currentTab === ActivityType.enum.Call && (
          <div className={styles.buttonHeader}>
            <div className={styles.button}>
              <Button label="Log Call" onClick={() => setLogCallModalOpen(true)} />
            </div>
          </div>
        )}

        <div
          className={styles.activities}
          style={{ display: displayAll ? 'flex' : 'none' }}
          ref={panelRef}
        >
          {/* Show pinned notes if All tab */}
          {currentTab === ActivityType.enum.All && pinnedNotes?.length ? (
            <>
              <h3 className={styles.pinnedNotesHeader}>Pinned Notes</h3>
              {pinnedNotes.map((note, idx) => (
                <NoteActivityCard
                  activity={note}
                  updatePinnedNote={updatePinnedNote}
                  key={`pn-${idx}`}
                />
              ))}
              <h3 className={styles.pinnedNotesHeader}>Rest of Activity</h3>
            </>
          ) : null}

          {/* Show main activity list if not log tab */}
          {currentTab !== ActivityType.enum.Log &&
            activityData &&
            activityData.map((activity, idx) => {
              if (activity.activityType === currentTab || currentTab === 'All') {
                switch (activity.activityType) {
                  case ActivityType.enum.Email:
                    return (
                      <EmailActivityCard
                        activity={activity.activity as Email}
                        setSelectedRecId={setSelectedEmailRecId}
                        key={`Email${idx}`}
                      />
                    );
                  case ActivityType.enum.Message:
                    return (
                      <SmsActivityCard
                        activity={activity.activity as Messages}
                        key={`Message${idx}`}
                      />
                    );
                  case ActivityType.enum.Note:
                    return (
                      <NoteActivityCard
                        activity={activity.activity as Note}
                        updatePinnedNote={updatePinnedNote}
                        key={`Note${idx}`}
                      />
                    );
                  case ActivityType.enum.Payment:
                    return (
                      <PaymentActivityCard
                        activity={activity.activity as Payment}
                        accountnum={accountnum}
                        key={`Payment${idx}`}
                      />
                    );
                  case ActivityType.enum.Call:
                    return (
                      <LogCallActivityCard
                        activity={activity.activity as Call}
                        callerPhoneNumber={callerPhoneNumber}
                        key={`Call${idx}`}
                      />
                    );
                  default:
                    return null;
                }
              }
              return null;
            })}

          {/* Show Log tab */}
          {currentTab === ActivityType.enum.Log &&
            logData.map((log, idx) => (
              <LogActivityCard
                activity={log.activity as ActivityLog}
                setSelectedLog={setSelectedLog}
                key={`log${idx}`}
              />
            ))}
        </div>

        <EmailContentsModal
          handleClose={() => setSelectedEmailRecId(null)}
          emailRecId={selectedEmailRecId}
        />

        {emailModalOpen && (
          <EmailModal
            colRecId={colRecId}
            initEmailData={initData}
            open={emailModalOpen}
            onClose={() => setEmailModalOpen(false)}
            canEmailBuyer={!contactInformation?.buyer.noEmail}
            canEmailCoBuyer={!contactInformation?.coBuyer.noEmail}
          />
        )}
        {logCallModalOpen && (
          <LogCallModal
            colRecId={colRecId}
            reloadActivity={initData}
            open={logCallModalOpen}
            onClose={() => setLogCallModalOpen(false)}
          />
        )}
        {!!selectedLog && (
          <CommonModal
            open={true}
            onClose={() => setSelectedLog(null)}
            title={[
              formatDate(selectedLog.contactOn),
              selectedLog.detailShort ?? selectedLog.transType,
            ].join(' - ')}
          >
            {selectedLog.detailLong.startsWith('<') ? (
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedLog.detailLong) }} />
            ) : (
              <div>{selectedLog.detailLong}</div>
            )}
          </CommonModal>
        )}
        <QuickActionsModal reloadActivity={initData} />
      </>
    </AccountsMainPanel>
  );
};

export default ActivityPanel;
