import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { useMatch, useParams } from "react-router-dom";
// state
import { useAppDispatch } from "@/store/store";
import { accountActions, useAccountSelector } from "../../accountSlice";
import { useGpsCtx } from "./components/GpsView/GpsProvider";
// services
import { accountsService } from "@/services/accountsService";
import { customerService } from "@/services/customerService";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { loadGpsLastLocate } from "./components/GpsView/requests";
import { formatPhoneE164, getNumber } from "@/utils/helpers/general";
import useReq from "@/utils/useReq";
// interfaces
import { OptInData, OptStatus } from "../interfaces";

const getOptStatus = (optIn: OptInData): OptStatus => {
  const { recId, phoneNumberE164, orgId, dcWorkflowBeginUtc, directConsentGranted } = optIn;
  if (recId === null || recId === undefined) return "unsent";
  else if (phoneNumberE164 === null || orgId === null) return "error";
  else if (dcWorkflowBeginUtc !== null && directConsentGranted === null) return "pending";
  else if (dcWorkflowBeginUtc !== null && directConsentGranted === false) return "denied";
  else if (dcWorkflowBeginUtc !== null && directConsentGranted === true) return "accepted";

  return "error";
};

const useCtxState = () => {
  const dispatch = useAppDispatch();
  const colRecId = getNumber(useParams().colRecId);
  const invRecId = getNumber(useParams().invRecId);
  const root = useMatch({ path: "/:root/*" })?.params.root;
  const isInventory = root === "inventory";

  /** @deprecated remove */
  const gpsLastLocateLoading = useGpsCtx((s) => s.gpsLastLocateLoading);
  const setGpsLastLocateLoading = useGpsCtx((s) => s.setGpsLastLocateLoading);
  const loadGpsInfo = useGpsCtx((s) => s.loadGpsInfo);
  const gpsInformation = useGpsCtx((s) => s.gpsInformation);
  const setGpsLastLocation = useGpsCtx((s) => s.setGpsLastLocation);

  /** @deprecated Replace with `const acctInfo = acctInfoReq.value;` */
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const appRecId = accountInformation?.appRecId;
  const vehRecId = isInventory ? invRecId : accountInformation?.vehRecId;

  const [optIn, setOptIn] = useState<OptInData>(OptInData.new(null));
  const [optInAuthorization, setOptInAuthorization] = useState(false);

  // Req. states
  const contactInfoReq = useReq(async (customColRecId?: number | null) => {
    const appliedColRecId = customColRecId || colRecId;
    if (!appliedColRecId) return;
    const res = await accountsService.getContactInformation(appliedColRecId);
    // @todo remove AFTER removing `accountActions.setContactInformation` AND its getter `___.contactInformation`
    dispatch(accountActions.setContactInformation(res));
    return res;
  });
  // @note This replaces `accountActionCreators.getAccountInformation(...)`
  const acctInfoReq = useReq(async (customColRecId?: number | null) => {
    const appliedColRecId = customColRecId || colRecId;
    if (!appliedColRecId) return; // @todo remove conditional after `accountsService.getAccountInformation(colRecId)` is updated to handle nullish `colRecId`

    dispatch(accountActions.reset()); // @todo Remove line after `accountActions` states are removed/appropriately scoped
    dispatch(accountActions.setAccountInformationLoading(true)); // @todo Remove line after `accountActions.setAccountInformationLoading` is removed
    const res = await accountsService.getAccountInformation(appliedColRecId);
    dispatch(accountActions.setAccountInformation(res)); // @todo Remove line after `accountActions.setAccountInformation` is removed
    dispatch(accountActions.setAccountInformationLoading(false)); // @todo Remove line after `accountActions.setAccountInformationLoading` is removed
    return res;
  });
  const optInReq = useReq(async (customAppRecId?: number | null) => {
    const appliedAppRecId = customAppRecId || appRecId;
    if (!appliedAppRecId) return; // @todo remove conditional after moved to service method
    if (!accountInformation?.buyer.cellPhone) {
      // @todo remove conditional after moved to service method
      return;
    }

    const phoneNumber = formatPhoneE164(accountInformation.buyer.cellPhone);
    const res = await customerService.getOptIn(phoneNumber, appliedAppRecId);
    return res && setOptIn(OptInData.new(res));
  });
  const optInAuthReq = useReq(async (customAppRecId?: number | null) => {
    const appliedAppRecId = customAppRecId || appRecId;
    if (!appliedAppRecId) return; // @todo remove conditional after moved to service method
    return await customerService.getOptInWorkflow(appliedAppRecId);
  });

  useEffect(() => {
    acctInfoReq.load();
    contactInfoReq.load();
    // @todo convert to `useReq` impl. and replace with `gpsInfoReq.load()`
    colRecId && loadGpsInfo(colRecId, "colRecId");
  }, [colRecId]);
  useEffect(() => {
    optInReq.load();
    optInAuthReq.load();
  }, [appRecId]);
  useEffect(() => {
    if (vehRecId && gpsInformation) {
      // @todo convert to `useReq` impl.
      loadGpsLastLocate(vehRecId, gpsInformation, setGpsLastLocateLoading, setGpsLastLocation); // @todo replace with `gpsLastLocateReq.load()`
    }
  }, [vehRecId]);

  // Getters
  const optStatus = getOptStatus(optIn);

  return {
    // Req. states
    contactInfoReq,
    acctInfoReq,
    optInReq,
    optInAuthReq,

    /** @deprecated remove */
    gpsLastLocateLoading,

    /** @deprecated replace with `optInReq.value` */
    optIn,
    /** @deprecated replace with `optInReq.load`/`optInReq.setValue` */
    setOptIn,
    optStatus,

    /** @deprecated replace with `optInAuthReq.value` */
    optInAuthorization,
    /** @deprecated replace with `optInAuthReq.load`/`optInAuthReq.setValue` */
    setOptInAuthorization,
  };
};

export type IAcctDetailCtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<IAcctDetailCtx | null>(null);
/** @todo rename to `useAcctDetailCtx` */
export const useAcctDetailsCtx = useCtxFactory(Ctx);
const AcctDetailProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default AcctDetailProvider;
