import { FC } from 'react';
import dayjs from 'dayjs';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { formatCurrency } from '@/utils/helpers/general';
// style
import styles from './LeftContainer.module.scss';

/** ###  */
const AcctMiscCard: FC = () => {
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  if (!accountInformation) return <></>;
  const dueDateUtc = dayjs.utc(accountInformation.nextRegularPaymentDueDate).startOf('day');
  const todayUtc = dayjs.utc().startOf('day');
  const daysLate = todayUtc.diff(dueDateUtc, 'days');

  const isLate = daysLate > 0;
  const isLegal = !!accountInformation.legalStatus;
  const isOneDay = Math.abs(daysLate) == 1 ? "Day" : "Days"
  const dueInDays = daysLate == 0 ? 'Today' : `In ${Math.abs(daysLate)} ${isOneDay}`
  const accountMisc = [
    accountInformation.code3,
    accountInformation.status,
    isLate ? `${daysLate} ${isOneDay} Late` : `Due ${dueInDays} ${"$" + accountInformation.amountDue}`,
    accountInformation.legalStatus ? 'Legal' : '',
    accountInformation.iopStatus ? 'IOP' : '',
  ]
    .filter((misc) => !!misc)
    .join(' - ');
  const hasAmountDue = (accountInformation.amountDue ?? 0) > 0;

  return (
    <div
      className={styles.accountMiscCard}
      style={{ backgroundColor: isLate || isLegal ? '#FFC3C3' : '#C2ECCD' }}
    >
      <div>{accountMisc}</div>
      {isLate && hasAmountDue && <div>{formatCurrency(accountInformation.amountDue)} Due</div>}
    </div>
  );
};

export default AcctMiscCard;
