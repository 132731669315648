// insuranceClaimsTableColumns.ts
import React from 'react';
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { AccountDb } from '../interfaces';
import { routeBase } from '../..';
import { CurrencyCell } from '../tableCellComponents/CurrencyCell';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const insuranceClaimsTableColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('totalled', 'Totalled', 100),            
  newColumnDef('claimStatus', 'Claim Status', 120),
  newColumnDef('claimDate', 'Claim Date', 120, 'date'),
  newColumnDef('insuranceCompany', 'Ins Company', 180),
  newColumnDef('custIns', 'Cust Ins', 140),
  newColumnDef('cpiClaim', 'CPI Claim', 100),           
  newColumnDef('deduct', 'Deduct', 120, CurrencyCell),  
  newColumnDef('claimSetAmt', 'Settle Amount', 130, CurrencyCell),
  newColumnDef('buyers', 'Buyers', 150),
  newColumnDef('claimSetDate', 'Settle Date', 150,'date'),

  newColumnDef('status', 'Act Status', 120),         
  newColumnDef('code3', 'Lot', 'auto'),
];
