import { Controller } from 'react-hook-form';
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import { Loader } from '@progress/kendo-react-all';
import { Button, Checkbox, DropdownInput, NumberInput, PhoneInput, TextInput, ZipInput } from "@/components";
import { Modal } from "@/components/modals/Modal";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { BasicTable } from '@/components/table/Table';
import { formatAddress, formatCurrency, getAddressFromGoogleMaps } from '@/utils/helpers/general';
import { SectionHeader } from '@components/sectionHeader/SectionHeader';
import { useTradeIn } from './useTradeIn';
import { FunctionLink } from '@components/functionLink/FunctionLink';
import { useNavUp } from '@/utils/routing/useNavUpEvent';
// style
import salesTabStyles from '../SalesTab.module.scss';
import styles from './TradeIn.module.scss';
import { usaStateCodes } from '@/general/regions';
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
import { useEffect, useMemo } from 'react';

export const TradeIn = () => {
  const {
    control,
    watch,
    tradeInList,
    tradeInColumns,
    addTradeIn,
    handleSubmit,
    errors,
    updateTradeIn,
    deleteTradeIn,
    editTradeInForm,
    editTradeInModalOpen,
    closeEditTradeInModal,
    activeTradeIn,
    totalAllowance,
    totalNetTrade,
    totalPayoff,
    submitTradeInLoading,
    deleteTradeInLoading,
    getTradeInLoading,
    getVinAuditData,
    getValues,
    setValue,
  } = useTradeIn();

  const fullAddress = useMemo(() => {
    var formGetValue = editTradeInModalOpen ? editTradeInForm.getValues : getValues;
    return formatAddress(
      formGetValue("address"),
      formGetValue("city"),
      formGetValue("state"),
      formGetValue("zip")
    );
  }, [editTradeInModalOpen]);

  const handleAddressPlaceChange = (place: google.maps.places.PlaceResult) => {
    var formSetValue = editTradeInModalOpen ? editTradeInForm.setValue : setValue;
    const fullAddress = getAddressFromGoogleMaps(place);

    formSetValue('address', fullAddress.address);
    formSetValue('city', fullAddress.city);
    formSetValue('state', fullAddress.state);
    formSetValue('zip', fullAddress.zip);
  };
  
  const clearAddress = () => {
    var formSetValue = editTradeInModalOpen ? editTradeInForm.setValue : setValue;
    formSetValue("address", "");
    formSetValue("city", "");
    formSetValue("state", "");
    formSetValue("zip", "");
  }

  useEffect(() => {
    var isSet = editTradeInModalOpen ? !!editTradeInForm.watch("payoff") : !!watch('payoff');

    if(!isSet) clearAddress();
  }, [watch('payoff'), editTradeInForm.watch("payoff")])

  return (
    <div className={salesTabStyles.container}>
      <header className={salesTabStyles.headerContainer}>
        <h2 className={salesTabStyles.header}>Trade-In</h2>
        <div className={styles.headerInfoContainer}>
          <h4 className={styles.headerInfo}>Total Allowance: {formatCurrency(totalAllowance)}</h4>
          <h4 className={styles.headerInfo}>Total Payoff {formatCurrency(totalPayoff)}</h4>
          <h4 className={styles.headerInfo}>Total Net Trade: {formatCurrency(totalNetTrade)}</h4>
          <Button label="Terms/Quotes" onClick={useNavUp()} />
        </div>
      </header>
      <main className={salesTabStyles.body} style={{overflow: "scroll"}}>
        <form onSubmit={handleSubmit(addTradeIn)}>
          <section className={salesTabStyles.section}>
            <SectionHeader title="Add Trade-In" />
            <div className={salesTabStyles.bodyContainer}>
              <div className={salesTabStyles.bodyChild}>
                <Controller
                  name="allowance"
                  control={control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Allowance"
                      required
                      errors={errors.allowance?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="acv"
                  control={control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <CurrencyInput label="ACV" required errors={errors.acv?.message} {...field} />
                  )}
                />

                <Controller
                  name="payoff"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Payoff" {...field} />}
                />

                {!!watch('payoff') && (
                  <Controller
                    name="owedTo"
                    control={control}
                    rules={{
                      required: { value: !!watch('payoff'), message: 'This field is required' },
                    }}
                    render={({ field }) => (
                      <TextInput
                        label="Owed To"
                        required={!!watch('payoff')}
                        errors={errors.owedTo?.message}
                        {...field}
                      />
                    )}
                  />
                )}

                <Controller
                  name="netTrade"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Net Trade" readOnly {...field} />}
                />

                <Controller
                  name="vin"
                  control={control}
                  render={({ field }) => <TextInput label="VIN" {...field} />}
                />

                <FunctionLink onClick={getVinAuditData} label="Decode Vin" />

                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => <NumberInput format="#" label="Year" {...field} />}
                />

                <Controller
                  name="make"
                  control={control}
                  render={({ field }) => <TextInput label="Make" {...field} />}
                />

                <Controller
                  name="model"
                  control={control}
                  render={({ field }) => <TextInput label="Model" {...field} />}
                />
              </div>

              <div className={salesTabStyles.bodyChild}>
                <Controller
                  name="color"
                  control={control}
                  render={({ field }) => <TextInput label="Color" {...field} />}
                />

                <Controller
                  name="bodyType"
                  control={control}
                  render={({ field }) => <TextInput label="Body Type" {...field} />}
                />

                <Controller
                  name="vehicleType"
                  control={control}
                  render={({ field }) => <TextInput label="Vehicle Type" {...field} />}
                />

                <Controller
                  name="miles"
                  control={control}
                  rules={{
                    min: { value: 0, message: 'Miles In cannot be less than 0' },
                    required: 'Miles In is required',
                  }}
                  render={({ field }) => (
                    <NumberInput label="Miles" {...field} required errors={errors.miles?.message} />
                  )}
                />

                <div className={styles.checkboxContainer}>
                  <Controller
                    name="notActual"
                    control={control}
                    render={({ field }) => <Checkbox label="Not Actual" {...field} />}
                  />

                  <Controller
                    name="exceedsLimits"
                    control={control}
                    render={({ field }) => <Checkbox label="Exceeds Limits" {...field} />}
                  />

                  <Controller
                    name="printExempt"
                    control={control}
                    render={({ field }) => <Checkbox label="Print Exempt" {...field} />}
                  />
                </div>

                <Controller
                  name="nameOnTitle"
                  control={control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <TextInput
                      label="Name on Title"
                      required
                      errors={errors.nameOnTitle?.message}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </section>
          {!!watch('payoff') && (
            <section className={salesTabStyles.section}>
              <SectionHeader title="Pay-Off Details" />
              <div className={salesTabStyles.bodyContainer}>
                <div className={salesTabStyles.bodyChild}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <AddressAutocomplete
                        label="Address"
                        {...field}
                        onPlaceSelected={handleAddressPlaceChange}
                        fullAddress={fullAddress}
                      />
                    )}
                  />

                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => <TextInput label="City" {...field} />}
                  />

                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => <DropdownInput label="State" data={usaStateCodes} {...field} />}
                  />

                  <Controller
                    name="zip"
                    control={control}
                    render={({ field }) => <ZipInput label="Zip" {...field} />}
                  />
                </div>

                <div className={salesTabStyles.bodyChild}>
                  <Controller
                    name="contact"
                    control={control}
                    render={({ field }) => <TextInput label="Contact" {...field} />}
                  />

                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => <PhoneInput label="Phone" {...field} />}
                  />

                  <Controller
                    name="actualPayoff"
                    control={control}
                    render={({ field }) => <CurrencyInput label="Actual Payoff" {...field} />}
                  />

                  <Controller
                    name="goodUntil"
                    control={control}
                    render={({ field }) => <DateInput label="Good Until" {...field} />}
                  />

                  <Controller
                    name="perDiem"
                    control={control}
                    render={({ field }) => <CurrencyInput label="Per Diem" {...field} />}
                  />
                </div>
              </div>
            </section>
          )}
          <div className={salesTabStyles.buttonContainer}>
            <Button label="Submit" loading={submitTradeInLoading} />
          </div>
        </form>
        <section>
          <SectionHeader title="Trade-In Information List" />
          {getTradeInLoading ? (
            <div className={salesTabStyles.loader}>
              <Loader size="large" />
            </div>
          ) : (
            <div>
              <BasicTable data={tradeInList} columns={tradeInColumns} />
            </div>
          )}
        </section>
      </main>
      {/* Modal cannot be in its own component because Payoff form field has fields relying on conditionals based on its state,
       and changes to the state would cause the parent to rerender and recreate the child instead of the child rerendering, 
       causing payoff to lose focus after typing one character */}
      <Modal
        isOpen={editTradeInModalOpen}
        centerModal
        closeButton
        title="Edit Trade-In"
        onCloseButtonClick={closeEditTradeInModal}
      >
        <form onSubmit={editTradeInForm.handleSubmit(updateTradeIn)}>
          <section className={salesTabStyles.section}>
            <div className={salesTabStyles.bodyContainer}>
              <div className={salesTabStyles.bodyChild}>
                <Controller
                  name="allowance"
                  control={editTradeInForm.control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Allowance"
                      required
                      errors={editTradeInForm.formState.errors.allowance?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="acv"
                  control={editTradeInForm.control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <CurrencyInput
                      label="ACV"
                      required
                      errors={editTradeInForm.formState.errors.acv?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="payoff"
                  control={editTradeInForm.control}
                  render={({ field }) => <CurrencyInput label="Payoff" {...field} />}
                />

                {!!editTradeInForm.watch('payoff') && (
                  <Controller
                    name="owedTo"
                    control={editTradeInForm.control}
                    render={({ field }) => <TextInput label="Owed To" {...field} />}
                  />
                )}

                <Controller
                  name="netTrade"
                  control={editTradeInForm.control}
                  render={({ field }) => <CurrencyInput label="Net Trade" readOnly {...field} />}
                />

                <Controller
                  name="vin"
                  control={editTradeInForm.control}
                  render={({ field }) => <TextInput label="VIN" {...field} />}
                />

                <Controller
                  name="year"
                  control={editTradeInForm.control}
                  render={({ field }) => <NumberInput format="#" label="Year" {...field} />}
                />

                <Controller
                  name="make"
                  control={editTradeInForm.control}
                  render={({ field }) => <TextInput label="Make" {...field} />}
                />

                <Controller
                  name="model"
                  control={editTradeInForm.control}
                  render={({ field }) => <TextInput label="Model" {...field} />}
                />
              </div>

              <div className={salesTabStyles.bodyChild}>
                <Controller
                  name="color"
                  control={editTradeInForm.control}
                  render={({ field }) => <TextInput label="Color" {...field} />}
                />

                <Controller
                  name="bodyType"
                  control={editTradeInForm.control}
                  render={({ field }) => <TextInput label="Body Type" {...field} />}
                />

                <Controller
                  name="vehicleType"
                  control={editTradeInForm.control}
                  render={({ field }) => <TextInput label="Vehicle Type" {...field} />}
                />

                <Controller
                  name="miles"
                  control={editTradeInForm.control}
                  render={({ field }) => <NumberInput label="Miles" {...field} />}
                />

                <div className={styles.checkboxContainer}>
                  <Controller
                    name="notActual"
                    control={editTradeInForm.control}
                    render={({ field }) => <Checkbox label="Not Actual" {...field} />}
                  />

                  <Controller
                    name="exceedsLimits"
                    control={editTradeInForm.control}
                    render={({ field }) => <Checkbox label="Exceeds Limits" {...field} />}
                  />

                  <Controller
                    name="printExempt"
                    control={editTradeInForm.control}
                    render={({ field }) => <Checkbox label="Print Exempt" {...field} />}
                  />
                </div>

                <Controller
                  name="nameOnTitle"
                  control={editTradeInForm.control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <TextInput
                      label="Name on Title"
                      required
                      errors={editTradeInForm.formState.errors.nameOnTitle?.message}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </section>
          {!!editTradeInForm.watch('payoff') && (
            <section className={salesTabStyles.section}>
              <div className={salesTabStyles.bodyContainer}>
                <div className={salesTabStyles.bodyChild}>
                  <Controller
                    name="address"
                    control={editTradeInForm.control}
                    render={({ field }) => (
                      <AddressAutocomplete
                        label="Address"
                        {...field}
                        onPlaceSelected={handleAddressPlaceChange}
                        fullAddress={fullAddress}
                      />
                    )}
                  />

                  <Controller
                    name="city"
                    control={editTradeInForm.control}
                    render={({ field }) => <TextInput label="City" {...field} />}
                  />

                  <Controller
                    name="state"
                    control={editTradeInForm.control}
                    render={({ field }) => <DropdownInput label="State" data={usaStateCodes} {...field} />}
                  />

                  <Controller
                    name="zip"
                    control={editTradeInForm.control}
                    render={({ field }) => <ZipInput label="Zip" {...field} />}
                  />
                </div>

                <div className={salesTabStyles.bodyChild}>
                  <Controller
                    name="contact"
                    control={editTradeInForm.control}
                    render={({ field }) => <TextInput label="Contact" {...field} />}
                  />

                  <Controller
                    name="phone"
                    control={editTradeInForm.control}
                    render={({ field }) => <PhoneInput label="Phone" {...field} />}
                  />

                  <Controller
                    name="actualPayoff"
                    control={editTradeInForm.control}
                    render={({ field }) => <CurrencyInput label="Actual Payoff" {...field} />}
                  />

                  <Controller
                    name="goodUntil"
                    control={editTradeInForm.control}
                    render={({ field }) => <DateInput label="Good Until" {...field} />}
                  />

                  <Controller
                    name="perDiem"
                    control={editTradeInForm.control}
                    render={({ field }) => <CurrencyInput label="Per Diem" {...field} />}
                  />
                </div>
              </div>
            </section>
          )}
          <div className={salesTabStyles.buttonContainer}>
            <Button
              type="button"
              label="Delete Trade-In"
              themeColor="error"
              onClick={() => deleteTradeIn(activeTradeIn!.recId!)}
              loading={deleteTradeInLoading}
            />
            <Spacer size={8} horizontal />
            <Button label="Submit" loading={submitTradeInLoading} />
          </div>
        </form>
      </Modal>
    </div>
  );
};
