export const appPdfCss = `
body {
  -webkit-print-color-adjust: exact;
  width: 100%;
  font-family: Arial, sans-serif;
  font-size: 14px; 
  margin: 0;
  padding: 0;
  zoom: 1.35; 
}

table {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
  font-size: 10px;
  border: 0;
}

.tB { border-top: 1px solid #000; }
.rB { border-right: 1px solid #000; }
.bB { border-bottom: 1px solid #000; }
.lB { border-left: 1px solid #000; }
.noBorderB { border-bottom: none; }
.noBorderT { border-top: none; }

th {
  border-top: 1px solid #000;
  line-height: 10px;
  font-weight: bold;
  padding: 4px 0 0 0;
  text-align: left;
  color: #686868;
}

td {
  line-height: 10px;
  font-size: 11px;
  padding: 0 0 4px 0;
  text-align: left;
}

.headerSection {
  width: 100%;
  background-color: #DBEAF7;
  color: #000;
  text-align: center;
  font-weight: bold;
  padding: 20px 0;
  height: 80px;
}

.headerSection2 {
  width: 100%;
  background-color: #DBEAF7;
  color: #000;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  height: 40px;
}

.sec2 {
  line-height: 1.6;
}

`;
