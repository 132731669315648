// kendo
import { GridColumnProps } from "@progress/kendo-react-all";
// utils
import { miscPaymentTableColumns } from "./pmtSubviews/miscPayment/tableColumns";
import { activeTableColumns } from "./accountsSubviews/tableColumns/active";
import { residualDueTableColumns } from "./accountsSubviews/tableColumns/residualDue";
import { iopColumns } from "./accountsSubviews/tableColumns/iop";
import { allColumns } from "./accountsSubviews/tableColumns/all";
import { unpaidSalesColumns } from "./accountsSubviews/tableColumns/unpaidSales";
import { cpiAccountsColumns } from "./accountsSubviews/tableColumns/cpiAccounts";
import { terminationsColumns } from "./accountsSubviews/tableColumns/terminations";
import { paymentActivityColumns } from "./pmtSubviews/paymentActivity/tableColumns";
import { deferredDownColumns } from "./accountsSubviews/tableColumns/deferredDown";
import {
  AllowedFilterKey as AllowedPmtFilterKey,
  AllowedSortKey as AllowedPmtSortKey,
} from "./pmtSubviews/default";
import {
  AllowedFilterKey as AllowedAcctFilterKey,
  AllowedSortKey as AllowedAcctSortKey,
} from "./accountsSubviews/default";
import { ClaimStatusKey, claimStatusMapping, LaStatusKey, RecTypeKey, SaleTypeKey, SpStatusKey } from "./accountsSubviews/interfaces";
import { outForRepoTableColumns } from "./accountsSubviews/tableColumns/outForRepo";
import { recurringColumns } from "./accountsSubviews/tableColumns/recurring";
import { insuranceTableColumns } from "./accountsSubviews/tableColumns/insuranceInfo";
import { paidoffColumns } from "./accountsSubviews/tableColumns/paidoff";
import { insuranceClaimsTableColumns } from "./accountsSubviews/tableColumns/insuranceclaims";

export enum AcctsSubviewKey {
  active = "active",
  residualDue = "residualDue",
  iop = "iop",
  all = "all",
  unpaidSales = "unpaidSales",
  terminations = "terminations",
  cpiAccounts = "cpiAccounts",
  outForRepo = "outForRepo",
  legalAccounts = "legalAccounts",
  deferredDown = "deferredDown", 
  recurringPayments = "recurringPayments", 
  insurance = "insurance" ,
  insuranceClaims = "insuranceClaims", 
  paidOff = "paidOff"
}
export enum PmtSubviewKey {
  miscPayment = "miscPayment",
  paymentActivity = "paymentActivity",
}
export type SubviewKey = AcctsSubviewKey | PmtSubviewKey;

export type SubviewInfo = {
  /** Subview slug & identifier/lookup-key */
  route: SubviewKey;
  /** View header title */
  text: string;
  /** Table column config */
  columns: (GridColumnProps & { display?: boolean })[];
  /** */
  initDateFilterFields: (AllowedPmtFilterKey | AllowedAcctFilterKey)[];
  initSortFields: (AllowedPmtSortKey | AllowedAcctSortKey)[];

  categoryFilterOptions?: string[];
};

export const viewInfo: SubviewInfo[] = [
  // `Accounts` subviews
  {
    text: "Active",
    route: AcctsSubviewKey.active,
    columns: activeTableColumns,
    initDateFilterFields: [],
    initSortFields: ["dateSold"],
    categoryFilterOptions: ["Lease", "Finance", "Sidenote"] as SaleTypeKey[],
  },
  {
    text: "All Accounts",
    route: AcctsSubviewKey.all,
    columns: allColumns,
    initDateFilterFields: [],
    initSortFields: ["dateSold"],
    categoryFilterOptions: [
      "Wholesale",
      "Sidenote",
      "Lease",
      "Finance",
      "Down",
      "Deposit",
      "CashSale",
      "Cash",
    ] as SaleTypeKey[],
  },
  {
    text: "Paid Off",
    route: AcctsSubviewKey.paidOff,
    columns: paidoffColumns,
    initDateFilterFields: [],
    initSortFields: ["dateSold"],
    categoryFilterOptions: ["Lease", "Finance", "Sidenote"] as SaleTypeKey[],
  },
  {
    text: "CPI Accounts",
    route: AcctsSubviewKey.cpiAccounts,
    columns: cpiAccountsColumns,
    initDateFilterFields: [],
    initSortFields: ["dateSold"],
    categoryFilterOptions: [],
  },
  {
    text: "Deferred Downs",
    route: AcctsSubviewKey.deferredDown,
    columns: deferredDownColumns,
    initDateFilterFields: [],
    initSortFields: ["daysLate"],
    categoryFilterOptions: [],
  },
  {
    text: "Recurring Accounts",
    route: AcctsSubviewKey.recurringPayments,
    columns: recurringColumns,
    initDateFilterFields: [],
    initSortFields: ["daysLate"],
    categoryFilterOptions: ["Active", "Not Setup", "Pending"] as RecTypeKey[],
  },
  {
    text: "In Our Possession",
    route: AcctsSubviewKey.iop,
    columns: iopColumns,
    initDateFilterFields: [],
    initSortFields: ["dateSold"],
    categoryFilterOptions: ["Lease", "Finance"] as SaleTypeKey[],
  },
  {
    text: "Legal Accounts",
    route: AcctsSubviewKey.legalAccounts,
    columns: activeTableColumns,
    initDateFilterFields: [],
    initSortFields: ["dateSold"],
    categoryFilterOptions: ["Active", "ChargeOff", "ResidualDue"] as LaStatusKey[],
  },
  // `Payment` subview
  {
    text: "Miscellaneous Payments",
    route: PmtSubviewKey.miscPayment,
    columns: miscPaymentTableColumns,
    initDateFilterFields: ["pmtDate"],
    initSortFields: ["pmtDate"],
    categoryFilterOptions: [],
  },
  {
    text: "Out For Repo",
    route: AcctsSubviewKey.outForRepo,
    columns: outForRepoTableColumns,
    initDateFilterFields: [], // what field?
    initSortFields: ["daysLate"], // what field?
    categoryFilterOptions: [],
  },
  // `Payment` subview
  {
    text: "Payment Activity",
    route: PmtSubviewKey.paymentActivity,
    columns: paymentActivityColumns,
    initDateFilterFields: ["pmtDate"],
    initSortFields: ["pmtDate"],
    categoryFilterOptions: [],
  },
  {
    text: "Residual Due",
    route: AcctsSubviewKey.residualDue,
    columns: residualDueTableColumns,
    initDateFilterFields: [],
    initSortFields: ["dateSold"],
    categoryFilterOptions: [],
  },
  {
    text: "Terminations/Charge-Offs",
    route: AcctsSubviewKey.terminations,
    columns: terminationsColumns,
    initDateFilterFields: ["chgOffDate"],
    initSortFields: ["chgOffDate"],
    categoryFilterOptions: ["Lease", "Finance", "Sidenote", "Down"] as SaleTypeKey[],
  },
  {
    text: "Insurance Info",
    route: AcctsSubviewKey.insurance,
    columns: insuranceTableColumns,
    initDateFilterFields: [],
    initSortFields: ["insuranceExpires"],
    categoryFilterOptions: [] 
  },
  {
    text: "Insurance Claims",
    route: AcctsSubviewKey.insuranceClaims,
    columns: insuranceClaimsTableColumns,
    initDateFilterFields: [],
    initSortFields: ["status"],
    categoryFilterOptions: [] as ClaimStatusKey[],
  },
  
  {
    text: "Unpaid Sales",
    route: AcctsSubviewKey.unpaidSales,
    columns: unpaidSalesColumns,
    initDateFilterFields: [],
    initSortFields: ["dateSold"],
    categoryFilterOptions: [
      "Wholesale",
      "CashSale",
      "Cash Down",
      "Lease Down",
      "Deposit",
    ] as SpStatusKey[],
  },
];

export const viewInfoMap: Map<SubviewKey, SubviewInfo> = new Map(viewInfo.map((v) => [v.route, v]));
