import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// kendo
import { Loader } from '@/components/loader/Loader';
import { accountsService } from '@/services/accountsService';
import { Button, DropDownButton, TextInput } from "@/components";
import { OdometerTrackingData } from '@/interfaces';
import { formatDate } from '@/utils/helpers/general';
import { BasicTable } from '@/components/table/Table';
import { Controller, useForm } from 'react-hook-form';
import { Column } from '@/components/table/TableInterface';
import { inventoryService } from '@/services/inventoryService';
import Grid from '@mui/material/Unstable_Grid2';
// style
import styles from './OdometerTracking.module.scss';
import { toast } from 'react-toastify';
import { getRouteParamNum } from '@/utils/routing/formatting';
import { useAccountSelector } from '@/features/Accounts/accountSlice';

const OdometerDataItem: FC<{ title: string; data: string | number | null }> = (props) => (
  <div>
    <span className={styles.dataItemTitle}>{props.title}: </span>
    {props.data}
  </div>
);

interface AddMilesForm {
  invRecId: number;
  miles: string;
}

const odometerTrackingColumns: Column[] = [
  {
    field: 'dateLocal',
    title: 'Date',
    cell: (props) => {
      const { dateLocal } = props.dataItem;
      return (
        <td className="k-table-td">
          <span>{formatDate(dateLocal)}</span>
        </td>
      );
    },
  },
  {
    field: 'odometerCurrent',
    title: 'Odo. Reading',
  },
  {
    field: 'source',
    title: 'Source',
  },
  {
    field: 'odometerElapsed',
    title: 'Miles Used',
  },
  {
    field: 'daysElapsed',
    title: 'Days',
  },
  {
    field: 'perDay',
    title: 'Per Day',
  },
  {
    field: 'perMonth',
    title: 'Per Month',
  },
  {
    field: 'perYear',
    title: 'Per Year',
  },
];

export const OdometerTrackingList: FC<{ isInventory?: boolean }> = ({ isInventory = false }) => {
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  const colRecId = getRouteParamNum(useParams().colRecId);
  const recId = getRouteParamNum(
    isInventory ? useParams().invRecId : accountInformation?.vehRecId
  );
  const [odometerData, setOdometerData] = useState<OdometerTrackingData>();
  const [dataLoading, setDataLoading] = useState(false);
  const [updatePopUpOpen, setUpdatePopUpOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const updateMilesForm = useForm<AddMilesForm>({
    defaultValues: { miles: '', invRecId: recId ?? undefined },
    mode: 'onChange',
  });

  const initData = async () => {
    setDataLoading(true);
    if (isInventory) {
      await inventoryService
        .getOdometerTracking(recId!)
        .then((odoData) => setOdometerData(odoData))
        .finally(() => {
          setDataLoading(false);
        });
    } else {
      await accountsService
        .getOdometerTracking(colRecId!)
        .then((odoData) => setOdometerData(odoData))
        .finally(() => {
          setDataLoading(false);
        });
    }
  };

  const submitAddMilesForm = async (formData: AddMilesForm) => {
    const { miles, invRecId } = formData;
    const odometerValue = Number(miles);
      if (!invRecId) return;
      try {
        setIsUpdating(true);
        await accountsService.insertOdometerRecord(invRecId, odometerValue);
        await initData();
        toast.success('Miles updated successfully');
      } catch (e) {
        toast.error('There was an error adding miles');
        throw e;
      } finally {
        setIsUpdating(false);
        setUpdatePopUpOpen(false);
      }
  };

  useEffect(() => {
    if (!recId) return;
    initData();
  }, [recId]);

  const addNewMilesRecordButton = ()=>{

    return (
      <DropDownButton
              label="Update Record"
              open={updatePopUpOpen}
              setOpen={setUpdatePopUpOpen}
              popUpProps={{
                popupAlign: { vertical: 'top', horizontal: 'right' },
                anchorAlign: { vertical: 'bottom', horizontal: 'right' },
              }}
              buttonProps={{ loading: isUpdating, type: 'button' }}
            >
              <Grid
                container
                direction="column"
                flex={1}
                gap={3}
                p={3}
                marginBottom={3}
                bgcolor="#dadde7"
                borderRadius={2}
              >
                <Grid container direction="column" gap={1}>
                  <Controller
                    name="miles"
                    control={updateMilesForm.control}
                    rules={{ required: 'Reason is required' }}
                    render={({ field }) => (
                      <TextInput
                        label="Current Miles"
                        required
                        spellCheck="true"
                        errors={updateMilesForm.formState.errors.miles?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid container direction="row" gap={2} alignSelf="end">
                  <Button
                    label="Update Miles"
                    loading={isUpdating}
                    onClick={updateMilesForm.handleSubmit(submitAddMilesForm)}
                    type="button"
                  />
                  <Button
                    label="Cancel"
                    onClick={() => {
                      setUpdatePopUpOpen(false);
                      updateMilesForm.reset();
                    }}
                  />
                </Grid>
              </Grid>
            </DropDownButton>
    )
  }

  return dataLoading ? (
    <Loader size="large" />
  ) : !odometerData ? (
    <div>Odometer data is unavailable</div>
  ) : (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.data}>
            <OdometerDataItem title="Miles At Sale" data={odometerData.milesOut} />
            <OdometerDataItem title="Date Sold" data={formatDate(odometerData.dateSold)} />
          </div>
        {addNewMilesRecordButton()}
        </div>
        {!odometerData.odometerRecords || !odometerData.odometerRecords.length ? (
          <div>No odometer records found for this {isInventory ? 'vehicle' : 'account'}</div>
        ) : (
          <BasicTable data={odometerData.odometerRecords} columns={odometerTrackingColumns} />
        )}
      </div>
    </>
  );
};
