import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Wrapper } from '@googlemaps/react-wrapper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// mui
import MuiKendoButton from '@/mui/components/buttons/MuiKendoButton';
// kendo
import { Loader } from '@progress/kendo-react-all';
// components
import GpsMap from './GpsMap';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { useGpsCtx } from './GpsProvider';
// utils
import { config } from '@/config';
import { DateFormat } from '@/utils/helpers/general';
import { getRouteParamNum } from '@/utils/routing/formatting';
import { forceUpdateGpsLocaton, loadGpsLastLocate } from './requests';
// interfaces
import { GpsDevice } from './interfaces';
// style
import styles from './Gps.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const LocationDataItem: FC<{ title: string; data: string | number | null }> = ({ data, title }) => (
  <div>
    <div className={styles.locationDataTitle}>{title}: </div>
    {data}
  </div>
);

/** ###  */
const GpsLocation: FC<{ isInventory?: boolean }> = ({ isInventory = false }) => {
  const params = useParams();

  const mapRef = useRef<HTMLDivElement>(null);

  const loadGpsInfo = useGpsCtx((s) => s.loadGpsInfo);
  const gpsInformation = useGpsCtx((s) => s.gpsInformation);
  const device = useGpsCtx((s) => s.gpsInformation?.device);
  const gpsLoading = useGpsCtx((s) => s.gpsLoading);
  const gpsLastLocation = useGpsCtx((s) => s.gpsLastLocation);
  const gpsLastLocateLoading = useGpsCtx((s) => s.gpsLastLocateLoading);
  const setGpsLastLocateLoading = useGpsCtx((s) => s.setGpsLastLocateLoading);
  const setGpsLastLocation = useGpsCtx((s) => s.setGpsLastLocation);
  const [locateNowLoading, setLocateNowLoading] = useState(false);

  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const accountInformationLoading = useAccountSelector((s) => s.accountInformationLoading);

  const vehRecId = isInventory ? getRouteParamNum(params.invRecId) : accountInformation?.vehRecId;

  const location: google.maps.LatLngLiteral | null =
    gpsLastLocation?.latitude && gpsLastLocation?.longitude
      ? {
          lat: gpsLastLocation.latitude,
          lng: gpsLastLocation.longitude,
        }
      : null;

  useEffect(() => {
    if (!mapRef || !mapRef.current) return;
    new window.google.maps.Map(mapRef.current, {
      center: location,
      zoom: 8,
    });
  }, [location, mapRef?.current]);

  const isPassTime = device === GpsDevice.enum.PassTime;
  const isSpireon = device === GpsDevice.enum.Spireon;
  const isIturan = device === GpsDevice.enum.Ituran;
  const isUnsupportedProvider = device && !isPassTime && !isSpireon && !isIturan;

  if (isUnsupportedProvider)
    return <div>GPS provider is not currently supported in the new UI</div>;

  const gpsDate = dayjs(gpsLastLocation?.lastPing).format(DateFormat.SimpleDateTime);

  const repeat = (input: number = 0) => {
    if (!vehRecId || !gpsInformation) return;

    setTimeout(async () => {
      input++;
      if (input >= 3)
        return;
      
      gpsLastLocation?.lastPing;
      await loadGpsInfo(vehRecId, 'vehRecId');
      await loadGpsLastLocate(vehRecId, gpsInformation, setGpsLastLocateLoading, setGpsLastLocation);
      setLocateNowLoading(false);

      //This is used to fetch the data to update the GPS Location info, it cycles for 2 times, but it was causing it to execute twice.
      // repeat(input);
    }, 5000);
  };

  const handleClickUpdateLastLocate = async () => {
    if (!vehRecId || !gpsInformation) {
      console.error('No vehRecId or GPS Information found in state/browser params:', {
        vehRecId,
        gpsInformation,
      });

      return;
    }

    setLocateNowLoading(true);
    await forceUpdateGpsLocaton(vehRecId, gpsInformation, setGpsLastLocation);

    repeat();
  };

  return !isInventory && accountInformationLoading ? (
    <Loader />
  ) : !location || !gpsLastLocation || !gpsInformation ? (
    <div>Unable to get location information for this {isInventory ? 'vehicle' : 'account'}</div>
  ) : (
    <div className={styles.locationContainer}>
      <div className={styles.locationHeader}>
        <div className={styles.locationData}>
          <div className={styles.locationDataItemWithButton}>
            <div className={styles.locationDataItem}>
              <LocationDataItem title="Date" data={gpsDate} />
              {isPassTime && <LocationDataItem title="Course" data={gpsLastLocation.course} />}
              <LocationDataItem title="Speed" data={gpsLastLocation.speed} />
              <LocationDataItem title="Address" data={gpsLastLocation.address} />
            </div>

            {locateNowLoading && <Loader style={{ marginRight: "48px", marginTop: "6px" }}/>}
            {!locateNowLoading && <MuiKendoButton onClick={handleClickUpdateLastLocate} sx={{ textWrap: 'nowrap', px: 3, textAlign: 'right' }}>
              Locate Now
            </MuiKendoButton>
            }
          </div>
          <div className={styles.locationDataItem}>
            <LocationDataItem title="Lat" data={gpsLastLocation.latitude} />
            <LocationDataItem title="Lng" data={gpsLastLocation.longitude} />
          </div>
        </div>
        {/* <MuiKendoButton onClick={handleClickUpdateLastLocate} sx={{ textWrap: 'nowrap', px: 3 }}>
          Locate Now
        </MuiKendoButton> */}
      </div>

      <Wrapper apiKey={config.googleMapsApiKey} render={(status) => <GpsMap status={status} />} />
    </div>
  );
};

export default GpsLocation;
