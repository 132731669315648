import { FC, useEffect, useState, ChangeEvent } from 'react';
// kendo
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
} from '@progress/kendo-react-all';
import { Button } from '@/components';
import { Modal } from "@/components/modals/Modal";
import TextCell from '@/components/kendoCells/TextCell';
// state
import { useWholesaleViewCtx } from '../WholesaleViewProvider';
// interfaces
import { IKendoPaginationState } from '@/interfaces/kendo';
import { WsFormVehicle } from '../interfaces';
import { KendoColumnDef } from '@/utils/tableLayout/utils';
// style
import styles from './vehicleSelectStyles.module.scss';

const initPageState = { skip: 0, take: 10 };
const paginationSettings = { buttonCount: 4, pageSizes: [initPageState.take] };
const headerClassName = styles.headerStyle;

const handlePageChange =
  (setPage: (p: IKendoPaginationState) => void) => (e: GridPageChangeEvent) => {
    setPage({ ...e.page });
  };

const paginateData = (data: any[], skip: number, take: number) => data.slice(skip, skip + take);

const VehicleSelectTable: FC = () => {
  const vehicleList = useWholesaleViewCtx((s) => s.formAddlData?.vehicles || []);
  const isModalOpen = useWholesaleViewCtx((s) => s.isModalOpen);
  const isWsCompanyModalOpen = useWholesaleViewCtx((s) => s.isWsCompanyModalOpen);
  const setWsFormField = useWholesaleViewCtx((s) => s.setWsFormField);
  const setIsModalOpen = useWholesaleViewCtx((s) => s.setIsModalOpen);

  const [page, setPage] = useState<IKendoPaginationState>({ ...initPageState });
  const [searchTerm, setSearchTerm] = useState<string>('');
  
  // We track filteredData separately so that we can properly update .length and clamp skip
  const [filteredData, setFilteredData] = useState<WsFormVehicle[]>(vehicleList);

  // The visible (paginated) data
  const [paginatedData, setPaginatedData] = useState<WsFormVehicle[]>(
    paginateData(vehicleList, page.skip, page.take)
  );

  // Event handlers
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelect = (vehicle: WsFormVehicle) => {
    setWsFormField('vehRecId', vehicle.recId || null);
    setIsModalOpen(false);
  };

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Effects
  useEffect(() => {
    // 1) Filter data by search term
    const term = searchTerm.toLowerCase();
    const fd = vehicleList.filter((v) => {
      return (
        v.stockNum?.toLowerCase()?.includes(term) ||
        v.year?.toString()?.includes(term) ||
        v.make?.toLowerCase()?.includes(term) ||
        v.model?.toLowerCase()?.includes(term) ||
        v.color?.toLowerCase()?.includes(term) ||
        v.vin?.toLowerCase()?.includes(term)
      );
    });

    // 2) If skip is out of bounds (e.g., user was on page 3 but results only have 1 page),
    //    reset skip to 0 (or clamp it).
    let { skip, take } = page;
    if (skip >= fd.length) {
      skip = 0;
    }

    // 3) Update states
    setFilteredData(fd);
    setPage((prevPage) => ({ ...prevPage, skip }));
    setPaginatedData(paginateData(fd, skip, take));
  }, [vehicleList, page.skip, page.take, searchTerm]);

  // If we want to update pagination after "page" changes (due to user clicks),
  // we can do it in a separate effect, or combine it with the one above. We'll do it here:
  useEffect(() => {
    setPaginatedData(paginateData(filteredData, page.skip, page.take));
  }, [filteredData, page.skip, page.take]);

  if (!isModalOpen || isWsCompanyModalOpen) return <></>;

  const columns: KendoColumnDef<keyof (WsFormVehicle & { select: any })>[] = [
    { field: 'stockNum', title: 'Stock Number', width: 150, headerClassName },
    { field: 'year', title: 'Year', width: 100, cell: TextCell, headerClassName },
    { field: 'make', title: 'Make', width: 100, cell: TextCell, headerClassName },
    { field: 'model', title: 'Model', width: 100, cell: TextCell, headerClassName },
    { field: 'color', title: 'Color', width: 100, cell: TextCell, headerClassName },
    { field: 'vin', title: 'VIN', width: 200, cell: TextCell, headerClassName },
    {
      field: 'select',
      title: 'Select',
      cell: (props: any) => (
        <td>
          <Button label="Select" onClick={() => handleSelect(props.dataItem)} />
        </td>
      ),
      width: 100,
      headerClassName,
    },
  ];

  const tableColumnElems = columns.map((c) => (
    <GridColumn
      field={c.field}
      title={c.title}
      width={c.width}
      cell={c.cell}
      headerClassName={c.headerClassName}
      key={c.field}
    />
  ));

  return (
    <Modal
      title="Vehicle List"
      isOpen={isModalOpen}
      closeButton
      centerModal
      onCloseButtonClick={handleCloseModal}
      panelChildrenStyle={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
    >
      {/* Simple search input. Style it as you like or replace with your own component */}
      <div style={{ marginBottom: '1rem' }}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder="Search vehicles..."
          style={{ width: '100%', padding: '0.5rem' }}
        />
      </div>

      <KendoGrid
        data={paginatedData}
        skip={page.skip}
        take={page.take}
        // IMPORTANT: total now comes from filteredData length so we have correct pagination
        total={filteredData.length}
        pageable={{ ...paginationSettings, pageSizeValue: initPageState.take }}
        onPageChange={handlePageChange(setPage)}
        style={{ overflow: 'hidden', flex: '1 1 auto' }}
      >
        <GridNoRecords>'No vehicles found'</GridNoRecords>
        {tableColumnElems}
      </KendoGrid>
    </Modal>
  );
};

export default VehicleSelectTable;
