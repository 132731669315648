import { FC, SVGProps } from 'react';
// mui
// components
import NavigationItemContainer from './NavigationItemContainer';
// style
import styles from '@/components/authenticatedContainer/AuthenticatedContainer.module.scss';
import PermissionFlag from '@/env/PermissionFlag';
import { PermissionKey } from '@/env/interfaces';

/** ###  */
const NavigationItem: FC<{
  Icon: FC<SVGProps<SVGSVGElement>>;
  name: string;
  route?: string;
  action?: () => void;
  permissionRequired?: PermissionKey;
}> = ({ Icon, name, route, action, permissionRequired = '' }) => {
  const style = ['Admin', 'Notifications', 'Inventory', 'Dashboard'].includes(name)
    ? { width: '23px', height: '23px' }
    : {};

  return (
    <PermissionFlag permissionNeeded={permissionRequired}>
      <NavigationItemContainer route={route} action={action}>
        <span className={styles.line} />
        <div className={styles.iconContainer}>
          <Icon className={styles.icon} style={style} />
        </div>
        <span className={styles.navigationItemTitle}>{name}</span>
      </NavigationItemContainer>
    </PermissionFlag>
  );
};

export default NavigationItem;
