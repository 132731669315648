import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { DCS_BLUE } from "@/mui/theme/colors";
// state
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";

/** ###  */
const TitleGroup: FC = () => {
  const reportStatus = useAiUnderwritingCtx((s) => s.latestAppEval?.finalDecision);
  const dateCreated = useAiUnderwritingCtx((s) => s.latestAppEval?.dateCreated);

  const statusColor=()=>{
    let displayColor = DCS_BLUE
    if(reportStatus === "Decline"){
      displayColor = "#C60000"
    } else if (reportStatus === "Review"){
      displayColor = "#c7a312"
    } else if (reportStatus === "Approve"){
      displayColor = "#129A38"
    }

    return displayColor
  }

  return (
    <Grid container direction="row" alignItems="center" gap={1}>
      <Grid component={Typography} color={statusColor} fontSize={18} fontWeight={700}>
        Status:
      </Grid>
      {reportStatus && (
        <Grid component={Typography} color={statusColor} fontSize={18} fontWeight={700}>
          {reportStatus}
        </Grid>
      )}

      <Grid
        component={Typography}
        color="grey"
        fontSize={14}
        mb="-3px"
        fontWeight={400}
        textAlign="end"
      >
        {dateCreated ? dateCreated.format("MM/DD/YYYY") : "No evaluations submitted"}
      </Grid>
    </Grid>
  );
};

export default TitleGroup;
