import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";
// state
import { usePmtFormCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
// services
import { paymentService } from "@/services/paymentService";
// config
import { scriptElemSelector } from "./config";
import { addFieldEventListeners, removeDuplicateFields } from "./cliqUtils";
import { loadCliqConfigScriptDeprec } from "./dom";
// validation
import { buildCliqReqBody, buildCliqReqBodyMisc } from "./cliqReqValidation";
import { CollectJsResSchema } from "./cliqResValidation";
import { CliqConfigKey, cliqFormConfig } from "./CliqForm/cliqFieldValidation";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useValidatedForm from "@/utils/forms/useValidatedForm";

const useCtxState = () => {
  // @note this provider will render only if valid `paidBy` is selected
  const setIsCliqModalOpen = usePmtFormCtx((s) => s.setIsCliqModalOpen);
  const receiptUrl = usePmtFormCtx((s) => s.receiptUrl);
  const setReceiptUrl = usePmtFormCtx((s) => s.setReceiptUrl);
  const isCliqModalOpen = usePmtFormCtx((s) => s.isCliqModalOpen);
  const apiKey = usePmtFormCtx((s) => s.paymentProviders.value?.cliqApiKey);
  const pmtFormState = usePmtFormCtx((s) => s.formState);
  const reqBodyValidation = usePmtFormCtx((s) => s.reqBodyValidation);
  const reqBodyValidationMisc = usePmtFormCtx((s) => s.reqBodyValidationMisc);
  const isDirty = usePmtFormCtx((s) => s.formState.isDirty);
  const paidBy = usePmtFormCtx((s) => s.formState.form.paidBy);
  const isCcOrAch = usePmtFormCtx((s) => s.isCcOrAch); // @note this is set by `paidBy`
  const isMiscPmt = usePmtFormCtx((s) => s.formState.config.external.isMiscPmt);

  // @note Select the fields to apply, which is determined by payment type (Credit Card, ACH, etc.)
  const appliedConfig = cliqFormConfig[paidBy as CliqConfigKey];

  const cliqFormState = useValidatedForm(appliedConfig.schema as z.ZodObject<z.ZodRawShape>);

  const [isDcsSubmitting, setIsDcsSubmitting] = useState(false);
  const [isCliqSubmitting, setIsCliqSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cliqRes, setCliqRes] = useState<CollectJsResSchema | null>(null);

  const isSubmitting = isDcsSubmitting || isCliqSubmitting;

  const cliqCallback = async (response: CollectJsResSchema) => {
    setCliqRes(response);
    setIsCliqSubmitting(false);
  };
  const validationCallback = (field: string, isValid: boolean, _message: string) => {
    cliqFormState.setField(field, isValid);
  };
  const timeoutCallback = () => {
    toast.error("Cliq: Request timed out");
    setIsDcsSubmitting(false);
    setIsCliqSubmitting(false);
  };
  const fieldsAvailableCallback = () => {
    setIsLoading(false);
    removeDuplicateFields(appliedConfig.fieldMap);
    addFieldEventListeners();
  };
  const submitPayment = async () => {
    if (!cliqRes) {
      console.warn("Exiting submitPayment (cliq): `cliqRes` cannot be null.");
      return;
    }
    if (!pmtFormState.validation.data) {
      console.warn(
        "Exiting submitPayment (cliq): Payment form validation failed:",
        pmtFormState.validation
      );
      return;
    }

    const reqBodyWithMpd = buildCliqReqBody(reqBodyValidation.validation, cliqRes);
    const reqBodyMiscWithMpd = buildCliqReqBodyMisc(reqBodyValidationMisc.validation, cliqRes);

    const reqWithMpd = isMiscPmt ? reqBodyMiscWithMpd : reqBodyWithMpd;

    if (!reqWithMpd) {
      console.warn("Invalid cliq payment");
      return;
    }

    setIsDcsSubmitting(true);
    try {
      const res = await paymentService.submitCliqPayment(reqWithMpd);

      // Request receipt
      if (!res.success) {
        const errMsgBase = "Transaction error:";
        if (res.wasBackendDuplicate) {
            console.log("Duplicate payment intercepted by system");
            return; 
        }
        if (res.wasDeclined) {
          toast.error(`${errMsgBase} Transaction declined`);
        } else if (res.wasDuplicate) {
          toast.error(`${errMsgBase} Duplicate transaction`);
        } else {
          toast.error(`${errMsgBase} ${res.responseCodeText}`);
        }
      } else if (res.paymentRecId) {
        toast.success("Successfully submitted payment");
        const url = await paymentService.getReceiptUrl(res.paymentRecId);
        setReceiptUrl(url);
        pmtFormState.resetToDefault(); // @todo remove if not necessary
        setCliqRes(null);
      } 
      // successful zero auth payment
      else if (res.success && pmtFormState.form.carPmt === 0) {
        toast.success("Successfully submitted zero dollar authorization");
        setReceiptUrl("ZeroAuth");
        pmtFormState.resetToDefault(); // @todo remove if not necessary
        setCliqRes(null);
      } else {
        toast.error(`Transaction error: Unknown`);
        console.error(res);
      }
    } finally {
      setIsDcsSubmitting(false);
    }
  };

  // This effect is for dynamically initializing CollectJS (via a `<script />` tag and config)
  useEffect(
    () => {
      if (isCliqModalOpen && isCcOrAch && apiKey && appliedConfig) {
        loadCliqConfigScriptDeprec(
          apiKey,
          {
            fields: appliedConfig.fieldLookup,
            callback: cliqCallback,
            timeoutCallback,
            validationCallback,
            fieldsAvailableCallback,
          },
          appliedConfig.fieldMap,
          setIsLoading
        );
      }
      removeDuplicateFields(appliedConfig.fieldMap);
    },
    // Reload when modal changes
    [isCliqModalOpen, isCcOrAch, apiKey, appliedConfig]
  );
  // This effect is for CollectJS: initiating the payment submission (submitting data from `iframe` elems)
  useEffect(() => {
    if (!isCliqSubmitting && cliqRes) submitPayment();
  }, [isCliqSubmitting, cliqRes]);
  // This effect is for preventing duplicate `iframe` elems (loaded via CollectJS/Cliq `<script />`) when the modal component renders.
  useEffect(() => {
    return () => {
      const existingScriptElem = document.querySelector(scriptElemSelector);
      existingScriptElem?.remove();
    };
  }, []);

  /** This effect is only for handling success-req outcomes.
   * @todo Remove. Call `setIsCliqModalOpen(false)` in correct location after successful DMS response.
   */
  useEffect(() => {
    if (!isSubmitting && !cliqRes && !isDirty && receiptUrl) setIsCliqModalOpen(false);
  }, [isSubmitting, cliqRes, isDirty, receiptUrl]);

  return {
    appliedConfig,
    cliqFormState,

    isSubmitting,
    setIsCliqSubmitting,

    isLoading,
    setIsLoading,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export const useCliqCtx = useCtxFactory(Ctx);
const CliqProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default CliqProvider;
