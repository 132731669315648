import { toast } from "react-toastify";
import { BlobServiceClient } from "@azure/storage-blob";
// utils
import { AxiosService } from "@services/axiosService";
// interfaces
import {
  GpsDevice,
  GpsHistoryItemDeprec,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/interfaces";
import { ApiResponse } from "@/interfaces/Api";
import {
  PhotoInfo,
  PhotoOutput,
  UserInfo,
  FormIds,
  GenericPasstimeResponse,
  GetPasstimeCustomerResponse,
  GetPasstimeGpsHistoryResponse,
  PasstimeCodesPayload,
  PasstimeTotalMilesResponse,
  PasstimeWarnPayload,
} from "@/interfaces/System";
import { isAxiosError } from "axios";
import { augmentPasstimeCommands } from "@/services/gpsCommands/passtimeCommands";
import { augmentSpireonCommands } from "@/services/gpsCommands/spireonCommands";
import { augmentIturanCommands } from "@/services/gpsCommands/ituranCommands";
import { IturanDeviceActivityResponse, IturanDeviceDetailResponse } from "@/interfaces/Gps/Ituran";
import { GetSpireonEventsResponse, GetSpireonAssetResponse } from "@/interfaces/Gps/Spireon";
export class SystemService extends AxiosService {
  public constructor() {
    super();
  }

  async getGpsModels(provider: GpsDevice) {
    try {
      const { data } = await this.axios.get<{ recId: number; model: string; provider: string }[]>(
        "/System/GpsModels",
        {
          params: { provider },
        }
      );
      return data ? data.map((d) => d.model) : [];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getBlobUploadUrl(orgId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>("/System/GetSasUploadUrl", {
        params: { orgId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async uploadBlob(blob: File, orgId: number, fileNameCloud: string) {
    try {
      const sasUrl = await this.getBlobUploadUrl(orgId);
      const blobServiceClient = new BlobServiceClient(sasUrl);
      const containerClient = blobServiceClient.getContainerClient(""); // Container is set on the URL
      const blockBlobClient = containerClient.getBlockBlobClient(fileNameCloud);

      await blockBlobClient.upload(blob, blob.size, {
        blobHTTPHeaders: { blobContentType: blob.type },
      });

      const sasUrlWithoutToken = sasUrl.split("?")[0];
      const fileUrl = `${sasUrlWithoutToken}/${fileNameCloud}`;
      return fileUrl;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getBlobReadUrl(orgId: number, blobName: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>("/System/GetSasReadUrl", {
        params: { orgId, blobName },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getBlobReadUrls(photoList: PhotoInfo[]) {
    try {
      const { data } = await this.axios.post<ApiResponse<PhotoOutput[]>>(
        "/System/GetSasReadUrls",
        photoList
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getUserInfo() {
    try {
      const res = await this.axios.get<ApiResponse<UserInfo>>("/System/UserInfo");
      if (!res.data.data) throw new Error("UserInfo response is empty");

      return res.data.data;
    } catch (e) {
      if (!isAxiosError(e)) throw e;
      toast.error("Error fetching user info");
      console.warn("e.response", e.response);
      console.warn("e.response?.data", e.response?.data);
      console.warn("e.response?.data.Type", e.response?.data.Type);
      console.error(e);
      throw e;
    }
  }

  async getFormIds(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<FormIds>>("/System/FormIds", {
        params: { compId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async mappedCompanies() {
    try {
      const { data } = await this.axios.get<ApiResponse<[]>>("/System/MappedCompanies");
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSignalrToken() {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>("/System/GetSignalrToken");
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export interface SystemService {
  getPasstimeTotalMiles(inventoryRecId: number): Promise<PasstimeTotalMilesResponse>;
  setPasstimeTotalMiles(inventoryRecId: number, totalMiles: number): Promise<GenericPasstimeResponse>;
  addPasstimeCustomer(inventoryRecId: number): Promise<GenericPasstimeResponse>;
  getPasstimeCustomer(inventoryRecId: number): Promise<GetPasstimeCustomerResponse>;
  updatePasstimeCustomer(inventoryRecId: number): Promise<GenericPasstimeResponse>;
  deletePasstimeCustomer(inventoryRecId: number): Promise<GenericPasstimeResponse>;
  updatePasstimeMap(inventoryRecId: number): Promise<void>;
  getPasstimeLastLocate(inventoryRecId: number): Promise<GpsHistoryItemDeprec | undefined>;
  getPasstimeGpsHistoryByDate(inventoryRecId: number, beginDate: string, endDate: string): Promise<GetPasstimeGpsHistoryResponse>;
  getPasstimeGpsHistory(inventoryRecId: number): Promise<GpsHistoryItemDeprec[]>;
  enableDisablePasstimeDevice(inventoryRecId: number, enable: boolean): Promise<void>;
  sendPasstimeEmergencyCode(inventoryRecId: number): Promise<void>;
  sendPasstimeWarningNoDisable(payload: PasstimeWarnPayload): Promise<void>;
  generatePasstimeCode(payload: PasstimeCodesPayload): Promise<GenericPasstimeResponse>;
  getSpireonAssetLocateEvents(inventoryRecId: number, startDate: string, endDate: string): Promise<GetSpireonEventsResponse>;
  getSpireonGpsHistory(inventoryRecId: number): Promise<GpsHistoryItemDeprec[]>;
  getSpireonAsset(inventoryRecId: number): Promise<GetSpireonAssetResponse>;
  getSpireonLastLocate(inventoryRecId: number): Promise<GpsHistoryItemDeprec | undefined>;
  updateSpireonMap(inventoryRecId: number): Promise<void>;
  sendSpireonEnableDisableCommand(inventoryRecId: number, enableVehicle: boolean): Promise<void>;
  installSpireonSerialNumber(inventoryRecId: number, serialNumber: string, currentOdometer: number): Promise<void>;
  uninstallSpireon(inventoryRecId: number): Promise<void>;
  updateSpireonOdometer(inventoryRecId: number, currentOdometer: number): Promise<void>;
  getIturanDeviceActivity(inventoryRecId: number): Promise<IturanDeviceActivityResponse>;
  getIturanGpsHistory(inventoryRecId: number): Promise<GpsHistoryItemDeprec[]>;
  getIturanLastLocate(inventoryRecId: number): Promise<GpsHistoryItemDeprec | undefined>;
  sendIturanLocateCommand(inventoryRecId: number): Promise<GpsHistoryItemDeprec>;
  getIturanDeviceDetail(inventoryRecId: number): Promise<IturanDeviceDetailResponse>;
  sendIturanEnableDisable(inventoryRecId: number, enableVehicle: boolean): Promise<void>;
  turnOnIturanCollectionTool1(inventoryRecId: number): Promise<void>;
  turnOnIturanCollectionTool2(inventoryRecId: number): Promise<void>;
  turnOnIturanCollectionTool3(inventoryRecId: number): Promise<void>;
  turnOffIturanCollectionTool(inventoryRecId: number): Promise<void>;
}

augmentPasstimeCommands(SystemService);
augmentSpireonCommands(SystemService);
augmentIturanCommands(SystemService);
export const systemService = new SystemService();
