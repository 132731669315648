import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { formatCurrency } from '@/utils/helpers/general';  

export const CurrencyCell: React.FC<GridCellProps> = (props) => {
  const value = props.dataItem[props.field!];  
  const numericValue = Number(value ?? 0);
  
  return (
    <td style={{ textAlign: 'right' }}>
      {formatCurrency(numericValue)}
    </td>
  );
};