import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// kendo
import { Checkbox } from '@/components/checkbox/Checkbox';
import { Spacer } from '@/components/spacer/Spacer';
import { Button } from '@/components/button/Button';
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { FunctionLink } from '@/components/functionLink/FunctionLink';
import { FormLinkWrapper } from '@/components/formLinkWrapper/formLinkWrapper';
// components
import Hotlist from '@/components/hotlist/Hotlist';
import { SelectVendorModal } from '@components/selectVendorModal/selectVendorModal';
import PhysicalLocationLogModal from './PhysicalLocLogModal';
import TransferToModal from './transferToModal';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useHotlistCtx } from '@/components/hotlist/HotlistProvider';
import { useInventoryDetailCtx } from '../../../InventoryDetailProvider';
// utils
import { inventoryService } from '@/services/inventoryService';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { genericRequired, notGreaterThanTodayValidation } from '@/utils/helpers/formValidation';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { SetState } from '@/interfaces/utilityTypes';
import { Vendor } from '@/interfaces/Vendors';
import { GeneralInformation } from '@/interfaces/Inventory';
import { InvType } from './interfaces';
// style
import styles from '../InventoryVehicle.module.scss';

/** ###  */
const GeneralInfoForm: FC<{ setDirty: SetState<boolean> }> = ({ setDirty }) => {
  const backDateInv = useAuthSelector((s) => s.backDateInv);
  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);
  const generalInformation = useInventoryDetailCtx((s) => s.generalInformation);

  const invRecId = getRouteParamNum(useParams().invRecId);

  const [physicalLocationOptions, setPhysicalLocationOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transferModalOpened, setTranferModalOpened] = useState(false);
  const [vendorListModalOpened, setVendorListModalOpened] = useState(false);
  const [physicalLocationLogModalOpened, setPhysicalLocationLogModalOpened] = useState(false);

  const purDate = formatDate(generalInformation?.purDate || '', { pattern: DateFormat.DateInput });
  const dateIn = formatDate(generalInformation?.dateIn || '', { pattern: DateFormat.DateInput });
  const invDate = formatDate(generalInformation?.invDate || '', { pattern: DateFormat.DateInput });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<GeneralInformation>({
    defaultValues: { ...(generalInformation || {}), purDate, dateIn, invDate },
  });

  useEffect(() => {
    reset(watch(), { keepDirty: false });
  }, [isSubmitSuccessful]);

  const selectVendor = (vendor: Vendor) => {
    setValue('purFrom', vendor.vendorName, { shouldDirty: true });
    setValue('purVendId', vendor.recId!, { shouldDirty: true });
  };

  const resetForm = (generalInformation?: GeneralInformation) => {
    if (!generalInformation) return;

    reset({
      ...generalInformation,
      purDate: formatDate(generalInformation.purDate || '', { pattern: DateFormat.DateInput }),
      dateIn: formatDate(generalInformation.dateIn || '', { pattern: DateFormat.DateInput }),
      invDate: formatDate(generalInformation.invDate || '', { pattern: DateFormat.DateInput }),
    });
  };

  const loadPhysicalLocationOptions = async () => {
    if (!generalInformation) return;

    const options = await inventoryService.getPhysicalLocationOptions(
      generalInformation.orgId.toString()
    );
    setPhysicalLocationOptions(options || []);
  };

  const submitGeneralInformationForm = async (data: GeneralInformation) => {
    if (!generalInformation || !invRecId) return;

    try {
      const cleanedData = {
        ...data,
        invDate: data.invDate === '' ? null : data.invDate,
        dateIn: data.dateIn || null, 
      };
  
      setIsLoading(true);
      const updatedGeneralInformation = await inventoryService.updateGeneralInformation(
        invRecId,
        cleanedData
      );
      resetForm(updatedGeneralInformation);
      await loadPhysicalLocationOptions();

      fetchHotlist(invRecId, 'Inventory', 'Active');
      toast.success('General information updated!');
    } catch (error) {
      console.error(error);
      toast.error('Unable to update general information');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty]);

  if (!generalInformation) return <></>;

  const vehicleSold = generalInformation.saleStatus?.toLowerCase() === 's';
  const purchased = generalInformation.purType?.toLowerCase() === 'purchase';

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => submitGeneralInformationForm(data))}
        style={{ marginTop: '20px' }}
      >
        <div className={styles.columns}>
          <div className={styles.column}>
            <Controller
              name="purDate"
              control={control}
              rules={{ ...genericRequired, ...notGreaterThanTodayValidation }}
              render={({ field }) => (
                <DateInput
                  required
                  label="Purchase Date"
                  errors={errors.purDate?.message}
                  disabled={vehicleSold && !backDateInv}
                  {...field}
                />
              )}
            />
            <Controller
              name="invDate"
              control={control}
              rules={{
                validate: (d: string | null) => {
                  const today = dayjs();
                  const received = dayjs(d);
                  const purchased = watch('purDate') ? dayjs(watch('purDate')) : undefined;
                  if (received > today) return "Cannot be greater than today's date";
                  if (purchased && received < purchased)
                    return 'Cannot be less than date purchased';
                  return undefined;
                },
              }}
              render={({ field }) => (
                <DateInput label="Date Received" errors={errors.invDate?.message} {...field} />
              )}
            />
            {!vehicleSold && (
              <FormLinkWrapper>
                <FunctionLink onClick={() => setTranferModalOpened(true)} label="Transfer To" />
              </FormLinkWrapper>
            )}
            <Controller
              name="locDate"
              control={control}
              render={({ field }) => <DateInput readOnly label="Date at Location" {...field} />}
            />
            <Controller
              name="purFrom"
              control={control}
              render={({ field }) => <TextInput label="From" disabled={true} required {...field} />}
            />
            {purchased && (
              <FormLinkWrapper>
                <FunctionLink
                  onClick={() => setVendorListModalOpened(true)}
                  label="Select Vendor"
                />
              </FormLinkWrapper>
            )}
            <Controller
              name="buyerEmp"
              control={control}
              render={({ field }) => <TextInput label="Buyer's Name" {...field} />}
            />
            <Controller
              name="psi"
              control={control}
              render={({ field }) => (
                <TextInput label="PSI Days" disabled={vehicleSold} {...field} />
              )}
            />
            <Controller
              name="purType"
              control={control}
              render={({ field }) => <TextInput label="Purchase Type" disabled={true} {...field} />}
            />
            <Controller
              name="invType"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Sale Type"
                  data={InvType.options}
                  disabled={vehicleSold}
                  {...field}
                />
              )}
            />
            <Controller
              name="phyLocation"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  label="Physical Location"
                  data={physicalLocationOptions}
                  disabled={vehicleSold}
                  allowCustom
                  onOpen={() => loadPhysicalLocationOptions()}
                  {...field}
                />
              )}
            />
            <FormLinkWrapper>
              <FunctionLink
                onClick={() => setPhysicalLocationLogModalOpened(true)}
                label="Physical Location Log"
              />
            </FormLinkWrapper>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 1.25 }}>
                <Controller
                  name="extraKeys"
                  control={control}
                  render={({ field }) => <Checkbox label="Extra Keys" {...field} />}
                />
              </div>
            </div>
            <Controller
              name="milesIn"
              control={control}
              defaultValue='0'
              rules={{
                required: 'Miles In is required',
                min: { value: 0, message: 'Miles In cannot be less than 0' },
              }}
              render={({ field }) => (
                <TextInput
                  label="Miles In"
                  disabled={vehicleSold}
                  errors={errors.milesIn?.message}
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name="milesOut"
              control={control}
              rules={{
                min: { value: 0, message: 'Miles Out cannot be less than 0' },
              }}
              render={({ field }) => (
                <TextInput
                  label="Miles Out"
                  disabled={vehicleSold}
                  errors={errors.milesOut?.message}
                  {...field}
                />
              )}
            />
            <div className={styles.columns} style={{ gap: '10px' }}>
              <div className={styles.column}>
                <Controller
                  name="notActual"
                  control={control}
                  render={({ field }) => (
                    <Checkbox label="Not Actual" disabled={vehicleSold} {...field} />
                  )}
                />
              </div>
              <div className={styles.column}>
                <Controller
                  name="exceedsLim"
                  control={control}
                  render={({ field }) => (
                    <Checkbox label="Exceeds Limit" disabled={vehicleSold} {...field} />
                  )}
                />
              </div>
              <div className={styles.column}>
                <Controller
                  name="exempt"
                  control={control}
                  render={({ field }) => (
                    <Checkbox label="Exempt" disabled={vehicleSold} {...field} />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <span className={styles.sectionSubHeader}>Alerts</span>
            <Controller
              name="alert1"
              control={control}
              render={({ field }) => <Checkbox label="TMU (True Miles Unknown)" {...field} />}
            />
            <Controller
              name="alert2"
              control={control}
              render={({ field }) => <Checkbox label="Salvage Title" {...field} />}
            />
            <Controller
              name="alert3"
              control={control}
              render={({ field }) => <Checkbox label="Frame Damage" {...field} />}
            />
            <Controller
              name="alert4"
              control={control}
              render={({ field }) => <Checkbox label="Unibody" {...field} />}
            />
            <Controller
              name="alert6"
              control={control}
              render={({ field }) => <Checkbox label="Airbag" {...field} />}
            />
            <div className={styles.row}>
              <Controller
                name="alert5"
                control={control}
                render={({ field }) => <Checkbox {...field} />}
              />
              <Controller
                name="alert5N"
                control={control}
                render={({ field }) => <TextInput {...field} />}
              />
            </div>
            <Spacer size={10} />
            <span className={styles.sectionSubHeader}>SAM</span>
            <div style={{ maxWidth: '400px' }}>
              <Hotlist
                recId={invRecId}
                transType="Inventory"
                initialStatusFilter="Active"
                showStatusFilters
              />
            </div>
          </div>
        </div>

        <div className={styles.buttonRow}>
          <Button label="Submit" secondary={!isDirty} disabled={!isDirty} loading={isLoading} />
          <Button label="Cancel" secondary={!isDirty} disabled={!isDirty} onClick={() => reset()} />
        </div>
      </form>

      <PhysicalLocationLogModal
        opened={physicalLocationLogModalOpened}
        setOpened={setPhysicalLocationLogModalOpened}
      />

      <TransferToModal opened={transferModalOpened} setOpened={setTranferModalOpened} />

      <SelectVendorModal
        opened={vendorListModalOpened}
        setOpened={setVendorListModalOpened}
        selectVendor={selectVendor}
      />
    </>
  );
};

export default GeneralInfoForm;
