import React, { FC } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
// mui
import Typography from "@mui/material/Typography";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";
// utils
import { DateFormat } from "@/utils/helpers/general";

/** ### DateCell Component */
const DateCell: FC<GridCellProps & { isDatetime?: boolean; dtFormat?: DateFormat | string }> = ({
  dataItem,
  field,
  isDatetime = false,
  dtFormat,
}) => {
  const cellValue: string = dataItem[field!];
  // Convert to date/datetime format
  const cellValueDt = isDatetime ? dayjs.utc(cellValue).local() : dayjs.utc(cellValue); 
  const isDt = cellValueDt.isValid();

  // Define appliedFormat based on isDatetime prop and default to "MM/DD/YYYY" if no format is provided
  const appliedFormat = dtFormat || (isDatetime ? DateFormat.DateTime : "MM/DD/YYYY");

  // Format cellValue with applied format if valid, otherwise display "Invalid date"
  const cellValueDtFmt = isDt ? cellValueDt.format(appliedFormat) : "Invalid date";

  // Display "-" if cellValue is empty, else formatted date
  const cellValueFmt = !cellValue ? "-" : cellValueDtFmt;

  return (
    <td className="k-table-td" style={{ textAlign: "center" }}>
      <Typography component="span" variant="tableCell">
        {cellValueFmt}
      </Typography>
    </td>
  );
};

export default DateCell;
