import { useAuthSelector } from '@/features/auth/authSlice';
import { PermissionKey } from './interfaces';

const usePermissionFlag = (permission: PermissionKey) => {
  const { permissions, userId, hasAfsLevel } = useAuthSelector((s) => s);
  if (!userId) return false; //Has not yet loaded userInfo
  if (!permission || hasAfsLevel || permissions?.includes('SuperAdmin')) return true; //Needs no permission
  if (!permissions || permissions.length == 0) return false; //Has no permissions
  return permissions.includes(permission);
};

export default usePermissionFlag;
