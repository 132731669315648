import { z } from "zod";

// @note update labels to: ['dev', 'qa', 'staging', 'prod']
export const RuntimeEnvKey = z.enum(["development", "qa", "staging", "production"]);
export type RuntimeEnvKey = z.infer<typeof RuntimeEnvKey>;

export const RuntimeEnvConfigValidation = z.object({
  key: RuntimeEnvKey,
  level: z.number(),
  label: z.string().default(""),
  isStandalone: z.boolean(),
});
export type RuntimeEnvConfigValidation = z.infer<typeof RuntimeEnvConfigValidation>;

export interface AppConfig {
  localUrl: string;
  apiUrl: string;
  openEdgeEnvironment: "test" | "prod";
  signalrUrl: string;
  googleMapsApiKey: string;
  idsUrl: string;
}


/* Left-nav Permissions Enums*/
const NavBarPermissionKey = z.enum(["Accounts", "Collections", "Inventory", "Sales", "Reports", "Settings", "Security", "RTD"]);
type NavBarPermissionKey = z.infer<typeof NavBarPermissionKey>;

/* Sales Permissions Enums*/
const SalesPermissionKey = z.enum(["Sales_All",  "Sales_Sold",  "Sales_WS",  "Sales_Cash", "Sales_Unpost"]);
type SalesPermissionKey = z.infer<typeof SalesPermissionKey>;

/*Compilation of all permissions, for ease of use and case safety*/
export const PermissionKey = z.enum([
  '', 
  "SuperAdmin", 
  ...NavBarPermissionKey.options, 
  ...SalesPermissionKey.options,
]);
export type PermissionKey = z.infer<typeof PermissionKey>;