import { FC } from "react";
import { Controller } from "react-hook-form";
// kendo
import { Loader } from "@progress/kendo-react-all";
import { Button, Checkbox, DropdownInput, NumberInput, TextInput } from "@/components";
import { Modal } from "@/components/modals/Modal";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// components
import { MultipleInputRow } from "../../../multipleInputRow/MultipleInputRow";
// state
import { useSalesTax } from "./useSalesTax";
// utils
import { useNavUp } from "@/utils/routing/useNavUpEvent";
// style
import salesTabStyles from "../../SalesTab.module.scss";
import styles from "./SalesTax.module.scss";

const multipleInputRowLabelStyles = { flex: 0, minWidth: "75px" };
const multipleInputRowLabelStylesAddons = { flex: 0 };
const addonLabelStyle = { flex: 1.25, minWidth: "175px" };

export const SalesTax: FC = () => {
  const navUp = useNavUp();
  const {
    control,
    handleSubmit,
    taxOnData,
    financeTaxRatesLoading,
    showConfirmationModal,
    setShowConfirmationModal,
    onResetClick,
    onResetYesClick,
    updateTaxSettings,
    updateTaxSettingsLoading,
  } = useSalesTax();

  const ResetConfirmationModal: FC = () => {
    return (
      <Modal
        isOpen={showConfirmationModal}
        onCloseButtonClick={() => setShowConfirmationModal(false)}
        closeButton
        centerModal
      >
        <h2 className={salesTabStyles.header}>Are you sure you want to reset the form?</h2>
        <Spacer size={16} />
        <div className={styles.confirmationModalButtons}>
          <Button
            type="button"
            fillMode="outline"
            secondary
            label="No"
            onClick={() => setShowConfirmationModal(false)}
          />
          <Button type="button" themeColor="warning" label="Yes" onClick={onResetYesClick} />
        </div>
      </Modal>
    );
  };

  if (financeTaxRatesLoading) {
    return (
      <div className={salesTabStyles.loader}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <div className={salesTabStyles.container}>
      <header className={salesTabStyles.headerContainer}>
        <h2 className={salesTabStyles.header}>Sales Tax</h2>
        <div className={salesTabStyles.headerButtonContainer}>
          <Button label="Terms/Quotes" onClick={navUp} />
        </div>
      </header>
      <main className={salesTabStyles.body} style={{ overflow: "scroll" }}>
        <form onSubmit={handleSubmit(updateTaxSettings)}>
          <div className={salesTabStyles.bodyContainer}>
            <div className={salesTabStyles.bodyChild}>
              <h3 className={salesTabStyles.subHeading}>Sales Tax Settings</h3>
              <Spacer size={16} />
              <div className={styles.labelOuterContainer}>
                <div style={{ minWidth: "75px" }}></div>
                <div className={styles.labelInnerContainer}>
                  <label className={styles.label}>Rate</label>
                  <label className={styles.label}>On</label>
                  <label className={styles.label}>Max</label>
                  <label className={styles.label}>Min</label>
                </div>
              </div>
              <MultipleInputRow label="State" labelStyles={multipleInputRowLabelStyles}>
                <Controller
                  name="stateRate"
                  control={control}
                  render={({ field }) => <NumberInput format="p3" {...field} />}
                />
                <Controller
                  name="stateOn"
                  control={control}
                  render={({ field }) => <DropdownInput data={taxOnData} {...field} />}
                />
                <Controller
                  name="stateMax"
                  control={control}
                  render={({ field }) => <CurrencyInput {...field} />}
                />
                <Controller
                  name="stateMin"
                  control={control}
                  render={({ field }) => <CurrencyInput {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="County" labelStyles={multipleInputRowLabelStyles}>
                <Controller
                  name="countyRate"
                  control={control}
                  render={({ field }) => <NumberInput format="p3" {...field} />}
                />
                <Controller
                  name="countyOn"
                  control={control}
                  render={({ field }) => <DropdownInput data={taxOnData} {...field} />}
                />
                <Controller
                  name="countyMax"
                  control={control}
                  render={({ field }) => <CurrencyInput {...field} />}
                />
                <Controller
                  name="countyMin"
                  control={control}
                  render={({ field }) => <CurrencyInput {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="City" labelStyles={multipleInputRowLabelStyles}>
                <Controller
                  name="cityRate"
                  control={control}
                  render={({ field }) => <NumberInput format="p3" {...field} />}
                />
                <Controller
                  name="cityOn"
                  control={control}
                  render={({ field }) => <DropdownInput data={taxOnData} {...field} />}
                />
                <Controller
                  name="cityMax"
                  control={control}
                  render={({ field }) => <CurrencyInput {...field} />}
                />
                <Controller
                  name="cityMin"
                  control={control}
                  render={({ field }) => <CurrencyInput {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow label="Location" labelStyles={multipleInputRowLabelStyles}>
                <Controller
                  name="locationRate"
                  control={control}
                  render={({ field }) => <NumberInput format="p3" {...field} />}
                />
                <Controller
                  name="locationOn"
                  control={control}
                  render={({ field }) => <DropdownInput data={taxOnData} {...field} />}
                />
                <Controller
                  name="locationMax"
                  control={control}
                  render={({ field }) => <CurrencyInput {...field} />}
                />
                <Controller
                  name="locationMin"
                  control={control}
                  render={({ field }) => <CurrencyInput {...field} />}
                />
              </MultipleInputRow>
            </div>
            <div className={salesTabStyles.bodyChild}>
              <h3 className={salesTabStyles.subHeading}>Taxable Settings for Addons</h3>
              <Spacer size={16} />
              <div className={styles.labelInnerContainer}>
                <div className={styles.label} style={addonLabelStyle}></div>
                <label className={styles.label}>State</label>
                <label className={styles.label}>County</label>
                <label className={styles.label}>City</label>
                <label className={styles.label}>Location</label>
              </div>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon1"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax1"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax1"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax1"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax1"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon2"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax2"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax2"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax2"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax2"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon3"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax3"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax3"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax3"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax3"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon4"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax4"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax4"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax4"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax4"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon5"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax5"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax5"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax5"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax5"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon6"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax6"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax6"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax6"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax6"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon7"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax7"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax7"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax7"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax7"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon8"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax8"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax8"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax8"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax8"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon9"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax9"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax9"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax9"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax9"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
              <MultipleInputRow labelStyles={multipleInputRowLabelStylesAddons}>
                <div style={addonLabelStyle}>
                  <Controller
                    name="addon10"
                    control={control}
                    render={({ field }) => <TextInput {...field} />}
                  />
                </div>
                <Controller
                  name="stateTax10"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="countyTax10"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="cityTax10"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Controller
                  name="locationTax10"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              </MultipleInputRow>
            </div>
          </div>
          <Spacer size={40} />
          <div className={salesTabStyles.buttonContainer}>
            <Button
              fillMode="outline"
              secondary
              label="Reset"
              themeColor="error"
              type="button"
              onClick={onResetClick}
            />
            <Button label="Submit" loading={updateTaxSettingsLoading} />
          </div>
        </form>
      </main>
      <ResetConfirmationModal />
    </div>
  );
};
