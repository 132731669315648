import { FC } from "react";
// kendo
import { TextInput } from "@/components";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** Payment Note Input Field */
const PaymentNoteField: FC = () => {
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("payNote"));

  return (
    <TextInput
      label="Payment Note"
      placeholder="Enter any payment notes..."
      {...fieldProps}
    />
  );
};

export default PaymentNoteField;