import { z } from "zod";
import { PaymentInterval, RepayFeeModel } from "@/enums/payment";
import { ColTypeCode } from "@/enums/general";
import { UsaStateCode } from "@/general/regions";
import { buildDefaultSchema } from "@/utils/forms/useValidatedForm/utils";
import dayjs, { Dayjs } from "dayjs";

export interface UpdateEmailPostReq {
  colRecId: number;
  appRecId: number;
  buyerCoBuyerRecId: number;
  isBuyer: boolean;
  email: string;
}

export interface UpdateEmailOptOutStatusPostReq {
  colRecId: number;
  appRecId: number;
  optOut: boolean;
  isBuyer: boolean;
}

export interface TextToPayNumberPayload {
  colRecId: number;
  appRecId: number;
  isBuyer: boolean;
  buyerCoBuyerRecId: number;
  cellPhone: number;
}

export interface IPaymentProviderConfigRes {
  achEnabled: boolean;
  allowPaymentProviderSelection: boolean;
  blytzpayEnabled: boolean;
  cliqApiKey: string;
  cliqEnabled: boolean;
  openEdgeDmsAchConvFee: number;
  openEdgeDmsCcConvFee: number;
  /** @todo rename to camelCase here and on backend */
  openedgeEnabled: boolean;
  openEdgeEnv: "test" | string;
  /** OpenEdge API key - @todo rename to `openEdgeApiKey` on backend */
  payfieldsApiKey: string;
  /** see `CardProcessor` enum */
  preferredPaymentProvider: number;
  repayCanWaiveFeeProd: boolean;
  repayCanWaiveFeeTest: boolean;
  repayCfeeModelProd: RepayFeeModel;
  repayCfeeModelTest: RepayFeeModel;
  repayConvFeeProd: number;
  repayConvFeeTest: number;
  repayEnabled: boolean;
  repayTestMode: boolean;
}

export interface SaveMpdForm {
  recurringIntervalRecIdPending: number;
  StartDate_pending: Dayjs;
  amtPending: number;
}

export interface SaveMpdReq {
  recId: number;
  recurringAuthCell: string;
  email: string;
  recurringStatusPending: string;
  recurringStatus: string;
  recurringIntervalRecIdPending: number;
  dayDatePending: string;
  startDatePending: string;
  amtPending: number;
  convFeePending: number;
}

export interface DeactivateMpdReq {
  recId: number;
  recurringAuthCell: string;
}

export interface SendAuthReq {
  recId: number;
  compId: number;
  appRecId: number;
  recurringAuthCell: string;
  authMessage: string;
}

export interface AuthDocumentUpload {
  file: File;
  orgId: number;
  compId: number;
  locId: number;
  mpdRecId: number | null;
  appRecId: number | null;
}

const paymentProviderConfig = z.object({
  achEnabled: z.boolean(),
  allowPaymentProviderSelection: z.boolean(),
  blytzpayEnabled: z.boolean(),
  cliqApiKey: z.string(),
  cliqEnabled: z.boolean(),
  openEdgeDmsAchConvFee: z.number(),
  openEdgeDmsCcConvFee: z.number(),
  openedgeEnabled: z.boolean(),
  openEdgeEnv: z.string().default("test"),
  payfieldsApiKey: z.string(),
  preferredPaymentProvider: z.number(),
  repayCanWaiveFeeProd: z.boolean(),
  repayCanWaiveFeeTest: z.boolean(),
  repayCfeeModelProd: RepayFeeModel,
  repayCfeeModelTest: RepayFeeModel,
  repayConvFeeProd: z.number(),
  repayConvFeeTest: z.number(),
  repayEnabled: z.boolean(),
  repayTestMode: z.boolean(),
});

const buyerFields = z.object({
  buyerAddr: z.string(),
  buyerCity: z.string(),
  buyerCPhone: z.string(),
  buyerDob: z.string(), // zDayjs
  buyerEmail: z.string(),
  buyerEmployer: z.string(),
  buyerFirstName: z.string(),
  buyerHPhone: z.string(),
  buyerLastName: z.string(),
  buyerNoCall: z.boolean(),
  buyerNoEmail: z.boolean(),
  buyerNoText: z.boolean(),
  buyerRecId: z.number(),
  buyerSsn: z.string(),
  buyerState: UsaStateCode,
  buyerWPhone: z.string(),
  buyerZip: z.string(),
});
const cobuyerFields = z.object({
  cobuyerAddr: z.string(),
  cobuyerCity: z.string(),
  cobuyerCPhone: z.string(),
  cobuyerDob: z.string(), // zDayjs
  cobuyerEmail: z.string(),
  cobuyerEmployer: z.string(),
  cobuyerFirstName: z.string(),
  cobuyerHPhone: z.string(),
  cobuyerLastName: z.string(),
  cobuyerNoCall: z.boolean(),
  cobuyerNoEmail: z.boolean(),
  cobuyerNoText: z.boolean(),
  coBuyerRecId: z.number(),
  cobuyerSsn: z.string(),
  cobuyerState: UsaStateCode,
  cobuyerWPhone: z.string(),
  cobuyerZip: z.string(),
});
const displayFields = z.object({
  display_AcctInfo: z.string(),
  display_Bal1: z.string(),
  display_Bal2: z.string(),
  display_Bal3: z.string(),
  display_Label1: z.string(),
  display_Label2: z.string(),
  display_Label3: z.string(),
  display_Label4: z.string(),
  display_Label5: z.string(),
  display_Label6: z.string(),
  display_Label7: z.string(),
  display_Label8: z.string(),
  display_LabelDueDate: z.string(),
  display_LP1: z.string(),
  display_LP2: z.string(),
  display_PA1: z.string(),
  display_PA2: z.string(),
  display_PA3: z.string(),
  display_Value1: z.string(),
  display_Value2: z.number(),
  display_Value3: z.number(),
  display_Value4: z.number(),
  display_Value5: z.number(),
  display_Value6: z.number(),
  display_Value7: z.number(),
  display_Value8: z.number(),
  display_ValueBal1: z.number(),
  display_ValueBal2: z.number(),
  display_ValueBal3: z.number(),
  display_ValueDueDate: z.string(), // zDayjs
  display_ValueLP1: z.number(),
  display_ValueLP2: z.string(), // zDayjs
  display_ValuePA2: z.string(),
  display_ValuePA3: z.number(),
  displayPaidPayments: z.string(),
});
const repayFields = z.object({
  repayCanWaiveFeeProd: z.boolean(),
  repayCanWaiveFeeTest: z.boolean(),
  repayCfeeModelProd: z.string(),
  repayCfeeModelTest: z.string(),
  repayConvFeeProd: z.number(),
  repayConvFeeTest: z.number(),
  repayTestMode: z.boolean(),
});

const basePaymentDataRes = z
  .object({
    accountNum: z.string(),
    accrued: z.number(),
    achConvenienceFee: z.number(),
    allowPartialPaymentForPaymentArrangement: z.boolean(),
    allowPartialPaymentWhenPastDue: z.boolean(),
    /** `amountFin` in DB  */
    amountFinanced: z.number(),
    amtDue: z.number(),
    appRecId: z.number(),
    buyOut: z.number(),
    cdBal: z.number(),
    color: z.string(),
    colRecId: z.number(),
    colType: ColTypeCode,
    compId: z.number(),
    convenienceFee: z.number(),
    cpiAvailable: z.number(),
    cpiDueNow: z.number(),
    cpiFirstDueDate: z.string(), // zDayjs
    cpiHold: z.number(),
    cpiRate: z.number(),
    cpiSchedule: PaymentInterval,
    cpiStatus: z.string(),
    cpiTotalPaid: z.number(),
    dateSold: z.string(), // zDayjs
    daysLate: z.number(),
    daysLateLabel: z.string(),
    ddDueNow: z.number(),
    ddNextAmt: z.number(),
    ddNextDue: z.string(), // zDayjs
    defDownBal: z.number(),
    dmsDaysLate: z.number(),
    dmsNextDueAmount: z.number(),
    dmsNextDueDate: z.string(), // zDayjs
    dpa: z.boolean(),
    gpiCustStatmentEnabled: z.boolean(),
    hasCobuyer: z.boolean(),
    insCanceled: z.boolean(),
    insCanDate: z.string(), // zDayjs
    insCName: z.string(),
    insCompDed: z.number(),
    insCoverage: z.string(),
    insExpire: z.string(), // zDayjs
    insFireDed: z.number(),
    insPolicyNum: z.string(),
    insuranceStatus: z.string(),
    intRate: z.number(),
    iopStatus: z.boolean(),
    lastPmtAmt: z.number(),
    lastPmtPaid: z.string(), // zDayjs
    lcDue: z.number(),
    legalStatus: z.boolean(),
    locId: z.number(),
    make: z.string(),
    maxPayment: z.number(),
    minAchAmount: z.number(),
    minCreditCardAmount: z.number(),
    miscDue: z.number(),
    model: z.string(),
    netBuyOut: z.number(),
    nextDueAmount: z.number(),
    nextDueDate: z.string(), // zDayjs
    nextPaymentDue: z.any(),
    nowLocal: z.string(), // zDayjs
    nsfDue: z.number(),
    numberPaymentsLatePaid: z.number(),
    numberPaymentsOnTime: z.number(),
    numberPaymentsPaid: z.number(),
    numberPaymentsTotal: z.number(),
    onCpi: z.boolean(),
    openedgeApiKey: z.string(),
    openEdgeDmsAchConvFee: z.number(),
    openEdgeDmsCcConvFee: z.number(),
    orgId: z.number(),
    originalBal: z.number(),
    paAmt: z.number(),
    paymentAmount: z.number(),
    payOff: z.number(),
    pmtDue: z.number(),
    prinBal: z.number(),
    residual: z.number(),
    schedule: PaymentInterval,
    securityDeposit: z.number(),
    snBalance: z.number(),
    snDueNow: z.number(),
    snPrinBal: z.number(),
    status: z.string(),
    stockNum: z.string(),
    tOfPBal: z.number(),
    trim: z.string(),
    twilioPhoneNumber: z.string(),
    vehRecId: z.number(),
    vehSize: z.string(),
    vin: z.string(),
    year: z.string(),
  })
  .merge(buyerFields)
  .merge(cobuyerFields)
  .merge(displayFields)
  .merge(repayFields);

/** Replaces `GetPaymentData` - @note `buildDefaultSchema` converts all fields to `nullable` */
export const PaymentDataRes = buildDefaultSchema(basePaymentDataRes);
export type PaymentDataRes = z.infer<typeof PaymentDataRes>;

export const PaymentProviderConfigRes = buildDefaultSchema(paymentProviderConfig)
  .nullable()
  .optional();
// Infer the type with the nullish behavior
export type PaymentProviderConfigRes = z.infer<typeof PaymentProviderConfigRes>;

// TransactionResultVm
/** For Non-credit card and non-ACH payments
 * Matches `TransactionResultVm` on backend
 */
export const SubmitPmtNonCcAchRes = z.object({
  /**  "HasError" */
  success: z.boolean(),
  /**  "CreditCardLogId" */
  creditCardLogId: z.number(),
  /**  "PaymentLogRecId" */
  paymentLogRecId: z.number(),
  /**  "PaymentRecId" */
  paymentRecId: z.number(),
});
export type SubmitPmtNonCcAchRes = z.infer<typeof SubmitPmtNonCcAchRes>;

export const SubmitPmtRepayCcRes = SubmitPmtNonCcAchRes.extend({
  /**  "ErrMessage" */
  message: z.string(),
  /**  "IFrameUrl" */
  iFrameUrl: z.string(),
  /**  "WasDeclined" */
  wasDeclined: z.boolean(),
  /**  "WasDuplicate" */
  wasDuplicate: z.boolean(),
  /**  "WasPaymentLogDuplicate" */
  wasPaymentLogDuplicate: z.boolean(),
  /**  "OpId" - not needed or used on frontend (backend only) */
  opId: z.string().nullish(),
  /**  "BatchId" */
  batchId: z.string(),
  /**  "PayerId" */
  payerId: z.string(),
  /**  "ResponseCode" */
  responseCode: z.number(),
  /**  "ResponseCodeText" */
  responseCodeText: z.string(),
  /**  "ResponseBody" */
  responseBody: z.string(),
});

export type SubmitPmtRepayCcRes = z.infer<typeof SubmitPmtRepayCcRes>;

/** Response body for request to '/Payment/SubmitCliqPayment' */
export const SubmitCliqPaymentRes = SubmitPmtRepayCcRes.extend({
  openEdgeTransactionId: z.string(), // NOT IN REPAY
  wasBackendDuplicate: z.boolean().optional()
});
export type SubmitCliqPaymentRes = z.infer<typeof SubmitCliqPaymentRes>;
