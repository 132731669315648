import { FC, ReactNode } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// components
import Header from "./Header";
import LhphFundingDetail from "./LhphFundingDetail";
import DealsQuotesDetail from "./DealsQuotesDetail";
// utils
import { useSalesParams } from "./utils";
import { useNavBack } from "@/utils/routing/useNavBackEvent";
// interfaces
import { SalesSubview } from "../enums";
import { ICON_GREY } from "@/mui/theme/colors";
import BackLink from "@/mui/components/buttons/BackLink";

/** @deprecated move to separate routes */
const detailComponentMap: Map<SalesSubview, ReactNode> = new Map<SalesSubview, ReactNode>([
  [SalesSubview.enum.lhPhFunding, <LhphFundingDetail />],
  [SalesSubview.enum.dealsQuotes, <DealsQuotesDetail />],
]);

/** ### Layout for sales detail-form views
 * @note in order to leverage the URL parameter for `subview` (provided by `useParams`), the `<Routes>` component cannot be used
 * @deprecated Remove file and implement in parent <Routes>. The intention of this impl. was to standardize header and body layout, but this adds more complexity than necessary.
 */
const SalesDetailLayout: FC = () => {
  const { subview } = useSalesParams();

  if (!subview) {
    console.error(`No 'subview' provided in URL params: '${subview}'`);
    useNavBack()();
    return <></>;
  }

  // Select detail-view component
  const DetailComponent = detailComponentMap.get(subview);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      overflow="hidden"
      // @todo Apply `svg` CSS rule to correct scope
      sx={{ flex: 1, p: "16px 32px", "&* svg": { color: ICON_GREY } }}
    >
      <BackLink />
      {/* @todo header should be managed individually, by each router-component */}
      <Header />

      {/* Body */}
      <Grid
        container
        direction="row"
        flex={1}
        sx={{ flexWrap: "nowrap", overflowX: "hidden", overflowY: "hidden" }}
      >
        {DetailComponent}
      </Grid>
    </Grid>
  );
};

export default SalesDetailLayout;
