import { FC, useEffect, useState } from "react";
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom";
import { HubConnection } from "@microsoft/signalr";
// kendo
import { ListView, Loader } from "@progress/kendo-react-all";
import { DropDownButton } from "@/components/dropDownButton/DropDownButton";
import { Modal } from "@/components/modals/Modal";
import { Spacer } from "@/components/spacer/Spacer";
import { Icons } from "@/components/icons";
// components
import AuthSettingsSelector from "@/features/Settings/AuthSettingsSelector";
import NavigationItem from "@/features/RootView/NavSidebar/NavigationItem";
import SupportWidget from "@/features/RootView/SupportWidget";
// state
import { useAppDispatch } from "@/store/store";
import { authActions, useAuthSelector } from "@/features/auth/authSlice";
import { useAuthCtx } from "@/AppProviders/AuthProvider";
import { useNotificationsCtx } from "@/features/notifications/NotificationsProvider";
// utils
import { isStandalone } from "@/env";
import { alphaLogout } from "@/utils/helpers/alpha";
import { getTimeAgo } from "@/utils/helpers/general";
import { connectSignalR, disconnectSignalR } from "@/features/notifications/utils";
import { initAuthState } from "./request";
// interfaces
import { UnreadSMS } from "@/features/notifications/interfaces";
import { SelectableCompany } from "@/interfaces/System";
import { ActivityType } from "../activityPanel/activityPanelInterfaces";
// style
import styles from "./AuthenticatedContainer.module.scss";
import Logo from "@/assets/dcsLogo.png";

// --- Material-UI DataGrid imports for the company selector:
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
// (Optional) if you have a common table style defined:
import { tableStyle } from "@/features/Settings/default";
import usePermissionFlag from "@/env/usePermissionFlag";

const AuthenticatedContainer: FC = () => {
  const navigate = useNavigate();
  const origPath = useLocation().pathname;
  const dispatch = useAppDispatch();

  // Auth state
  const setUserInfo = useAuthCtx((s) => s.setUserInfo);
  const standaloneLogout = useAuthCtx((s) => s.logout);
  const locId = useAuthSelector((s) => s.locId);
  const compId = useAuthSelector((s) => s.compId);
  const userEmail = useAuthSelector((s) => s.userEmail);
  const selectableCompanies = useAuthSelector((s) => s.selectableCompanies);
  const companySelectorOpen = useAuthSelector((s) => s.companySelectorOpen);

  const loadingBffUser = useAuthCtx((s) => s.loadingBffUser);
  const selectedCompanyName = useAuthCtx((s) => s.selectedCompany?.companyName);

  const isLoggedInStandalone = useAuthCtx((s) => s.isLoggedIn);
  /** @deprecated - remove post-alpha */
  const isLoggedInDeprec = useAuthSelector((s) => s.isLoggedInDeprec);
  const isLoggedIn = isLoggedInStandalone || isLoggedInDeprec;

  // Notifications state
  const unreadSmsOpen = useNotificationsCtx((s) => s.unreadSmsOpen);
  const setUnreadSmsOpen = useNotificationsCtx((s) => s.setUnreadSmsOpen);
  const loadUnreadSms = useNotificationsCtx((s) => s.loadUnreadSms);
  const unreadSms = useNotificationsCtx((s) => s.unreadSms);
  const selectedSms = useNotificationsCtx((s) => s.selectedSms);
  const setSelectedSms = useNotificationsCtx((s) => s.setSelectedSms);
  const { signalRConnection, setSignalRConnection } = useAuthCtx((s) => s);
  const { unreadSmsCount, setUnreadSmsCount } = useAuthCtx((s) => s);
  // Layout state
  const [navExpanded, setNavExpanded] = useState(false);
  const [loadingSms, setLoadingSms] = useState(false);

  // ----- SEARCH STATE -----
  const [searchTerm, setSearchTerm] = useState("");

  const containerClass = `${styles.container} ${navExpanded ? styles.full : styles.minimal}`;
  const headerClass = `${styles.standaloneHeader} ${navExpanded ? styles.full : styles.minimal}`;

  // Event handlers
  const handleNavToggle = (expanded: boolean) => {
    dispatch(authActions.setCompanySelectorOpen(false));
    setUnreadSmsOpen(false);
    setNavExpanded(expanded);
  };

  const handleCompanySelect = (selectedCompany: SelectableCompany) => {
    disconnectSignalR(signalRConnection, setSignalRConnection);
    dispatch(authActions.setCompanySelectorOpen(false));
    localStorage.setItem("selectedCompId", selectedCompany.compId.toString());
    window.location.href = "/";
  };

  const handleSMSClick = (sms: UnreadSMS) => {
    if (sms.collectionRecords.length === 1) {
      navigate(
        `/accounts/${sms.collectionRecords[0]!.colRecId}?activityType=${ActivityType.enum.Message}`,
        { state: { activityType: ActivityType.enum.Message } }
      );
    } else {
      setSelectedSms(sms);
    }
    setUnreadSmsOpen(false);
  };

  const closeSMSAccountModal = () => {
    setSelectedSms(null);
  };

  const handleSmsCounter = async (smsCount: number) => {
    await setUnreadSmsCount(smsCount);
  };

  const handleSmsOpen = async (open: boolean) => {
    await setUnreadSmsOpen(open);
    if (open && unreadCount !== unreadSmsCount) {
      setLoadingSms(true);
      await loadUnreadSms();
      setLoadingSms(false);
    }
  };

  // Effects
  useEffect(() => {
    if (!loadingBffUser && isLoggedInStandalone === false) {
      navigate({ pathname: "/auth/login", search: `from=${origPath}` });
    } else if (!loadingBffUser && isLoggedInStandalone === true) {
      initAuthState(dispatch, isLoggedIn, setUserInfo);
    } else if (!loadingBffUser && !isStandalone && isLoggedInDeprec === true) {
      initAuthState(dispatch, isLoggedIn, setUserInfo);
    }
  }, [isLoggedIn, loadingBffUser]);

  useEffect(() => {
    connectSignalR(signalRConnection, setSignalRConnection, locId, dispatch, handleSmsCounter);
    return () => {
      disconnectSignalR(signalRConnection, setSignalRConnection);
    };
  }, [compId]);

  const unreadCount = unreadSms
    ? unreadSms.reduce((acc, cur) => acc + (cur.unreadCount ?? 0), 0)
    : 0;

  // ----- STEP 1: Filter out duplicate companies -----
  // Define duplicates as having identical companyName, compId, orgId, locId, and state.
  const uniqueCompanies: SelectableCompany[] = [...(selectableCompanies || [])].reduce(
    (acc: SelectableCompany[], company: SelectableCompany) => {
      const key = `${company.companyName}-${company.compId}-${company.orgId}-${company.locId}-${company.state}`;
      if (
        !acc.some(
          (c) =>
            `${c.companyName}-${c.compId}-${c.orgId}-${c.locId}-${c.state}` === key
        )
      ) {
        acc.push(company);
      }
      return acc;
    },
    []
  );

  // ----- STEP 2: Filter based on search term -----
  // Now, search across all columns: companyName, compId, orgId, locId, and state.
  const filteredCompanies = uniqueCompanies.filter((company) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      company.companyName.toLowerCase().includes(searchLower) ||
      company.compId.toString().toLowerCase().includes(searchLower) ||
      company.orgId.toString().toLowerCase().includes(searchLower) ||
      company.locId.toString().toLowerCase().includes(searchLower) ||
      company.state.toLowerCase().includes(searchLower)
    );
  });

  const shouldRender = usePermissionFlag('SuperAdmin');

  const columnsToShow = shouldRender
   ? [
      {
        field: "companyName",
        headerName: "Name",
        width: 200,
      },
      {
        field: "compId",
        headerName: "ID",
        width: 100,
      },
      {
        field: "orgId",
        headerName: "Org",
        width: 100,
      },
      {
        field: "state",
        headerName: "State",
        width: 100,
      },
    ] : [
      {
        field: "companyName",
        headerName: "Name",
        width: 200,
      },
      {
        field: "state",
        headerName: "State",
        width: 100,
      },
    ]

  return (
    <div className={"authContainerWithTopHeader"}>
      {isStandalone && (
        <div className={headerClass}>
          <h1 style={{ fontSize: "20px" }}>{selectedCompanyName}</h1>
          <AuthSettingsSelector />
        </div>
      )}
      <div className={containerClass}>
        <div className={styles.navigationContainer}>
          <div className={styles.arrowButtonContainer}>
            {navExpanded ? (
              <div className={styles.arrowButton} onClick={() => handleNavToggle(false)}>
                <Icons.ArrowLeft className={styles.arrowIcon} />
              </div>
            ) : (
              <div className={styles.arrowButton} onClick={() => handleNavToggle(true)}>
                <Icons.ArrowRight className={styles.arrowIcon} />
              </div>
            )}
          </div>
          <div className={styles.navigationHeader}>
            {isStandalone && uniqueCompanies?.length ? (
              <div>
                <span className={styles.line} />
                <DropDownButton
                  buttonProps={{
                    style: {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: navExpanded ? "flex-start" : "center",
                      flex: 1,
                    },
                  }}
                  anchorStyles={{ display: "flex", flex: 1 }}
                  popUpProps={{
                    popupAlign: { vertical: "center", horizontal: "left" },
                    anchorAlign: { vertical: "center", horizontal: "right" },
                  }}
                  label={
                    <div className={styles.clickableLogo}>
                      <img src={Logo} className={styles.logo} alt="Logo" />
                    </div>
                  }
                  open={companySelectorOpen}
                  setOpen={(open) => dispatch(authActions.setCompanySelectorOpen(open))}
                >
                  <div className={styles.companySelectorContainer}>
                    <div className={styles.companySelectorTitle}>Select Company</div>
                    {/* ----- Search Bar ----- */}
                    <div style={{ padding: "0 8px 8px" }}>
                      <input
                        type="text"
                        placeholder="Search companies..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "6px 8px",
                          fontSize: "14px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                    {/* ----- MUI DataGrid for Company Selector ----- */}
                    <div style={{ height: "100%", maxHeight: "50vh", width: "100%" }}>
                      <DataGrid
                        rows={filteredCompanies.sort((a, b) =>
                          a.companyName.localeCompare(b.companyName)
                        )}
                        getRowId={(row) => row.compId}
                        columns={columnsToShow}
                        columnHeaderHeight={40}
                        rowHeight={35}
                        hideFooter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        onRowClick={(params) => handleCompanySelect(params.row)}
                        sx={{
                          cursor: "pointer",
                          ...tableStyle,
                        }}
                      />
                    </div>
                  </div>
                </DropDownButton>
              </div>
            ) : (
              <img src={Logo} className={styles.logo} alt="Logo" />
            )}

            {navExpanded && (
              <div className={styles.textContainer}>
                <div className={styles.brand}>Dealer Controlled Solutions</div>
                {!!userEmail && <div className={styles.email}>{userEmail}</div>}
              </div>
            )}
          </div>
          <div className={styles.hr} />
          <Spacer size={8} />
          <NavigationItem
            Icon={Icons.Users}
            name="Accounts"
            route="/accounts"
            permissionRequired="Accounts"
          />
          <NavigationItem
            Icon={Icons.Collections}
            name="Collections"
            route="/collections"
            permissionRequired="Collections"
          />
          <NavigationItem
            Icon={Icons.Graph}
            name="Reports"
            route="/reports"
            permissionRequired="Reports"
          />
          <NavigationItem
            Icon={Icons.Sales}
            name="Sales"
            route="/sales"
            permissionRequired="Sales"
          />
          <NavigationItem
            Icon={Icons.Car}
            name="Inventory"
            route="/inventory"
            permissionRequired="Inventory"
          />
          <NavigationItem
            Icon={Icons.Gauge}
            name="Dashboard"
            route="/dashboard"
            permissionRequired="RTD"
          />
          <div className={`${styles.notificationContainer} ${unreadSmsOpen ? styles.active : ""}`}>
            <span className={styles.line} />
            <DropDownButton
              title="Notifications"
              buttonProps={{
                style: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: navExpanded ? "flex-start" : "center",
                  flex: 1,
                },
              }}
              anchorStyles={{ display: "flex", flex: 1 }}
              popUpProps={{
                popupAlign: { vertical: "bottom", horizontal: "left" },
                anchorAlign: { vertical: "center", horizontal: "right" },
              }}
              label={
                <>
                  <div className={styles.notificationsIconContainer}>
                    <Icons.Bell />
                    {!!unreadSmsCount && unreadSmsCount > 0 && (
                      <span
                        className={styles.unreadIndicator}
                        style={{ right: unreadSmsCount > 99 ? "-10px" : "-2px" }}
                      >
                        {unreadSmsCount > 99 ? "99+" : unreadSmsCount}
                      </span>
                    )}
                  </div>
                  <span className={styles.navigationItemTitle}>Notifications</span>
                </>
              }
              open={unreadSmsOpen}
              setOpen={handleSmsOpen}
            >
              <div className={styles.unreadSMSContainer}>
                {loadingSms ? (
                  <div style={{ padding: 10 }}>
                    <Loader size="medium" />
                  </div>
                ) : (
                  <>
                    {!unreadSms.length ? (
                      <div style={{ padding: 10 }}>No new messages</div>
                    ) : (
                      <ListView
                        data={unreadSms}
                        item={({ dataItem }) => (
                          <div className={styles.smsContainer} onClick={() => handleSMSClick(dataItem)}>
                            <div>
                              <Icons.Message />
                            </div>
                            <div>
                              <div className={styles.bold}>{dataItem.name}</div>
                              <div className={styles.bold}>"{dataItem.message}"</div>
                              <div className={styles.italic}>{getTimeAgo(dataItem.dateTime)}</div>
                            </div>
                            <div className={styles.unreadCount}>{dataItem.unreadCount}</div>
                          </div>
                        )}
                      />
                    )}
                  </>
                )}
              </div>
            </DropDownButton>
          </div>
          <Spacer expand />
          <div className={styles.hr} />
          <NavigationItem
            Icon={Icons.Logout}
            name="Logout"
            action={isStandalone ? standaloneLogout : alphaLogout}
          />
        </div>
        <div className={styles.mainContent}>
          {!!selectedSms && (
            <Modal
              isOpen={!!selectedSms}
              onCloseButtonClick={closeSMSAccountModal}
              onDimmerClick={closeSMSAccountModal}
              centerModal
              closeButton
            >
              <h3>Phone #{selectedSms?.phoneNumber} has multiple associated accounts:</h3>
              {selectedSms.collectionRecords.map((rec) => (
                <div style={{ marginBottom: "5px" }} key={rec.colRecId}>
                  <Link
                    to={`/accounts/${rec.colRecId}?activityType=${ActivityType.enum.Message}`}
                    onClick={closeSMSAccountModal}
                  >
                    #{rec.stockNum} - {rec.buyerNames} - {rec.vehicle}
                  </Link>
                </div>
              ))}
            </Modal>
          )}
          {!compId ? (
            <div className={styles.center}>
              <Loader size="large" />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
        <div className={styles.hr} />
        <Spacer size={8} />
        <SupportWidget />
      </div>
    </div>
  );
};

export default AuthenticatedContainer;
