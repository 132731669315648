import { FC, PropsWithChildren } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { GridRow, GridRowProps } from "@mui/x-data-grid";
// state
import { useCollectionQueuesListCtx } from "../CollectionQueuesListProvider";

const boolToYesNo = (b: boolean) => (b ? "Yes" : "No");

const ExpandedCell: FC<{ title: string; value: any }> = ({ title, value }) => (
  <Grid container direction="row" gap={2} width="100%" justifyContent="space-between" wrap="nowrap">
    <Grid container fontSize={14} fontWeight={700}>
      {title}
    </Grid>
    <Grid container fontSize={14} fontWeight={400} color="rgb(115, 115, 115)">
      {value}
    </Grid>
  </Grid>
);
const ExpandedColumn: FC<PropsWithChildren> = ({ children }) => (
  <Grid container direction="column" gap={2}>
    {children}
  </Grid>
);
const ExpandedSection: FC<{ rowId: string | number }> = ({ rowId }) => {
  const expandedRow = useCollectionQueuesListCtx((s) => s.expandedRow);
  const expandedRowIdx = useCollectionQueuesListCtx((s) => s.expandedRowIdx);
  if (rowId !== expandedRowIdx || expandedRow == null) return <></>;

  return (
    <Grid container direction="row" sx={{ px: 6, py: 2, gap: 4, background: "rgba(0,0,0,0.08)" }}>
      <ExpandedColumn>
        <ExpandedCell title="Out for Repo" value={boolToYesNo(expandedRow.includeOutForRepo)} />
        <ExpandedCell title="Field Call" value={boolToYesNo(expandedRow.includeFieldCall)} />
        <ExpandedCell
          title="In Our Possession"
          value={boolToYesNo(expandedRow.includeInOurPossession)}
        />
      </ExpandedColumn>
      <ExpandedColumn>
        <ExpandedCell title="SideNotes" value={boolToYesNo(expandedRow.includeSidenotes)} />
        <ExpandedCell title="Days From" value={expandedRow.daysFrom} />
        <ExpandedCell title="Days To" value={expandedRow.daysTo} />
      </ExpandedColumn>
      <ExpandedColumn>
        <ExpandedCell title="Alpha From" value={expandedRow.alphaFrom} />
        <ExpandedCell title="Alpha To" value={expandedRow.alphaTo} />
      </ExpandedColumn>
    </Grid>
  );
};

const ExpandableRow: FC<GridRowProps> = (props) => (
  <>
    <GridRow {...props} />
    <ExpandedSection rowId={props.rowId} />
  </>
);

export default ExpandableRow;
