import { toast } from "react-toastify";
import dayjs from "dayjs";
import { SystemService } from "@/services/systemService";
import { ApiResponse } from "@/interfaces/Api";
import { GetSpireonEventsResponse, GetSpireonAssetResponse, GenericSpireonResponse } from "@/interfaces/Gps/Spireon";
import { GpsHistoryItemDeprec } from "@/interfaces";
import { DateFormat } from "@/utils/helpers/general";

export function augmentSpireonCommands(cls: typeof SystemService): void {
    cls.prototype.getSpireonAssetLocateEvents = async function (
    inventoryRecId: number,
    startDate: string,
    endDate: string
    ) {
    try {
        const { data } = await this.axios.get<ApiResponse<GetSpireonEventsResponse>>(
        "/System/GetSpireonAssetLocateEvents",
        { params: { inventoryRecId, startDate, endDate } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to get Spireon device location history");
        throw e;
    }
    };

    cls.prototype.getSpireonGpsHistory = async function (inventoryRecId: number) {
    const locateEvents = await this.getSpireonAssetLocateEvents(
        inventoryRecId,
        "2010-01-01",
        dayjs().format("YYYY-MM-DD")
    );

    const standardGpsHistory: GpsHistoryItemDeprec[] = locateEvents.events.map((event: any) => {
        const { location } = event;
        const { address } = location;
        return {
        address: `${address.line1} ${address.city} ${address.stateOrProvince} ${address.postalCode}`,
        odometer: event.odometer,
        lastPing: event.date,
        course: "",
        speed: (event.speed ?? "").toString(),
        latitude: location.lat,
        longitude: location.lng,
        locateType: event.type,
        };
    });

    return standardGpsHistory;
    };

    cls.prototype.getSpireonAsset = async function (inventoryRecId: number) {
    try {
        const { data } = await this.axios.get<ApiResponse<GetSpireonAssetResponse>>(
        "/System/GetSpireonAsset",
        { params: { inventoryRecId } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to get Spireon device data");
        throw e;
    }
    };

    cls.prototype.getSpireonLastLocate = async function (inventoryRecId: number) {
    try {
        const res = await this.getSpireonAsset(inventoryRecId);
        const spireonAsset = res?.asset;

        if (!spireonAsset || !spireonAsset.lastLocation || !spireonAsset.lastLocation.address)
        return undefined;

        const { lastLocation } = spireonAsset;
        const { address } = lastLocation;

        const standardLastLocate: GpsHistoryItemDeprec = {
        address: `${address.line1} ${address.city} ${address.stateOrProvince} ${address.postalCode}`,
        odometer: spireonAsset.odometer,
        lastPing: dayjs(spireonAsset.locationLastReported).format(DateFormat.SimpleDateTime),
        course: "",
        speed: (spireonAsset.speed ?? "").toString(),
        latitude: lastLocation.lat,
        longitude: lastLocation.lng,
        };

        return standardLastLocate;
    } catch (e) {
        console.error(e);
        throw e;
    }
    };

    cls.prototype.updateSpireonMap = async function (inventoryRecId: number) {
    try {
        const res = await this.axios.post<ApiResponse<GenericSpireonResponse>>(
        "/System/SendSpireonLocateCommand",
        {},
        { params: { inventoryRecId } }
        );
        if (!res.data.data?.success) {
        console.error(res.data.data);
        throw new Error("Spireon error");
        }
    } catch (e) {
        toast.error("Spireon error");
        console.error(e);
        throw e;
    }
    };

    cls.prototype.sendSpireonEnableDisableCommand = async function sendSpireonEnableDisableCommand(inventoryRecId: number, enableVehicle: boolean) {
        try {
            const { data } = await this.axios.post<ApiResponse<GenericSpireonResponse>>(
            '/System/SendSpireonEnableDisableCommand',
            {},
            {
                params: { inventoryRecId, enableVehicle },
            }
            );
            if (!data.data?.success) throw new Error();
        } catch (e) {
            console.error(e);
            toast.error(`Unable to ${enableVehicle ? 'enable' : 'disable'} vehicle`);
            throw e;
        }
    };

    cls.prototype.installSpireonSerialNumber = async function installSpireonSerialNumber(
        inventoryRecId: number,
        serialNumber: string,
        currentOdometer: number
    ) {
        try {
        const { data } = await this.axios.post<ApiResponse<GenericSpireonResponse>>(
            '/System/InstallSpireonSerialNumber',
            {},
            {
            params: { inventoryRecId, serialNumber, currentOdometer },
            }
        );
        if (!data.data?.success) throw new Error(data.data?.message);
        } catch (e: any) {
        console.error(e);
        toast.error('Unable to install Spireon serial number');
        if (e.message) {
            toast.error(e.message, { autoClose: false });
        }
        throw e;
        }
    };

    cls.prototype.uninstallSpireon = async function uninstallSpireon(inventoryRecId: number) {
        try {
            const { data } = await this.axios.post<ApiResponse<GenericSpireonResponse>>(
            '/System/UninstallSpireon',
            {},
            {
                params: { inventoryRecId },
            }
            );
            if (!data.data?.success) throw new Error();
        } catch (e) {
            console.error(e);
            toast.error('Unable to uninstall Spireon device');
            throw e;
        }
    };

    cls.prototype.updateSpireonOdometer = async function updateSpireonOdometer(inventoryRecId: number, currentOdometer: number) {
        try {
            const { data } = await this.axios.post<ApiResponse<GenericSpireonResponse>>(
            '/System/UpdateSpireonOdometer',
            {},
            {
                params: { inventoryRecId, currentOdometer },
            }
            );
            if (!data.data?.success) throw new Error();
        } catch (e) {
            console.error(e);
            toast.error('Unable to update Spireon odometer');
            throw e;
        }
    };
}