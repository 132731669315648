import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { Messages } from '@/services/customerService';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { Icons } from '@/components/icons';

import styles from '../activityPanel.module.scss';
import { formatPhone } from '@/utils/helpers/formatting';


/** ###  */
const SmsActivityCard: FC<{activity:Messages}> = ({activity }) => {
  let messageSummaryClassName = styles.type;
  let messageSummary = `Message sent to ${activity.sentTo} from ${activity.sentBy}`;
  let messageIconContainerClass = styles.messageIconContainer;
  if (activity.isDirectConsentError) {
    messageSummaryClassName = styles.smsDirectConsentFailure;
    messageSummary = 'Text Message Failed to Send';
    messageIconContainerClass = styles.messageIconContainerDirectConsentFailure;
  } else if (activity.status === 'failed' || activity.status === 'undelivered') {
    messageSummaryClassName = styles.smsDirectConsentFailure;
    messageSummary = `Message failed with error: ${
      activity.errorMessage === null ? activity.errorCode : activity.errorMessage
    }`;
    messageIconContainerClass = styles.messageIconContainerDirectConsentFailure;
  } else if (activity.received || !activity.sentTo) {
    messageSummary = `Message received from ${activity.sentBy}`;
    messageIconContainerClass = styles.messageIconContainerInboundSms;
  } else {
    messageSummary;
    messageIconContainerClass;
  }

  return (
    <Grid container direction="column">
 <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={messageIconContainerClass}>
            <Icons.Message />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.messageHeader}>
                <div className={messageSummaryClassName}>{messageSummary}</div>
              </div>
              {activity.isDirectConsentError ? (
                <div>
                  Failed to send, Customer opted out of text communication with phone number:{' '}
                  {formatPhone(activity.sentToNumber ?? '')}
                  <br />
                  <div>"{activity.body}"</div>
                </div>
              ) : (
                <div>{activity.body}</div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(activity.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    </Grid>
  );
};

export default SmsActivityCard;
