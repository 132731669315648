import { FC } from "react";
// kendo
import Hotlist from "@/components/hotlist/Hotlist";
// state
import { useAccountSelector } from "@/features/Accounts/accountSlice";
// style
import styles from "./LeftContainer.module.scss";

/** ###  */
const AcctStatusCard: FC = () => {
  // @todo handle nulls properly
  const appRecId = useAccountSelector((s) => s.accountInformation?.appRecId);

  if(!appRecId) return <></>;
  
  return (
    <div className={styles.accountStatusCard} style={{ gap: "8px" }}>
      <div className={styles.title}>Account Status</div>
      <Hotlist recId={appRecId!} transType="Account" initialStatusFilter="Active" />
    </div>
  );
};

export default AcctStatusCard;
