import React, { FC, createRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash-es';
import { toast } from 'react-toastify';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  Editor,
  EditorUtils,
  Grid as KendoGrid, GridColumn,
  GridHeaderSelectionChangeEvent,
  GridNoRecords,
  GridSelectionChangeEvent,
  GridSortChangeEvent, Loader,
  Tooltip
} from '@progress/kendo-react-all';
import {
  CollectionEmail,
  DynamicData,
  EmailTemplate,
  TextTemplate,
  collectionsService,
  getCollectionsFromResponse
} from '@/services/collectionsService';
import { Button, Checkbox, DropDownButton, TextInput } from "@/components";
import { Modal } from "@/components/modals/Modal";
import { BackLink } from "@/components/backLink/BackLink";
import { Spacer } from "@/components/spacer/Spacer";
import { Icons } from '@/components/icons';
import { HTMLtoSms, SmsEditor } from '../components/smsEditor/SmsEditor';
import { useAppDispatch } from '@/store/store';
import { collectionsActions } from '../collectionsSlice';
import CollectionsExport from '../components/collectionsExport/CollectionsExport';
import EmailEditor from '../../../components/emailEditor/EmailEditor';
import { useAuthSelector } from '@/features/auth/authSlice';
import { useCollectionQueuesCtx } from '../CollectionQueuesProvider';
// components
import DynamicDataToolTip from './DynamicDataToolTip';
import TextTemplateToolTip from './TextTemplateToolTip';
// utils
import { cellTypeDict, tableColumns } from './tableColumns';
// interfaces
import { CollectionRow, GridColumnProps } from './interfaces';
// style
import styles from './QueueRunList.module.scss';

const initialSort: SortDescriptor[] = [{ field: 'lastPaymentDate', dir: 'asc' }];
const STORAGE_KEY = 'collectionColumns';
const COLUMN_VERSION = 1; // Increment when modifying tableColumns
const DATA_ITEM_KEY = 'recId';
  const SELECTED_FIELD = 'selected';

const dropDownButtonIconStyle = (flip: boolean) => ({
  transform: flip ? 'scaleY(-1)' : 'scaleY(1)',
  transition: 'transform 150ms ease',
});

/** @deprecated needs refactor */
export const QueueRunList: FC = () => {
  const params = useParams();
  const queueRecId = Number(params.queueRecId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const compId = useAuthSelector((s) => s.compId);
  const setActiveQueue = useCollectionQueuesCtx((s) => s.setActiveQueue);

  const [collections, setCollections] = useState<CollectionRow[]>([]);
  const [queueName, setQueueName] = useState('');
  const [allSelect, setAllSelect] = useState(false);
  const [TTPTemplate, setTTPTemplate] = useState('');
  const [sendingTTP, setSendingTTP] = useState(false);
  const [sendingSMS, setSendingSMS] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [gridSeed, setGridSeed] = useState(Math.random());
  const localColumns = JSON.parse(localStorage.getItem(STORAGE_KEY)!);

  const [columns, setColumnState] = useState<GridColumnProps[]>(
    localColumns?.version === COLUMN_VERSION ? localColumns.columns : cloneDeep(tableColumns)
  );
  const [loading, setLoading] = useState(false);

  const saveColumns = (newColumns: GridColumnProps[]) => {
    setColumnState(newColumns);
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        version: COLUMN_VERSION,
        columns: newColumns,
      })
    );
  };

  const [dynamicData, setDynamicData] = useState<DynamicData[] | null>(null);
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const [showTTP, setShowTTP] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showSMS, setShowSMS] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showVariables, setShowVariables] = useState(false);

  const [sort, setSort] = React.useState(initialSort);

  const [smsMessage, setSMSMessage] = useState('');
  const smsEditor = createRef<Editor>();

  const [emailSubject, setEmailSubject] = useState('');
  const [textTemplate, setTextTemplate] = useState<TextTemplate[]>([]);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate[]>([]);

  const [selectedCollections, setSelectedCollections] = useState(false);

  const [notSentList, setNotSentList] = useState<CollectionRow[]>([]);
  const [notSentReason, setNotSentReason] = useState('');
  const [showNotSentModal, setShowNotSentModal] = useState(false);

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    const rowRecId = event.dataItem.recId;
    const tempCollections = [...collections!];

    tempCollections.find((c) => c.recId === rowRecId)!.selected = !event.dataItem.selected;

    setCollections(tempCollections);

    const selectionCount = tempCollections.filter((c) => c.selected);

    if (selectionCount.length > 0) {
      setSelectedCollections(true);
    } else {
      setSelectedCollections(false);
    }
  };

  const onHeaderSelectionChange = (event: GridHeaderSelectionChangeEvent) => {
    const checked = event.nativeEvent.target.checked;
    setAllSelect(checked);

    const tempCollections = [...collections];

    tempCollections.forEach((c) => {
      c.selected = checked;
    });

    const selectionCount = tempCollections.filter((c) => c.selected);

    if (selectionCount.length > 0) {
      setSelectedCollections(true);
    } else {
      setSelectedCollections(false);
    }

    setCollections(tempCollections);
  };

  const initData = async () => {
    setLoading(true);
    try {
      const getDynamicData = await collectionsService.getDynamicData();

      setDynamicData(getDynamicData);

      const getTextTemplates = await collectionsService.getTextTemplates(compId!);

      setTextTemplate(getTextTemplates.filter((t) => t.category === 'Collections'));

      const getEmailTemplates = await collectionsService.getEmailTemplates(compId!);

      setEmailTemplate(getEmailTemplates.filter((t) => t.category === 'Collections'));

      const data = await collectionsService.getCollectionsByQueue(queueRecId);
      setTTPTemplate(data.queue.autoTTPTemplate);
      const tempCollections = data.queueAccounts.map((c) => getCollectionsFromResponse(c));
      const out = tempCollections.map((c) => ({ ...c, selected: false }));
      setCollections(out);
      setSelectedCollections(false);
      setQueueName(data.queue.queueName);
      setActiveQueue(data.queue);

      const sortField = columns.find((c) => c.field === data.queue.sortColumn!);

      const sortDirection = data.queue.sortDirection === 'Ascending' ? 'asc' : 'desc';

      if (sortField && sortDirection) {
        setSort([{ field: sortField.field, dir: sortDirection }]);

        if (sortField.display === false) {
          const tempColumns = [...columns];
          const index = tempColumns.findIndex((c) => c.field === sortField.field);
          tempColumns[index]!.display = true;

          saveColumns(tempColumns);
        }
      }
    } catch (error) {
      console.error('Unable to load collections accounts', error);
      toast.error('Unable to load collections accounts');
    } finally {
      setLoading(false);
    }
  };

  const onColumnResize = (event: any) => {
    if (event.end !== true) {
      return;
    }
    const colIndex = event.index;
    const columnField = event.columns[colIndex].field;
    const newWidth = event.newWidth;

    const tempColumns = [...columns];
    const index = tempColumns.findIndex((c) => c.field === columnField);

    tempColumns[index]!.width = newWidth;

    saveColumns(tempColumns);
  };

  const onColumnReorder = (event: any) => {
    const tempColumns = cloneDeep(columns);
    event.columns.forEach((column: GridColumnProps) => {
      const index = tempColumns.findIndex((c) => c.field === column.field);
      tempColumns[index]!.order = column.orderIndex!;
    });

    saveColumns(tempColumns);
    setGridSeed(Math.random());
  };

  const resetGrid = () => {
    setColumnState(cloneDeep(tableColumns));
    localStorage.removeItem(STORAGE_KEY);
    setGridSeed(Math.random());
    initData();
  };

  const sendTTP = () => {
    const collectionRecIds = collections.filter((c) => c.selected && !c.noText).map((c) => c.recId);
    const collectionsNoText = collections.filter((c) => c.selected && c.noText);

    if (collectionRecIds.length === 0 && collectionsNoText.length) {
      toast.error('All selected customers have no text preference on');
      return;
    } else if (collectionRecIds.length === 0) return;
    setSendingTTP(true);

    collectionsService
      .sendBulkTTP(
        queueRecId,
        collections.filter((c) => c.selected).map((c) => c.recId)
      )
      .then(
        () => {
          if (collectionsNoText.length) {
            setNotSentList(collectionsNoText);
            setShowNotSentModal(true);
            setNotSentReason('Customer has no text preference on');
          }
          toast.success('Text to Pay messages sent');
        },
        () => {
          toast.error('Failed to send some Text to Pay messages');
        }
      )
      .finally(() => {
        setSendingTTP(false);
        setShowTTP(false);
        initData();
      });
  };

  const sendSMS = () => {
    const collectionRecIds = collections.filter((c) => c.selected && !c.noText).map((c) => c.recId);
    const collectionsNoText = collections.filter((c) => c.selected && c.noText);

    if (collectionRecIds.length === 0 && collectionsNoText.length) {
      toast.error('All selected customers have no text preference on');
      return;
    } else if (collectionRecIds.length === 0) return;
    setSendingSMS(true);
    collectionsService
      .sendBulkSMS(collectionRecIds, smsMessage)
      .then(
        () => {
          if (collectionsNoText.length) {
            setNotSentList(collectionsNoText);
            setShowNotSentModal(true);
            setNotSentReason('Customer has no text preference on');
          }
          toast.success('SMS messages sent');
        },
        () => {
          toast.error('Failed to send some SMS messages');
        }
      )
      .finally(() => {
        setSendingSMS(false);
        setShowSMS(false);
        initData();
      });
  };

  const sendEmail = (emailSubject: string, emailMessage: string) => {
    const collectionRecIds = collections
      .filter((c) => c.selected && !c.noEmail)
      .map((c) => c.recId);
    const collectionsNoEmail = collections.filter((c) => c.selected && c.noEmail);

    if (collectionRecIds.length === 0 && collectionsNoEmail.length) {
      toast.error('All selected customers have no email preference on');
      return;
    } else if (collectionRecIds.length === 0) return;
    setSendingEmail(true);
    collectionsService
      .sendBulkEmail(collectionRecIds, emailSubject, emailMessage)
      .then(
        (e) => {
          let message = `Emails messages sent successfully\n`;

          if (e.data) {
            if (e.data?.collectionEmails?.length > 0) {
              message += `\nThe following emails cannot receive emails:`;

              e.data.collectionEmails.forEach((col: CollectionEmail) => {
                message += `\n${col.value}`;
              });
            }
          }

          if (collectionsNoEmail.length) {
            setNotSentList(collectionsNoEmail);
            setShowNotSentModal(true);
            setNotSentReason('Customer has no email preference on');
          }

          toast.success(message, { className: styles.toast });
        },
        () => {
          toast.error('Failed to send Email messages');
        }
      )
      .finally(() => {
        setSendingEmail(false);
        setShowEmail(false);
        initData();
      });
  };

  const closeEmail = () => {
    setShowEmail(false);
    setEmailSubject('');
  };

  const handleUserSettingsRowClick = (column: GridColumnProps) => {
    const tempColumns = cloneDeep(columns);
    const tempColumn = tempColumns.find((c) => c.field === column.field);
    if (!column) return;

    tempColumn!.display = !tempColumn!.display;
    saveColumns(tempColumns);
  };

  /** @deprecated move out of component */
  const NotSentModal: FC = () => {
    return (
      <Modal
        isOpen={showNotSentModal}
        onCloseButtonClick={() => setShowNotSentModal(false)}
        centerModal
        closeButton
        title="Message not sent"
      >
        <div className={styles.notSentModal}>
          <div className={styles.heading}>These customers did not receive the message</div>
          <ul className={styles.customerList}>
            {notSentList.map((customer) => (
              <li>
                {customer.stockNum} - {customer.buyers}
              </li>
            ))}
          </ul>
          <div className={styles.heading}>Reason</div>
          <Spacer size={4} />
          <div className={styles.notSentReason}>{notSentReason}</div>
          <div className={styles.notSentButton}>
            <Spacer size={24} />
            <Button label="Ok" fillMode="outline" onClick={() => setShowNotSentModal(false)} />
          </div>
        </div>
      </Modal>
    );
  };

  const handleSmsDynamicDataClick = (dd: DynamicData) => {
    let html = EditorUtils.getHtml(smsEditor.current!.view!.state);
    html = html.slice(0, -4);
    html = html + dd.code + '</p>';

    setSMSMessage(HTMLtoSms(html));
    EditorUtils.setHtml(smsEditor.current!.view!, html);
  };

  const handleSmsTemplateClick = (t: TextTemplate) => {
    EditorUtils.setHtml(smsEditor.current!.view!, `<p>${t.body}</p>`);
    setSMSMessage(t.body);
  };

  const runAccounts = () => {
    const accounts = collections.filter((col) => col.selected);

    dispatch(collectionsActions.setQueueAccounts(accounts));
    navigate(`run/${accounts[0]!.recId}`, { state: { accounts } });
  };

  const getColumnCell = (column: GridColumnProps) => cellTypeDict.find((c) => c.cellType === column.cellType)?.cell;

  useEffect(() => {
    initData();
  }, []);

  return (
    <Grid container direction="column" gap={2} flex={1} overflow="hidden" wrap="nowrap">
      <BackLink
        to="/collections"
        title="Back to list"
        // containerStyle={{ marginTop: 10 }}
      />

      <div className={styles.buttonRow}>
        <Button label="Accounts" onClick={runAccounts} disabled={!selectedCollections} />

        {/* SEND EMAIL DROP DOWN BUTTON  */}
        <DropDownButton
          disabled={!selectedCollections}
          label={'Send Emails'}
          open={showEmail}
          setOpen={setShowEmail}
          outsideClickClassExceptions={['dontClose', 'k-list-item']}
        >
          <div className={styles.emailDropDown}>
            <div className={styles.header}>Compose Collection Email</div>
            <div className={styles.subject}>
              <div>Subject</div>
              <TextInput
                placeholder="Subject"
                onBlur={(e) => setEmailSubject(e.target.value?.toString() || '')}
              />
            </div>

            <EmailEditor
              emailSubject={emailSubject}
              dynamicData={dynamicData || []}
              emailTemplate={emailTemplate}
              sendEmail={sendEmail}
              closeEmail={closeEmail}
              sending={sendingEmail}
            />
          </div>
        </DropDownButton>

        {/* SEND SMS DROP DOWN BUTTON  */}
        <DropDownButton
          disabled={!selectedCollections}
          label={'Send Text'}
          open={showSMS}
          setOpen={setShowSMS}
          onClose={() => {
            setShowVariables(false);
            setShowTemplate(false);
          }}
          outsideClickClassExceptions={['dontClose', 'k-list-item']}
        >
          <div className={styles.smsDropDown}>
            <div className={styles.header}>Compose Collection Text Messages</div>
            <div className={styles.textAreaContainer}>
              <div className={styles.textAreaHeader}>
                <DropDownButton
                  label={
                    <div className={styles.DDBTitle}>
                      Dynamic Data
                      <div style={dropDownButtonIconStyle(showVariables)}>
                        <Icons.DownArrow />
                      </div>
                    </div>
                  }
                  open={showVariables}
                  setOpen={setShowVariables}
                  outsideClickClassExceptions={['dontClose']}
                >
                  <div className={styles.templateDropDown}>
                    {dynamicData?.map((d) => {
                      return (
                        <div
                          className="dontClose"
                          onClick={() => handleSmsDynamicDataClick(d)}
                          key={d.code}
                        >
                          <Tooltip
                            content={() => <DynamicDataToolTip data={d} />}
                            position="left"
                            anchorElement="target"
                          >
                            <div className={'dontClose'} title={d.code}>
                              {d.code}
                            </div>
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                </DropDownButton>

                <DropDownButton
                  label={
                    <div className={styles.DDBTitle}>
                      Templates
                      <div style={dropDownButtonIconStyle(showTemplate)}>
                        <Icons.DownArrow />
                      </div>
                    </div>
                  }
                  open={showTemplate}
                  setOpen={setShowTemplate}
                  outsideClickClassExceptions={['dontClose']}
                >
                  <div className={styles.templateDropDown}>
                    {textTemplate.map((t) => {
                      return (
                        <div
                          className="dontClose"
                          onClick={() => handleSmsTemplateClick(t)}
                          key={t.recId}
                        >
                          <Tooltip
                            content={() => <TextTemplateToolTip data={t} />}
                            position="left"
                            anchorElement="target"
                            className="dontClose"
                          >
                            <div className="dontClose" title={t.name}>
                              {t.name}
                            </div>
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                </DropDownButton>
              </div>
              <div className={styles.textAreaInput}>
                <SmsEditor setValue={setSMSMessage} dynamicData={dynamicData!} editor={smsEditor} />
              </div>
            </div>
            <div className={styles.buttonRow}>
              <Button label={'Cancel'} onClick={() => setShowSMS(false)} disabled={sendingSMS} />
              <Button label={'Send'} onClick={sendSMS} loading={sendingSMS} />
            </div>
          </div>
        </DropDownButton>

        {/* SEND TTP DROP DOWN BUTTON  */}
        <DropDownButton
          disabled={!selectedCollections}
          label={'Text To Pay'}
          open={showTTP}
          setOpen={setShowTTP}
        >
          {TTPTemplate ? (
            <div className={styles.ttpDropDown}>
              <div className={styles.header}>Are you sure you want to send Text to Pay</div>
              <div className={styles.body}>This action cannot be undone</div>
              <div className={styles.buttonRow}>
                <Button label={'Cancel'} onClick={() => setShowTTP(false)} disabled={sendingTTP} />
                <Button
                  label={'Yes, Send Text to Pay'}
                  loading={sendingTTP}
                  className={styles.buttonOne}
                  onClick={sendTTP}
                />
              </div>
            </div>
          ) : (
            <div className={styles.ttpNoMessageDropdown}>
              Queue does not have a message template defined. Please set a message template in the
              queue settings
            </div>
          )}
        </DropDownButton>

        <div className={styles.columnSettings}>
          <DropDownButton
            label="User Settings"
            open={userSettingsOpen}
            setOpen={setUserSettingsOpen}
          >
            <div className={styles.userSettingsContent}>
              {columns.map((c) => {
                if (c.field === 'selected') return null;
                return (
                  <div
                    className={styles.userSettingsCheckList}
                    onClick={() => handleUserSettingsRowClick(c)}
                    key={c.field}
                  >
                    <Checkbox checked={c.display} /> <div>{c.title}</div>
                  </div>
                );
              })}
            </div>
          </DropDownButton>
        </div>
      </div>
      <KendoGrid
        key={gridSeed}
        data={orderBy(collections, sort)}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        // scrollable='scrollable'
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'multiple',
        }}
        sortable={true}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
        }}
        onSelectionChange={(e) => onSelectionChange(e)}
        onHeaderSelectionChange={(e) => onHeaderSelectionChange(e)}
        resizable={true}
        onColumnResize={onColumnResize}
        reorderable={true}
        onColumnReorder={onColumnReorder}
      >
        <GridNoRecords>
          <div style={{ textAlign: 'left' }}>
            {loading ? <Loader size="large" /> : 'No accounts found for this queue'}
          </div>
        </GridNoRecords>
        {columns
          .filter((x) => x.display)
          .sort((a, b) => a.order - b.order)
          .map((column) => {
            return (
              <GridColumn
                field={column.field}
                title={column.title}
                sortable={true}
                key={column.field}
                width={column.width}
                className={column.className}
                cells={getColumnCell(column)}
                headerSelectionValue={!column.title ? allSelect : undefined}
              />
            );
          })}
      </KendoGrid>
      <div className={styles.buttonRow} style={{ width: '10em' }}>
        <Button label="Reset Grid" onClick={() => resetGrid()} />
        <CollectionsExport
          collections={collections}
          columns={columns}
          title={queueName + ' queue'}
        />
      </div>
      <NotSentModal />
    </Grid>
  );
};



/** @deprecated why is this being done? */
export const collectionColumnsDictionary: { field: string; title: string }[] = tableColumns
  .filter((c, i) => i !== 0)
  .map((c) => {
    return { field: c.field, title: c.title! };
  });
/** @deprecated why is this being done? */
export const colTitleToField = (title: string) => {
  const column = collectionColumnsDictionary.find((c) => c.title === title);
  return column?.field;
};
/** @deprecated why is this being done? */
export const colFieldToTitle = (field: string) => {
  const column = collectionColumnsDictionary.find((c) => c.field === field);
  return column?.title;
};


