import React from 'react';
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { AccountDb } from '../interfaces';
import { routeBase } from '../..';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const insuranceTableColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('buyers', 'Buyers', 150),
  newColumnDef('vehicle', 'Vehicle', 220),
  newColumnDef('dateSold', 'Date Sold', 120, 'date'),
  newColumnDef('insuranceCompany', 'Ins Company', 180),
  newColumnDef('insuranceExpires', 'Expires', 130, 'date'),
  newColumnDef('insurancePolicy', 'Policy #', 150),
  newColumnDef('insuranceCancelled', 'Cancelled', 150, 'date'),
  newColumnDef('saleType', 'Sale Type', 120),
  newColumnDef('code3', 'Lot', 'auto'),
];
