import dayjs from "dayjs";
import {
  FinanceTermData,
  TermsLockType,
} from "@/features/Sales/components/salesTabs/terms/financeTerm/interfaces";
import { SpecialPaymentPlan } from "@/features/Sales/components/salesTabs/terms/financeTerm/interfaces";
import { QuoteData, SaleData } from "@/interfaces";
import { addDays, addMonths, compareDate, numDays } from "./sales";

/**
 * Renamed in Step 1 (was "SetTerm")
 */
export function calculateTerm(edate: string | Date, bdate: string | Date) {
  const T_FDD = new Date(edate);
  const T_SDD = new Date(bdate);
  const fmn = T_FDD.getMonth() + T_FDD.getFullYear() * 12;
  const tmn = T_SDD.getMonth() + T_SDD.getFullYear() * 12;
  const fdd = T_FDD.getDate();
  const tdd = T_SDD.getDate();
  let partmonth;
  if (fdd < tdd) {
    partmonth = -(tdd - fdd) / 30.416666;
  } else if (fdd > tdd) {
    partmonth = (fdd - tdd) / 30.416666;
  } else {
    partmonth = 0;
  }
  const n = fmn - tmn + partmonth;
  return n;
}

/**
 * Renamed in Step 1 (was "Presval")
 */
export function calculatePresentValueOfPayment(amt: number, PerRate: number, spnum: number) {
  let rval = 0;
  const efac = Math.pow(1 + PerRate, spnum * -1);
  rval = (amt - amt * efac) / PerRate;
  return rval;
}

/**
 * Renamed in Step 1 (was "PVSpecialPmt")
 */
export function calculatePVSpecialPayment(
  num: number,
  amt: number,
  spsch: string,
  saledate: string | Date,
  spdd: string | Date,
  intrate: number,
  sched: string
) {
  let rval = 0;
  if (num === 1) {
    // set default schedule for finals / balloons
    spsch = sched;
  }
  const bdate = saledate;
  const edate = spdd;

  const tdays = getTermDays(spsch);
  const DaysinYear = getDaysInYear(spsch);
  const perrate = intrate / (DaysinYear / tdays);

  const odds = getOddDays(spsch, bdate, edate);
  const units = getOddUnits(spsch, bdate, edate);

  let FPrin;
  let uprin;
  if (intrate === 0) {
    rval = num * amt;
  } else {
    if (odds < 0) {
      FPrin = calculatePresentValueOfPayment(amt, perrate, num - 1) + amt;
      uprin = FPrin / Math.pow(1 + perrate, units);
      rval = uprin / (1 + (odds * intrate) / DaysinYear);
    } else {
      FPrin = calculatePresentValueOfPayment(amt, perrate, num);
      uprin = FPrin / Math.pow(1 + perrate, units);
      rval = uprin / (1 + (odds * intrate) / DaysinYear);
    }
  }
  rval = Math.round(rval * 100) / 100;
  return rval;
}

/**
 * Renamed in Step 1 (was "GetTermDays")
 */
export const getTermDays = (schedule: string) => {
  let rval = 30;
  switch (schedule) {
    case "Weekly":
      rval = 7;
      break;
    case "Bi-Weekly":
      rval = 14;
      break;
    case "Semi-Monthly":
      rval = 15;
      break;
    default:
      rval = 30;
  }
  return rval;
};

/** (unchanged name; was already in camelCase) */
export const getDaysInYear = (schedule: string) => {
  let rval = 360;
  switch (schedule) {
    case "Weekly":
    case "Bi-Weekly":
      rval = 364;
      break;
    case "Semi-Monthly":
      rval = 360;
      break;
    default:
      rval = 360;
  }
  return rval;
};

/**
 * Renamed in Step 1 (was "GetOddDays")
 */
export function getOddDays(schedule: string, beginningDate: string | Date, endDate: string | Date) {
  let rval = 0;
  let units = 0;
  let daze;
  let estunits;
  let newDate;
  switch (schedule) {
    case "Weekly":
      daze = numDays(beginningDate, endDate) - 7;
      units = Math.floor(daze / 7);
      rval = daze - units * 7;
      break;
    case "Bi-Weekly":
      daze = numDays(beginningDate, endDate) - 14;
      units = Math.floor(daze / 14);
      rval = daze - units * 14;
      break;
    case "Semi-Monthly":
      estunits = Math.floor(numDays(beginningDate, endDate) / 15);
      newDate = addSemiMonthlyDays(endDate, -estunits);
      while (compareDate(newDate, ">", beginningDate)) {
        estunits = estunits + 1;
        newDate = addSemiMonthlyDays(endDate, -estunits);
      }
      estunits = estunits - 1;
      newDate = addSemiMonthlyDays(endDate, -estunits);
      daze = numDays(beginningDate, newDate);
      if (daze > 15) {
        estunits = estunits + 1;
        daze = 0;
      }
      newDate = addSemiMonthlyDays(endDate, -estunits);
      daze = numDays(beginningDate, newDate);
      rval = daze;
      break;
    default:
      estunits = Math.floor(numDays(endDate, beginningDate) / 30);
      newDate = addMonths(endDate, -estunits);
      while (compareDate(newDate, ">", beginningDate)) {
        estunits = estunits + 1;
        newDate = addMonths(endDate, -estunits);
      }
      estunits = estunits - 1;
      newDate = addMonths(endDate, -estunits);
      daze = numDays(beginningDate, newDate);
      if (daze > 29) {
        estunits = estunits + 1;
        daze = 0;
      }
      units = estunits - 1;
      rval = daze;
  }
  return rval;
}

/**
 * Renamed in Step 1 (was "GetOddUnits")
 */
export function getOddUnits(schedule: string, bdate: string | Date, edate: string | Date) {
  let rval = 0;
  let daze;
  let newdate;
  let estunits;
  switch (schedule) {
    case "Weekly":
      daze = numDays(bdate, edate) - 7;
      rval = Math.floor(daze / 7);
      break;
    case "Bi-Weekly":
      daze = numDays(bdate, edate) - 14;
      rval = Math.floor(daze / 14);
      break;
    case "Semi-Monthly":
      estunits = Math.floor(numDays(edate, bdate) / 15);
      newdate = addSemiMonthlyDays(edate, -estunits);
      while (compareDate(newdate, ">", bdate)) {
        estunits = estunits + 1;
        newdate = addSemiMonthlyDays(edate, -estunits);
      }
      estunits = estunits - 1;
      newdate = addSemiMonthlyDays(edate, -estunits);
      daze = numDays(bdate, newdate);
      if (daze > 15) {
        estunits = estunits + 1;
        daze = 0;
      }
      newdate = addSemiMonthlyDays(edate, -estunits);
      daze = numDays(bdate, newdate);
      rval = estunits - 1;
      break;
    default:
      estunits = Math.floor(numDays(edate, bdate) / 30);
      newdate = addMonths(edate, -estunits);
      while (compareDate(newdate, ">", bdate)) {
        estunits = estunits + 1;
        newdate = addMonths(edate, -estunits);
      }
      estunits = estunits - 1;
      newdate = addMonths(edate, -estunits);
      daze = numDays(bdate, newdate);
      if (daze > 29) {
        estunits += 1;
        daze = 0;
      }
      rval = estunits - 1;
  }

  return rval;
}

/**
 * Renamed in Step 1 (was "AFSPayment")
 */
export function calculateAmortizedPayment(LoanAmt: number, IntR: number, NumP: number) {
  let rval = 0;
  if (IntR === 0) {
    rval = LoanAmt / NumP;
  } else {
    rval = (IntR * LoanAmt) / (1 - Math.pow(1 + IntR, NumP * -1));
  }
  return rval;
}

/**
 * Renamed in Step 1 (was "PmtTerm")
 */
export function calculateTermFromPayment(Prin: number, Intrate: number, Pmt: number) {
  let rval = 0;
  if (Intrate === 0) {
    rval = Prin / Pmt;
  } else {
    rval = (Math.log(1 - (Intrate * Prin) / Pmt) / Math.log(1 + Intrate)) * -1;
  }
  return rval;
}

/**
 * Renamed in Step 1 (was "get_finaldate")
 */
export function calculateFinalDate(BegDate: string | Date, sched: string, nnpp: number) {
  let EndDate;
  let eo;
  const thedate = new Date(BegDate);
  let ddys;
  switch (sched) {
    case "Weekly":
      EndDate = addDays(BegDate, nnpp * 7);
      break;
    case "Bi-Weekly":
      EndDate = addDays(BegDate, nnpp * 14);
      break;
    case "Semi-Monthly":
      eo = nnpp - 2 * Math.floor(nnpp / 2);
      if (eo > 0) {
        if (thedate.getDate() < 16) {
          ddys = 15;
        } else {
          ddys = -15;
        }
      } else {
        ddys = 0;
      }
      if (thedate.getDate() < 16) {
        EndDate = addDays(addMonths(BegDate, Math.floor(nnpp / 2)), ddys);
      } else {
        EndDate = addDays(addMonths(BegDate, Math.floor((nnpp + 1) / 2)), ddys);
      }

      break;
    default:
      // Monthly
      EndDate = addMonths(BegDate, nnpp);
  }
  return EndDate;
}

/**
 * Renamed in Step 1 (was "addSMDays")
 * @deprecated slightly different than the implementation in sales.ts
 */
export function addSemiMonthlyDays(date: Date | string, numsu: number) {
  const SMDate = new Date(date);
  const SM1 = SMDate.getDate();
  let SDate = date;
  let numm = 0;
  if (numsu - 2 * Math.floor(numsu / 2) === 0) {
    numm = Math.floor(numsu / 2);
  } else {
    if (SM1 > 15) {
      SDate = addDays(date, -15);
      numm = 1 + Math.floor(numsu / 2);
    } else {
      SDate = addDays(date, 15);
      numm = Math.floor(numsu / 2);
    }
  }
  const newdate = new Date(SDate);
  let mm = newdate.getMonth();
  let yy = newdate.getFullYear();
  let xx = 0;
  mm = mm + numm;
  xx = Math.floor((mm - 1) / 12);
  mm = mm - xx * 12;
  yy = yy + xx;
  newdate.setFullYear(yy, mm);
  return newdate;
}

/**
 * Already existed; we keep it as-is, but the name is fine.
 */
export function getsalestax(
  sp: number,
  ta: number,
  rate: number,
  tmax: number,
  tmin: number,
  ton: string,
  add1: number,
  add2: number,
  add3: number,
  add4: number,
  add5: number,
  add6: number,
  add7: number,
  add8: number,
  add9: number,
  add10: number,
  tax1: boolean,
  tax2: boolean,
  tax3: boolean,
  tax4: boolean,
  tax5: boolean,
  tax6: boolean,
  tax7: boolean,
  tax8: boolean,
  tax9: boolean,
  tax10: boolean
) {
  let taxable = 0;
  let taxamt = 0;
  switch (ton) {
    case "Sales Price less Trade":
      taxable = sp - ta;
      break;
    case "Sales Price":
      taxable = sp;
      break;
    default:
      taxable = 0;
  }

  if (tax1) taxable += add1;
  if (tax2) taxable += add2;
  if (tax3) taxable += add3;
  if (tax4) taxable += add4;
  if (tax5) taxable += add5;
  if (tax6) taxable += add6;
  if (tax7) taxable += add7;
  if (tax8) taxable += add8;
  if (tax9) taxable += add9;
  if (tax10) taxable += add10;

  taxamt = (taxable * rate) / 100;
  taxamt = Math.round(0.499 + taxamt * 100) / 100;
  taxamt = Math.max(0, taxamt);
  if (tmin > 0) {
    taxamt = Math.max(taxamt, tmin);
  }
  if (tmax > 0) {
    taxamt = Math.min(taxamt, tmax);
  }

  return taxamt;
}

/* ------------------------------------------------------------------
   Step 2: Extract repeated code for calculating state, county, city, 
   and loc taxes into helper functions.
   ------------------------------------------------------------------ */

/**
 * Helper for reCalcFinanceTerms (pulls tax info from `saleData`).
 *
 * Returns the sum of four taxes: state, county, city, and local.
 * This replaces the repeated code in `reCalcFinanceTerms` for:
 *   statesalestax + countysalestax + citysalestax + locsalestax
 */
export function calculateCombinedSalesTaxFromSaleData(
  salesPrice: number,
  tradeAllowance: number,
  addon1: number,
  addon2: number,
  addon3: number,
  addon4: number,
  addon5: number,
  addon6: number,
  addon7: number,
  addon8: number,
  addon9: number,
  addon10: number,
  saleData: SaleData
): number {
  const stax1 = saleData.sale?.staX1 ?? false;
  const stax2 = saleData.sale?.staX2 ?? false;
  const stax3 = saleData.sale?.staX3 ?? false;
  const stax4 = saleData.sale?.staX4 ?? false;
  const stax5 = saleData.sale?.staX5 ?? false;
  const stax6 = saleData.sale?.staX6 ?? false;
  const stax7 = saleData.sale?.staX7 ?? false;
  const stax8 = saleData.sale?.staX8 ?? false;
  const stax9 = saleData.sale?.staX9 ?? false;
  const stax10 = saleData.sale?.staX10 ?? false;

  const ctax1 = saleData.sale?.citytaX1 ?? false;
  const ctax2 = saleData.sale?.citytaX2 ?? false;
  const ctax3 = saleData.sale?.citytaX3 ?? false;
  const ctax4 = saleData.sale?.citytaX4 ?? false;
  const ctax5 = saleData.sale?.citytaX5 ?? false;
  const ctax6 = saleData.sale?.citytaX6 ?? false;
  const ctax7 = saleData.sale?.citytaX7 ?? false;
  const ctax8 = saleData.sale?.citytaX8 ?? false;
  const ctax9 = saleData.sale?.citytaX9 ?? false;
  const ctax10 = saleData.sale?.citytaX10 ?? false;

  const ltax1 = saleData.sale?.loctaX1 ?? false;
  const ltax2 = saleData.sale?.loctaX2 ?? false;
  const ltax3 = saleData.sale?.loctaX3 ?? false;
  const ltax4 = saleData.sale?.loctaX4 ?? false;
  const ltax5 = saleData.sale?.loctaX5 ?? false;
  const ltax6 = saleData.sale?.loctaX6 ?? false;
  const ltax7 = saleData.sale?.loctaX7 ?? false;
  const ltax8 = saleData.sale?.loctaX8 ?? false;
  const ltax9 = saleData.sale?.loctaX9 ?? false;
  const ltax10 = saleData.sale?.loctaX10 ?? false;

  const otax1 = saleData.sale?.countytaX1 ?? false;
  const otax2 = saleData.sale?.countytaX2 ?? false;
  const otax3 = saleData.sale?.countytaX3 ?? false;
  const otax4 = saleData.sale?.countytaX4 ?? false;
  const otax5 = saleData.sale?.countytaX5 ?? false;
  const otax6 = saleData.sale?.countytaX6 ?? false;
  const otax7 = saleData.sale?.countytaX7 ?? false;
  const otax8 = saleData.sale?.countytaX8 ?? false;
  const otax9 = saleData.sale?.countytaX9 ?? false;
  const otax10 = saleData.sale?.countytaX10 ?? false;

  const staterate = saleData.sale?.staterate ?? 0;
  const stateon = saleData.sale?.stateon ?? "";
  const statemin = saleData.sale?.statemin ?? 0;
  const statemax = saleData.sale?.statemax ?? 0;

  const countyrate = saleData.sale?.countyrate ?? 0;
  const countymax = saleData.sale?.countymax ?? 0;
  const countymin = saleData.sale?.countymin ?? 0;
  const countyon = saleData.sale?.countyon ?? "";

  const cityrate = saleData.sale?.cityrate ?? 0;
  const citymax = saleData.sale?.citymax ?? 0;
  const citymin = saleData.sale?.citymin ?? 0;
  const cityon = saleData.sale?.cityon ?? "";

  const locrate = saleData.sale?.locrate ?? 0;
  const locmax = saleData.sale?.locmax ?? 0;
  const locmin = saleData.sale?.locmin ?? 0;
  const locon = saleData.sale?.locon ?? "";

  const statesalestax = getsalestax(
    salesPrice,
    tradeAllowance,
    staterate,
    statemax,
    statemin,
    stateon!,
    addon1,
    addon2,
    addon3,
    addon4,
    addon5,
    addon6,
    addon7,
    addon8,
    addon9,
    addon10,
    stax1,
    stax2,
    stax3,
    stax4,
    stax5,
    stax6,
    stax7,
    stax8,
    stax9,
    stax10
  );
  const countysalestax = getsalestax(
    salesPrice,
    tradeAllowance,
    countyrate,
    countymax,
    countymin,
    countyon!,
    addon1,
    addon2,
    addon3,
    addon4,
    addon5,
    addon6,
    addon7,
    addon8,
    addon9,
    addon10,
    otax1,
    otax2,
    otax3,
    otax4,
    otax5,
    otax6,
    otax7,
    otax8,
    otax9,
    otax10
  );
  const citysalestax = getsalestax(
    salesPrice,
    tradeAllowance,
    cityrate,
    citymax,
    citymin,
    cityon!,
    addon1,
    addon2,
    addon3,
    addon4,
    addon5,
    addon6,
    addon7,
    addon8,
    addon9,
    addon10,
    ctax1,
    ctax2,
    ctax3,
    ctax4,
    ctax5,
    ctax6,
    ctax7,
    ctax8,
    ctax9,
    ctax10
  );
  const locsalestax = getsalestax(
    salesPrice,
    tradeAllowance,
    locrate,
    locmax,
    locmin,
    locon!,
    addon1,
    addon2,
    addon3,
    addon4,
    addon5,
    addon6,
    addon7,
    addon8,
    addon9,
    addon10,
    ltax1,
    ltax2,
    ltax3,
    ltax4,
    ltax5,
    ltax6,
    ltax7,
    ltax8,
    ltax9,
    ltax10
  );

  return statesalestax + countysalestax + citysalestax + locsalestax;
}

/**
 * Helper for reCalcFinanceQuote (pulls tax info from `quoteData`).
 *
 * Returns the sum of the four taxes: state, county, city, and local.
 * This replaces the repeated code in `reCalcFinanceQuote`.
 */
export function calculateCombinedSalesTaxFromQuoteData(
  salesPrice: number,
  tradeAllowance: number,
  addon1: number,
  addon2: number,
  addon3: number,
  addon4: number,
  addon5: number,
  addon6: number,
  addon7: number,
  addon8: number,
  addon9: number,
  addon10: number,
  quoteData: QuoteData
): number {
  const stax1 = quoteData.staX1;
  const stax2 = quoteData.staX2;
  const stax3 = quoteData.staX3;
  const stax4 = quoteData.staX4;
  const stax5 = quoteData.staX5;
  const stax6 = quoteData.staX6;
  const stax7 = quoteData.staX7;
  const stax8 = quoteData.staX8;
  const stax9 = quoteData.staX9;
  const stax10 = quoteData.staX10;

  const ctax1 = quoteData.cityTaX1;
  const ctax2 = quoteData.cityTaX2;
  const ctax3 = quoteData.cityTaX3;
  const ctax4 = quoteData.cityTaX4;
  const ctax5 = quoteData.cityTaX5;
  const ctax6 = quoteData.cityTaX6;
  const ctax7 = quoteData.cityTaX7;
  const ctax8 = quoteData.cityTaX8;
  const ctax9 = quoteData.cityTaX9;
  const ctax10 = quoteData.cityTaX10;

  const ltax1 = quoteData.locTaX1;
  const ltax2 = quoteData.locTaX2;
  const ltax3 = quoteData.locTaX3;
  const ltax4 = quoteData.locTaX4;
  const ltax5 = quoteData.locTaX5;
  const ltax6 = quoteData.locTaX6;
  const ltax7 = quoteData.locTaX7;
  const ltax8 = quoteData.locTaX8;
  const ltax9 = quoteData.locTaX9;
  const ltax10 = quoteData.locTaX10;

  const otax1 = quoteData.countyTaX1;
  const otax2 = quoteData.countyTaX2;
  const otax3 = quoteData.countyTaX3;
  const otax4 = quoteData.countyTaX4;
  const otax5 = quoteData.countyTaX5;
  const otax6 = quoteData.countyTaX6;
  const otax7 = quoteData.countyTaX7;
  const otax8 = quoteData.countyTaX8;
  const otax9 = quoteData.countyTaX9;
  const otax10 = quoteData.countyTaX10;

  const staterate = quoteData.staterate;
  const stateon = quoteData.stateon;
  const statemin = quoteData.statemin;
  const statemax = quoteData.statemax;

  const countyrate = quoteData.countyrate;
  const countymax = quoteData.countymax;
  const countymin = quoteData.countymin;
  const countyon = quoteData.countyon;

  const cityrate = quoteData.cityrate;
  const citymax = quoteData.citymax;
  const citymin = quoteData.citymin;
  const cityon = quoteData.cityon;

  const locrate = quoteData.locrate;
  const locmax = quoteData.locmax;
  const locmin = quoteData.locmin;
  const locon = quoteData.locon;

  const statesalestax = getsalestax(
    salesPrice,
    tradeAllowance,
    staterate,
    statemax,
    statemin,
    stateon!,
    addon1,
    addon2,
    addon3,
    addon4,
    addon5,
    addon6,
    addon7,
    addon8,
    addon9,
    addon10,
    stax1,
    stax2,
    stax3,
    stax4,
    stax5,
    stax6,
    stax7,
    stax8,
    stax9,
    stax10
  );
  const countysalestax = getsalestax(
    salesPrice,
    tradeAllowance,
    countyrate,
    countymax,
    countymin,
    countyon!,
    addon1,
    addon2,
    addon3,
    addon4,
    addon5,
    addon6,
    addon7,
    addon8,
    addon9,
    addon10,
    otax1,
    otax2,
    otax3,
    otax4,
    otax5,
    otax6,
    otax7,
    otax8,
    otax9,
    otax10
  );
  const citysalestax = getsalestax(
    salesPrice,
    tradeAllowance,
    cityrate,
    citymax,
    citymin,
    cityon!,
    addon1,
    addon2,
    addon3,
    addon4,
    addon5,
    addon6,
    addon7,
    addon8,
    addon9,
    addon10,
    ctax1,
    ctax2,
    ctax3,
    ctax4,
    ctax5,
    ctax6,
    ctax7,
    ctax8,
    ctax9,
    ctax10
  );
  const locsalestax = getsalestax(
    salesPrice,
    tradeAllowance,
    locrate,
    locmax,
    locmin,
    locon!,
    addon1,
    addon2,
    addon3,
    addon4,
    addon5,
    addon6,
    addon7,
    addon8,
    addon9,
    addon10,
    ltax1,
    ltax2,
    ltax3,
    ltax4,
    ltax5,
    ltax6,
    ltax7,
    ltax8,
    ltax9,
    ltax10
  );

  return statesalestax + countysalestax + citysalestax + locsalestax;
}

/** @deprecated extremely large function, high-risk */
export function reCalcFinanceTerms(
  data: FinanceTermData,
  saleData: SaleData,
  specialPaymentPlanList: SpecialPaymentPlan[],
  quoteData: QuoteData,
  lock: TermsLockType
) {
  const SStatus = saleData.sale?.appstatus;

  if (SStatus === "Pending") {
    const Lock = lock ? lock : (saleData.sale?.lock as TermsLockType);
    let salesprice = Math.max(0, data.salesPrice);
    let saledate = data.dateOfSale;
    let salestax = data.salesTax;

    const addon1 = Math.max(0, data.license);
    const addon2 = Math.max(0, data.title);
    const addon3 = Math.max(0, data.registration);
    const addon4 = Math.max(0, data.customField1);
    const addon5 = Math.max(0, data.customField2);
    const addon6 = Math.max(0, data.customField3);
    const addon7 = Math.max(0, data.customField4);
    const addon8 = Math.max(0, data.customField5);
    let addon9 = Math.max(0, data.customField6);
    const addon10 = Math.max(0, data.serviceContract);

    const tradeallow = Math.max(0, data.tradeAllowance);
    const tradepayoff = data.tradePayoff;
    const depositdown = Math.max(0, data.depositDown);
    let cod = Math.max(0, data.cashOnDelivery);
    const intrate = Math.max(0, data.interestRate * 100); // alpha used whole number

    let amtfin = data.amountFinanced;
    const firstpmtdue = dayjs(data.dateStarted).add(1, "day").format("YYYY-MM-DD");
    const sched = data.schedule;
    let term = data.term;
    let pmtamt = data.amount;
    let numpmts = data.numberOfPayments;

    let final = data.final;
    let tofp = data.totalPayments;

    const spnum2 = Math.max(0, specialPaymentPlanList[0]?.numberOfPayments || 0);
    const spnum3 = Math.max(0, specialPaymentPlanList[1]?.numberOfPayments || 0);
    const spnum4 = Math.max(0, specialPaymentPlanList[2]?.numberOfPayments || 0);
    const spnum5 = Math.max(0, specialPaymentPlanList[3]?.numberOfPayments || 0);

    const spamt2 = Math.max(0, specialPaymentPlanList[0]?.amount || 0);
    const spamt3 = Math.max(0, specialPaymentPlanList[1]?.amount || 0);
    const spamt4 = Math.max(0, specialPaymentPlanList[2]?.amount || 0);
    const spamt5 = Math.max(0, specialPaymentPlanList[3]?.amount || 0);

    const spdd2 = specialPaymentPlanList[0]?.dateStarted
      ? dayjs(specialPaymentPlanList[0].dateStarted).add(1, "day").format("YYYY-MM-DD")
      : "";
    const spdd3 = specialPaymentPlanList[1]?.dateStarted
      ? dayjs(specialPaymentPlanList[1].dateStarted).add(1, "day").format("YYYY-MM-DD")
      : "";
    const spdd4 = specialPaymentPlanList[2]?.dateStarted
      ? dayjs(specialPaymentPlanList[2].dateStarted).add(1, "day").format("YYYY-MM-DD")
      : "";
    const spdd5 = specialPaymentPlanList[3]?.dateStarted
      ? dayjs(specialPaymentPlanList[3].dateStarted).add(1, "day").format("YYYY-MM-DD")
      : "";

    const spsch2 = specialPaymentPlanList[0]?.schedule || "";
    const spsch3 = specialPaymentPlanList[1]?.schedule || "";
    const spsch4 = specialPaymentPlanList[2]?.schedule || "";
    const spsch5 = specialPaymentPlanList[3]?.schedule || "";

    // STEP 2 CHANGE: Instead of manually summing statesalestax + countysalestax + etc.
    // We now call our new helper:
    salestax = calculateCombinedSalesTaxFromSaleData(
      salesprice,
      tradeallow,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      saleData
    );

    const deferreddown = 0;
    const useinvtax = quoteData.useTXInvTax;
    const invtaxrate = quoteData.invTaxRate!;

    if (useinvtax === true) {
      addon9 = Math.round(salesprice * (invtaxrate / 100) * 100) / 100;
    }

    let tsp =
      salesprice +
      addon1 +
      addon2 +
      addon3 +
      addon4 +
      addon5 +
      addon6 +
      addon7 +
      addon8 +
      addon9 +
      addon10 +
      salestax;

    const tradenet = tradeallow - tradepayoff;
    let totaldown = cod + tradenet + depositdown + deferreddown;
    if (amtfin < 0) {
      cod = Math.max(0, cod + amtfin);
      totaldown = cod + tradenet + depositdown + deferreddown;
      amtfin = tsp - totaldown;
      if (amtfin < 0) {
        // recalc if it goes negative
        salesprice = salesprice - amtfin;

        // re-run the same combined sales tax logic
        salestax = calculateCombinedSalesTaxFromSaleData(
          salesprice,
          tradeallow,
          addon1,
          addon2,
          addon3,
          addon4,
          addon5,
          addon6,
          addon7,
          addon8,
          addon9,
          addon10,
          saleData
        );

        tsp =
          salesprice +
          addon1 +
          addon2 +
          addon3 +
          addon4 +
          addon5 +
          addon6 +
          addon7 +
          addon8 +
          addon9 +
          addon10 +
          salestax;
        amtfin = tsp - totaldown;
      }
    }

    let txpmttax = 0;
    let txdowntax = 0;
    let txtaxbal = 0;
    const usetxtax = quoteData.useTXTax;
    const staterate = saleData.sale?.staterate ?? 0;
    if (usetxtax === true) {
      txdowntax = Math.round(staterate * (cod + depositdown + deferreddown)) / 100;
      txtaxbal = salestax;
    }

    if (Lock === "Amt") {
      if (pmtamt > 0) {
        numpmts = 0;
      }
    } else {
      if (numpmts > 0) {
        pmtamt = 0;
      }
    }

    let spPrin2 = 0;
    let spPrin3 = 0;
    let spPrin4 = 0;
    let spPrin5 = 0;

    if (spnum2 > 0) {
      spPrin2 = calculatePVSpecialPayment(
        spnum2,
        spamt2,
        spsch2,
        saledate,
        spdd2,
        intrate / 100,
        sched
      );
    }
    if (spnum3 > 0) {
      spPrin3 = calculatePVSpecialPayment(
        spnum3,
        spamt3,
        spsch3,
        saledate,
        spdd3,
        intrate / 100,
        sched
      );
    }
    if (spnum4 > 0) {
      spPrin4 = calculatePVSpecialPayment(
        spnum4,
        spamt4,
        spsch4,
        saledate,
        spdd4,
        intrate / 100,
        sched
      );
    }
    if (spnum5 > 0) {
      spPrin5 = calculatePVSpecialPayment(
        spnum5,
        spamt5,
        spsch5,
        saledate,
        spdd5,
        intrate / 100,
        sched
      );
    }

    let amountfinanced = amtfin - spPrin2 - spPrin3 - spPrin4 - spPrin5;
    if (intrate > 0) {
      amountfinanced = amountfinanced - txtaxbal;
    }

    const edate = firstpmtdue;
    const bdate = saledate;
    const odds = getOddDays(sched, bdate, edate);
    const units = getOddUnits(sched, bdate, edate);
    const tdays = getTermDays(sched);
    const Daysinyear = getDaysInYear(sched);
    const perrate = intrate / 100 / (Daysinyear / tdays);
    const Day2First = numDays(edate, bdate);

    const ii = perrate;
    const ii1 = 1 + ii;
    const irate = intrate / 100;

    if (pmtamt <= 0 && numpmts <= 0) {
      numpmts = 1;
      pmtamt = 0;
    }

    let finaldue = new Date(data.finalDueOn);
    let FirstBal;
    let iiN;
    let uprin;

    //  ~~~ (unchanged calculations) ~~~
    //  We have only replaced the repetitive tax calculation lines with
    //  our new helper. All other logic below is still identical.

    if (intrate === 0) {
      if (pmtamt === 0) {
        numpmts = Math.max(1, numpmts);
        pmtamt = Math.round((100 * amountfinanced) / numpmts) / 100;
        final = amountfinanced - pmtamt * numpmts;
        if (final === 0) {
          finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
        } else {
          finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
        }
      } else {
        if (numpmts === 0) {
          numpmts = Math.floor(amountfinanced / pmtamt);
          final = amountfinanced - pmtamt * numpmts;
          if (final === 0) {
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
          } else {
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
          }
        } else {
          numpmts = Math.max(1, numpmts);
          pmtamt = Math.round((100 * amountfinanced) / numpmts) / 100;
          final = amountfinanced - pmtamt * numpmts;
          if (final === 0) {
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
          } else {
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
          }
        }
      }
    } else {
      if (pmtamt > 0) {
        if (numpmts > 0) {
          txpmttax = txtaxbal / numpmts;
          if (odds < 0) {
            numpmts = numpmts - 1;
            FirstBal = amountfinanced * (1 + (Day2First * irate) / 365) - (pmtamt - txpmttax);
            iiN = Math.pow(ii1, numpmts);
            final =
              FirstBal * iiN -
              ((pmtamt - txpmttax) / ii) * (iiN - 1) +
              (FirstBal * iiN - ((pmtamt - txpmttax) / ii) * (iiN - 1)) * ii;
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts + 1);
            numpmts = numpmts + 1;
          } else {
            final = -1;
            while (final <= 0) {
              uprin = amountfinanced * Math.pow(1 + ii, units);
              FirstBal = uprin * (1 + (odds * irate) / Daysinyear);
              iiN = Math.pow(ii1, numpmts);
              final =
                FirstBal * iiN -
                ((pmtamt - txpmttax) / ii) * (iiN - 1) +
                (FirstBal * iiN - ((pmtamt - txpmttax) / ii) * (iiN - 1)) * ii;
              if (final < 0) {
                numpmts = numpmts - Math.floor((final * -1) / (pmtamt - txpmttax));
              }
            }
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
          }
        } else {
          txpmttax = txtaxbal / (amountfinanced / pmtamt);
          if (odds < 0) {
            FirstBal = amountfinanced * (1 + (Day2First * irate) / 365) - (pmtamt - txpmttax);
            numpmts = Math.floor(calculateTermFromPayment(FirstBal, ii, pmtamt - txpmttax));
            txpmttax = txtaxbal / numpmts;
            iiN = Math.pow(ii1, numpmts);
            final =
              FirstBal * iiN -
              ((pmtamt - txpmttax) / ii) * (iiN - 1) +
              (FirstBal * iiN - ((pmtamt - txpmttax) / ii) * (iiN - 1)) * ii;
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts + 1);
            numpmts = numpmts + 1;
          } else {
            final = -1;
            let Loopmax = 1;
            while (final < 0 && Loopmax < 10) {
              uprin = amountfinanced * Math.pow(1 + ii, units);
              FirstBal = uprin * (1 + (odds * irate) / Daysinyear);
              numpmts = Math.floor(calculateTermFromPayment(FirstBal, ii, pmtamt - txpmttax));
              txpmttax = txtaxbal / numpmts;
              iiN = Math.pow(ii1, numpmts);
              final =
                FirstBal * iiN -
                ((pmtamt - txpmttax) / ii) * (iiN - 1) +
                (FirstBal * iiN - ((pmtamt - txpmttax) / ii) * (iiN - 1)) * ii;
              Loopmax = Loopmax + 1;
            }
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
          }
        }
      } else {
        if (numpmts > 0) {
          txpmttax = txtaxbal / numpmts;
          if (numpmts === 1) {
            if (odds < 0) {
              pmtamt = amountfinanced * (1 + (Day2First * irate) / 365);
              finaldue = calculateFinalDate(firstpmtdue, sched, 0);
            } else {
              uprin = amountfinanced * Math.pow(1 + ii, units + 1);
              pmtamt = uprin * (1 + (odds * irate) / Daysinyear);
              finaldue = calculateFinalDate(firstpmtdue, sched, 0);
            }
            final = 0;
          } else {
            let GuessPmt = calculateAmortizedPayment(amountfinanced, ii, numpmts);
            let PaymentAmt = 0;
            let Loopmax = 0;
            let isodd;
            while (
              Math.round(PaymentAmt * 100) / 100 !== Math.round(GuessPmt * 100) / 100 &&
              Loopmax < 20
            ) {
              PaymentAmt = GuessPmt;
              Loopmax = Loopmax + 1;
              if (odds < 0) {
                FirstBal = amountfinanced * (1 + (Day2First * irate) / 365) - GuessPmt;
                isodd = 1;
              } else {
                uprin = amountfinanced * Math.pow(1 + ii, units);
                FirstBal = uprin * (1 + (odds * irate) / Daysinyear);
                isodd = 0;
              }
              GuessPmt = calculateAmortizedPayment(FirstBal, ii, numpmts - isodd);
            }
            final = 0;
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
            pmtamt = Math.round((txpmttax + GuessPmt) * 100) / 100;
          }
          finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
        }
      }
    }

    final = Math.round(final * 100) / 100;
    term = calculateTerm(finaldue, saledate);
    tofp =
      numpmts * pmtamt +
      spnum2 * spamt2 +
      spnum3 * spamt3 +
      spnum4 * spamt4 +
      spnum5 * spamt5 +
      final;
    const finchg = tofp - amtfin;

    return {
      saleDate: dayjs(saledate).utc().format("YYYY-MM-DD"),
      salesTax: salestax,
      salesPrice: salesprice,
      crLife: 0,
      crDisb: 0,
      totalSp: tsp,
      cod: cod,
      totalDown: totaldown,
      amountFinanced: amtfin,
      term: term,
      paymentAmount: pmtamt,
      numberPayments: numpmts,
      finalPayment: final,
      finalPaymentDue: dayjs(finaldue).format("YYYY-MM-DD"),
      totalOfPayments: tofp,
      financeCharge: finchg,
      spNum1: numpmts,
      spAmt1: pmtamt,
      firstPaymentDue: firstpmtdue,
      spSch1: sched,
      spNum2: spnum2,
      spAmt2: spamt2,
      spdd2: spdd2,
      spSch2: spsch2,
      spNum3: spnum3,
      spAmt3: spamt3,
      spdd3: spdd3,
      spSch3: spsch3,
      spNum4: spnum4,
      spAmt4: spamt4,
      spdd4: spdd2,
      spSch4: spsch4,
      spNum5: spnum5,
      spAmt5: spamt5,
      spdd5: spdd5,
      spSch5: spsch5,
      spNum6: 1,
      spAmt6: final,
      spdd6: dayjs(finaldue).utc().format("YYYY-MM-DD"),
      spSch6: "",
      license: addon1,
      title: addon2,
      registration: addon3,
      addon4: addon4,
      addon5: addon5,
      addon6: addon6,
      addon7: addon7,
      addon8: addon8,
      addon9: addon9,
      serviceContract: addon10,
      goodCalc: true,
      txPmtTax: txpmttax,
      txDownTax: txdowntax,
      taxTaxBal: txtaxbal,
    };
  }

  return;
}

/** @deprecated extremely large function, high-risk */
export function reCalcFinanceQuote(
  data: FinanceTermData,
  specialPaymentPlanList: SpecialPaymentPlan[],
  quoteData: QuoteData,
  lock: string
) {
  const SStatus = "Pending";

  if (SStatus === "Pending") {
    const Lock = lock;
    let salesprice = Math.max(0, data.salesPrice);
    let saledate = data.dateOfSale;
    // original logic forcibly overwrote saledate
    saledate = Date();
    let salestax = data.salesTax;

    const addon1 = Math.max(0, data.license);
    const addon2 = Math.max(0, data.title);
    const addon3 = Math.max(0, data.registration);
    const addon4 = Math.max(0, data.customField1);
    const addon5 = Math.max(0, data.customField2);
    const addon6 = Math.max(0, data.customField3);
    const addon7 = Math.max(0, data.customField4);
    const addon8 = Math.max(0, data.customField5);
    let addon9 = Math.max(0, data.customField6);
    const addon10 = Math.max(0, data.serviceContract);

    const tradeallow = Math.max(0, data.tradeAllowance);
    const tradepayoff = data.tradePayoff;
    const depositdown = Math.max(0, data.depositDown);
    let cod = Math.max(0, data.cashOnDelivery);
    const intrate = Math.max(0, data.interestRate * 100);

    let amtfin = data.amountFinanced;
    const firstpmtdue = dayjs(data.dateStarted).add(1, "day").format("YYYY-MM-DD");
    const sched = data.schedule;
    let term = data.term;
    let pmtamt = data.amount;
    let numpmts = data.numberOfPayments;

    let final = data.final;
    let tofp = data.totalPayments;

    const spnum2 = Math.max(0, specialPaymentPlanList[0]?.numberOfPayments || 0);
    const spnum3 = Math.max(0, specialPaymentPlanList[1]?.numberOfPayments || 0);
    const spnum4 = Math.max(0, specialPaymentPlanList[2]?.numberOfPayments || 0);
    const spnum5 = Math.max(0, specialPaymentPlanList[3]?.numberOfPayments || 0);

    const spamt2 = Math.max(0, specialPaymentPlanList[0]?.amount || 0);
    const spamt3 = Math.max(0, specialPaymentPlanList[1]?.amount || 0);
    const spamt4 = Math.max(0, specialPaymentPlanList[2]?.amount || 0);
    const spamt5 = Math.max(0, specialPaymentPlanList[3]?.amount || 0);

    const spdd2 = specialPaymentPlanList[0]?.dateStarted || "";
    const spdd3 = specialPaymentPlanList[1]?.dateStarted || "";
    const spdd4 = specialPaymentPlanList[2]?.dateStarted || "";
    const spdd5 = specialPaymentPlanList[3]?.dateStarted || "";

    const spsch2 = specialPaymentPlanList[0]?.schedule || "";
    const spsch3 = specialPaymentPlanList[1]?.schedule || "";
    const spsch4 = specialPaymentPlanList[2]?.schedule || "";
    const spsch5 = specialPaymentPlanList[3]?.schedule || "";

    // STEP 2 CHANGE: Instead of manually summing statesalestax + countysalestax + etc.
    // We now call our new helper for quoteData:
    salestax = calculateCombinedSalesTaxFromQuoteData(
      salesprice,
      tradeallow,
      addon1,
      addon2,
      addon3,
      addon4,
      addon5,
      addon6,
      addon7,
      addon8,
      addon9,
      addon10,
      quoteData
    );

    const deferreddown = 0;
    const useinvtax = quoteData.useTXInvTax;
    const invtaxrate = quoteData.invTaxRate!;

    if (useinvtax === true) {
      addon9 = Math.round(salesprice * (invtaxrate / 100) * 100) / 100;
    }

    let tsp =
      salesprice +
      addon1 +
      addon2 +
      addon3 +
      addon4 +
      addon5 +
      addon6 +
      addon7 +
      addon8 +
      addon9 +
      addon10 +
      salestax;

    const tradenet = tradeallow - tradepayoff;
    let totaldown = cod + tradenet + depositdown + deferreddown;
    if (amtfin < 0) {
      cod = Math.max(0, cod + amtfin);
      totaldown = cod + tradenet + depositdown + deferreddown;
      amtfin = tsp - totaldown;
      if (amtfin < 0) {
        salesprice = salesprice - amtfin;

        // re-run the combined tax logic using quoteData
        salestax = calculateCombinedSalesTaxFromQuoteData(
          salesprice,
          tradeallow,
          addon1,
          addon2,
          addon3,
          addon4,
          addon5,
          addon6,
          addon7,
          addon8,
          addon9,
          addon10,
          quoteData
        );

        tsp =
          salesprice +
          addon1 +
          addon2 +
          addon3 +
          addon4 +
          addon5 +
          addon6 +
          addon7 +
          addon8 +
          addon9 +
          addon10 +
          salestax;
        amtfin = tsp - totaldown;
      }
    }

    let txpmttax = 0;
    let txdowntax = 0;
    let txtaxbal = 0;
    const usetxtax = quoteData.useTXTax;
    const staterate = quoteData.staterate;
    if (usetxtax === true) {
      txdowntax = Math.round(staterate * (cod + depositdown + deferreddown)) / 100;
      txtaxbal = salestax;
    }

    if (Lock === "Amt") {
      if (pmtamt > 0) {
        numpmts = 0;
      }
    } else {
      if (numpmts > 0) {
        pmtamt = 0;
      }
    }

    let spPrin2 = 0;
    let spPrin3 = 0;
    let spPrin4 = 0;
    let spPrin5 = 0;

    if (spnum2 > 0) {
      spPrin2 = calculatePVSpecialPayment(
        spnum2,
        spamt2,
        spsch2,
        saledate,
        spdd2,
        intrate / 100,
        sched
      );
    }
    if (spnum3 > 0) {
      spPrin3 = calculatePVSpecialPayment(
        spnum3,
        spamt3,
        spsch3,
        saledate,
        spdd3,
        intrate / 100,
        sched
      );
    }
    if (spnum4 > 0) {
      spPrin4 = calculatePVSpecialPayment(
        spnum4,
        spamt4,
        spsch4,
        saledate,
        spdd4,
        intrate / 100,
        sched
      );
    }
    if (spnum5 > 0) {
      spPrin5 = calculatePVSpecialPayment(
        spnum5,
        spamt5,
        spsch5,
        saledate,
        spdd5,
        intrate / 100,
        sched
      );
    }

    let amountfinanced = amtfin - spPrin2 - spPrin3 - spPrin4 - spPrin5;
    if (intrate > 0) {
      amountfinanced = amountfinanced - txtaxbal;
    }

    const edate = firstpmtdue;
    const bdate = saledate;
    const odds = getOddDays(sched, bdate, edate);
    const units = getOddUnits(sched, bdate, edate);
    const tdays = getTermDays(sched);
    const Daysinyear = getDaysInYear(sched);
    const perrate = intrate / 100 / (Daysinyear / tdays);
    const Day2First = numDays(edate, bdate);

    const ii = perrate;
    const ii1 = 1 + ii;
    const irate = intrate / 100;

    if (pmtamt <= 0 && numpmts <= 0) {
      numpmts = 1;
      pmtamt = 0;
    }

    let finaldue = new Date(data.finalDueOn);
    let FirstBal;
    let iiN;
    let uprin;

    // The rest is identical logic, as in Step 1. We only replaced
    // the repeated tax lines with our new helper function.

    if (intrate === 0) {
      if (pmtamt === 0) {
        numpmts = Math.max(1, numpmts);
        pmtamt = Math.round((100 * amountfinanced) / numpmts) / 100;
        final = amountfinanced - pmtamt * numpmts;
        if (final === 0) {
          finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
        } else {
          finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
        }
      } else {
        if (numpmts === 0) {
          numpmts = Math.floor(amountfinanced / pmtamt);
          final = amountfinanced - pmtamt * numpmts;
          if (final === 0) {
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
          } else {
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
          }
        } else {
          numpmts = Math.max(1, numpmts);
          pmtamt = Math.round((100 * amountfinanced) / numpmts) / 100;
          final = amountfinanced - pmtamt * numpmts;
          if (final === 0) {
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
          } else {
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
          }
        }
      }
    } else {
      if (pmtamt > 0) {
        if (numpmts > 0) {
          txpmttax = txtaxbal / numpmts;
          if (odds < 0) {
            numpmts = numpmts - 1;
            FirstBal = amountfinanced * (1 + (Day2First * irate) / 365) - (pmtamt - txpmttax);
            iiN = Math.pow(ii1, numpmts);
            final =
              FirstBal * iiN -
              ((pmtamt - txpmttax) / ii) * (iiN - 1) +
              (FirstBal * iiN - ((pmtamt - txpmttax) / ii) * (iiN - 1)) * ii;
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts + 1);
            numpmts = numpmts + 1;
          } else {
            final = -1;
            while (final <= 0) {
              uprin = amountfinanced * Math.pow(1 + ii, units);
              FirstBal = uprin * (1 + (odds * irate) / Daysinyear);
              iiN = Math.pow(ii1, numpmts);
              final =
                FirstBal * iiN -
                ((pmtamt - txpmttax) / ii) * (iiN - 1) +
                (FirstBal * iiN - ((pmtamt - txpmttax) / ii) * (iiN - 1)) * ii;
              if (final < 0) {
                numpmts = numpmts - Math.floor((final * -1) / (pmtamt - txpmttax));
              }
            }
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
          }
        } else {
          txpmttax = txtaxbal / (amountfinanced / pmtamt);
          if (odds < 0) {
            FirstBal = amountfinanced * (1 + (Day2First * irate) / 365) - (pmtamt - txpmttax);
            numpmts = Math.floor(calculateTermFromPayment(FirstBal, ii, pmtamt - txpmttax));
            txpmttax = txtaxbal / numpmts;
            iiN = Math.pow(ii1, numpmts);
            final =
              FirstBal * iiN -
              ((pmtamt - txpmttax) / ii) * (iiN - 1) +
              (FirstBal * iiN - ((pmtamt - txpmttax) / ii) * (iiN - 1)) * ii;
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts + 1);
            numpmts = numpmts + 1;
          } else {
            final = -1;
            let Loopmax = 1;
            while (final < 0 && Loopmax < 10) {
              uprin = amountfinanced * Math.pow(1 + ii, units);
              FirstBal = uprin * (1 + (odds * irate) / Daysinyear);
              numpmts = Math.floor(calculateTermFromPayment(FirstBal, ii, pmtamt - txpmttax));
              txpmttax = txtaxbal / numpmts;
              iiN = Math.pow(ii1, numpmts);
              final =
                FirstBal * iiN -
                ((pmtamt - txpmttax) / ii) * (iiN - 1) +
                (FirstBal * iiN - ((pmtamt - txpmttax) / ii) * (iiN - 1)) * ii;
              Loopmax = Loopmax + 1;
            }
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts);
          }
        }
      } else {
        if (numpmts > 0) {
          txpmttax = txtaxbal / numpmts;
          if (numpmts === 1) {
            if (odds < 0) {
              pmtamt = amountfinanced * (1 + (Day2First * irate) / 365);
              finaldue = calculateFinalDate(firstpmtdue, sched, 0);
            } else {
              uprin = amountfinanced * Math.pow(1 + ii, units + 1);
              pmtamt = uprin * (1 + (odds * irate) / Daysinyear);
              finaldue = calculateFinalDate(firstpmtdue, sched, 0);
            }
            final = 0;
          } else {
            let GuessPmt = calculateAmortizedPayment(amountfinanced, ii, numpmts);
            let PaymentAmt = 0;
            let Loopmax = 0;
            let isodd;
            while (
              Math.round(PaymentAmt * 100) / 100 !== Math.round(GuessPmt * 100) / 100 &&
              Loopmax < 20
            ) {
              PaymentAmt = GuessPmt;
              Loopmax = Loopmax + 1;
              if (odds < 0) {
                FirstBal = amountfinanced * (1 + (Day2First * irate) / 365) - GuessPmt;
                isodd = 1;
              } else {
                uprin = amountfinanced * Math.pow(1 + ii, units);
                FirstBal = uprin * (1 + (odds * irate) / Daysinyear);
                isodd = 0;
              }
              GuessPmt = calculateAmortizedPayment(FirstBal, ii, numpmts - isodd);
            }
            final = 0;
            finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
            pmtamt = Math.round((txpmttax + GuessPmt) * 100) / 100;
          }
          finaldue = calculateFinalDate(firstpmtdue, sched, numpmts - 1);
        }
      }
    }

    final = Math.round(final * 100) / 100;
    term = calculateTerm(finaldue, saledate);
    tofp =
      numpmts * pmtamt +
      spnum2 * spamt2 +
      spnum3 * spamt3 +
      spnum4 * spamt4 +
      spnum5 * spamt5 +
      final;
    const finchg = tofp - amtfin;

    return {
      saleDate: dayjs(saledate).utc().format("YYYY-MM-DD"),
      salesTax: salestax,
      salesPrice: salesprice,
      crLife: 0,
      crDisb: 0,
      totalSp: tsp,
      cod: cod,
      totalDown: totaldown,
      amountFinanced: amtfin,
      term: term,
      paymentAmount: pmtamt,
      numberPayments: numpmts,
      finalPayment: final,
      finalPaymentDue: dayjs(finaldue).format("YYYY-MM-DD"),
      totalOfPayments: tofp,
      financeCharge: finchg,
      spNum1: numpmts,
      spAmt1: pmtamt,
      firstPaymentDue: firstpmtdue,
      spSch1: sched,
      spNum2: spnum2,
      spAmt2: spamt2,
      spdd2: spdd2,
      spSch2: spsch2,
      spNum3: spnum3,
      spAmt3: spamt3,
      spdd3: spdd3,
      spSch3: spsch3,
      spNum4: spnum4,
      spAmt4: spamt4,
      spdd4: spdd2,
      spSch4: spsch4,
      spNum5: spnum5,
      spAmt5: spamt5,
      spdd5: spdd5,
      spSch5: spsch5,
      spNum6: 1,
      spAmt6: final,
      spdd6: dayjs(finaldue).utc().format("YYYY-MM-DD"),
      spSch6: "",
      license: addon1,
      title: addon2,
      registration: addon3,
      addon4: addon4,
      addon5: addon5,
      addon6: addon6,
      addon7: addon7,
      addon8: addon8,
      addon9: addon9,
      serviceContract: addon10,
      goodCalc: true,
      txPmtTax: txpmttax,
      txDownTax: txdowntax,
      taxTaxBal: txtaxbal,
    };
  }

  return;
}
