import { z } from "zod";

export const WholesaleFormTabLabel = z.enum(["Wholesale Management", "Vehicle", "Documents"]);
export type WholesaleFormTabLabel = z.infer<typeof WholesaleFormTabLabel>;
export const WholesaleFormTab = z.enum(["wholesale-management", "vehicle", "documents"]);
export type WholesaleFormTab = z.infer<typeof WholesaleFormTab>;

export type WsTabItem = { label: WholesaleFormTabLabel; value: WholesaleFormTab };

export const tabItems: WsTabItem[] = [
  { label: "Wholesale Management", value: "wholesale-management" },
  // { label: "Vehicle", value: "vehicle" },
  { label: "Documents", value: "documents" },
];
export const tabMap: Map<WholesaleFormTab, WsTabItem> = new Map<WholesaleFormTab, WsTabItem>(
  tabItems.map((t) => [t.value, t])
);
