import React, { FC, PropsWithChildren } from 'react';
// utils
import { currentEnv } from '.';
import usePermissionFlag from './usePermissionFlag';
import { PermissionKey } from './interfaces';

/** ### Flag a feature for a specific permission by wrapping with this component
 * Usage:
 * ```tsx
 * // Prevent entire set of routes from rendering
 * <PermissionFlag permissionNeeded="AFS_User">
 *   <Routes>
 *     <Route path="user-management/*">
 *       <Route index element={<UserAuthTable />} />
 *       <Route path="user/:userRecId" element={<UserAuthDetail />} />
 *       // ...
 *     </Route>
 *   </Routes>
 * </PermissionFlag>
 * ```
 */
const PermissionFlag: FC<
  PropsWithChildren & {
    permissionNeeded: PermissionKey;
  }
> = ({ permissionNeeded, children }) => {
  const shouldRender = usePermissionFlag(permissionNeeded);
  if (shouldRender) return <>{children}</>;
  return <></>;
};

export default PermissionFlag;
