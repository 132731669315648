import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

export const gpsTabs = ['deviceInfo', 'location', 'locationHistory'] as const;
export type GpsTabKey = (typeof gpsTabs)[number];
export const gpsTabMap = new Map<GpsTabKey, string>([
  ['deviceInfo', 'Device Info'],
  ['location', 'Location'],
  ['locationHistory', 'Location History'],
]);

export const PasstimePackage = z.enum(['TRAX', 'Gps', 'Pro', 'Encore']);
export type PasstimePackage = z.infer<typeof PasstimePackage>;

export const GpsDeviceType = z.enum(['None', 'GPS Only', 'GPS & Starter Interrupt']);
export type GpsDeviceType = z.infer<typeof GpsDeviceType>;

export const GpsDevice = z.enum(['Ituran', 'PassTime', 'Spireon']);
export type GpsDevice = z.infer<typeof GpsDevice>;

export interface UpdateGpsInformation {
  recId?: number;
  device?: GpsDevice | undefined;
  gpsType?: GpsDeviceType | undefined;
  deviceModel?: string;
  packageType?: PasstimePackage;
  serialNumber?: string;
  expires?:  string | undefined | null;
}

/** @deprecated found an error in an implementation. Please check response and state values for accuracy. */
export interface GpsHistoryItemDeprec {
  address: string;
  odometer: number;
  lastPing: string;
  course: string;
  speed: string;
  latitude: number;
  longitude: number;
}

export class PasstimeGetLastLocateRes<TDate extends string | Dayjs = Dayjs> {
  lastLocate: TDate; // datetime '2024-08-26T10:58:16.077';
  eventDate: TDate; // datetime '2024-08-26T19:02:48.913';
  address: string; // '861 Southpark Drive, Littleton, CO  80120, USA';
  lat: number; // 39.568972;
  long: number; // -104.9993;
  speed: string; // '1 MPH';
  course: string; // 'South West';
  type: string; // 'Auto (Actual)';
  guid: string; // 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
  id: number; // 2566586951;
  totalMiles: number; // 603;
  batteryPct: string | number | null; // null;
  voltage: number; // 13.9;
  showGpsUncertainty: boolean; // false;
  gpsUncertaintyInMeters: number; // 0
  gpsUncertaintyCircle2InMeters: number; // 0
  gpsUncertaintyInFeet: number; // 0
  gpsUncertaintyRgbColor: string | null; // null
  gpsUncertaintyColor: string | null; // null
  gpsUncertaintyFtDisplay: string | null; // null
  message: string | null; // null
  callResult: string; // "true"

  constructor(gpsRes: PasstimeGetLastLocateRes<string>) {
    this.lastLocate = dayjs(gpsRes.lastLocate) as TDate;
    this.eventDate = dayjs(gpsRes.eventDate) as TDate;
    this.address = gpsRes.address;
    this.lat = gpsRes.lat;
    this.long = gpsRes.long;
    this.speed = gpsRes.speed;
    this.course = gpsRes.course;
    this.type = gpsRes.type;
    this.guid = gpsRes.guid;
    this.id = gpsRes.id;
    this.totalMiles = gpsRes.totalMiles;
    this.batteryPct = gpsRes.batteryPct;
    this.voltage = gpsRes.voltage;
    this.showGpsUncertainty = gpsRes.showGpsUncertainty;
    this.gpsUncertaintyInMeters = gpsRes.gpsUncertaintyInMeters;
    this.gpsUncertaintyCircle2InMeters = gpsRes.gpsUncertaintyCircle2InMeters;
    this.gpsUncertaintyInFeet = gpsRes.gpsUncertaintyInFeet;
    this.gpsUncertaintyRgbColor = gpsRes.gpsUncertaintyRgbColor;
    this.gpsUncertaintyColor = gpsRes.gpsUncertaintyColor;
    this.gpsUncertaintyFtDisplay = gpsRes.gpsUncertaintyFtDisplay;
    this.message = gpsRes.message;
    this.callResult = gpsRes.callResult;
  }
}

export interface GpsInformation extends UpdateGpsInformation {
  lastLocationInformation: GpsHistoryItemDeprec;
  expires?: string | null | undefined;
}

/**
"lastLocate": "2024-08-26T10:58:16.077",
"eventDate": "2024-08-26T19:02:48.913",
"address": "861 Southpark Drive, Littleton, CO  80120, USA",
"lat": 39.568972,
"long": -104.9993,
"speed": "1 MPH",
"course": "South West",
"type": "Auto (Actual)",
"guid": "db8aff3c-c772-4fcc-abcb-47d74d8c6808",
"id": 2566586951,
"totalMiles": 603,
"batteryPct": null,
"voltage": 13.9,
"showGpsUncertainty": false,
"gpsUncertaintyInMeters": 0,
"gpsUncertaintyCircle2InMeters": 0,
"gpsUncertaintyInFeet": 0,
"gpsUncertaintyRgbColor": null,
"gpsUncertaintyColor": null,
"gpsUncertaintyFtDisplay": null,
"message": null,
"callResult": "true"
 */
