import { DateString } from '@/utils/types';

export interface AddNewInventoryPayload {
  vin: string;
  orgId: number;
  locId: number;
  compId: number;
  purchaseDate: string;
  dateReceived: string;
  purchaseVendorName: string;
  purchaseVendorId: number; // Vendor while in the form, number while submitting
  buyersName: string;
  year: string;
  make: string;
  model: string;
  purchasePrice: number;
  transport: number;
  psiCost: number;
  psiDays: number;
  exteriorColor: string;
  interiorColor: string;
  assignedTo: AssignableCompany | number; // AssignableCompany while in the form, number while submitting
  gpsInstalled: boolean;
  carTruck: number;
  milesIn: number;
  notActual: boolean;
  exceedsLimit: boolean;
  printExempt: boolean;
  trueMilesUnknown: boolean;
  salvageTitle: boolean;
  frameDamage: boolean;
  unibody: boolean;
  airBagDisclosure: boolean;
  customAlert: boolean;
  customAlertReason: string;
}

export interface AddNewInventoryResponse {
  invRecId: number;
}

export interface ColorListItem {
  recId: number;
  orgId?: number;
  compId?: number;
  locationId?: number;
  listLength?: number;
  item: string;
  view?: boolean;
  editable?: boolean;
}

/** @deprecated use `ICompanyForm` */
export interface AssignableCompany {
  recId: number;
  shortName: string;
}

export interface VinAuditData {
  vin: string;
  year: string;
  make: string;
  model: string;
  trim: string;
  shortTrim: string;
  trimVariations: string;
  madeIn: string;
  madeInCity: string;
  vehicleStyle: string;
  vehicleType: string;
  vehicleSize: string;
  vehicleCategory: string;
  doors: string;
  fuelType: string;
  fuelCapacity: string;
  cityMileage: string;
  highwayMileage: string;
  engine: string;
  engineSize: string;
  engineCylinders: string;
  transmissionType: string;
  transmissionGears: string;
  drivenWheels: string;
  antiBrakeSystem: string;
  steeringType: string;
  curbWeight: string;
  grossWeight: string;
  overallHeight: string;
  overallLength: string;
  overallWidth: string;
  standardSeating: string;
  optionalSeating: string;
  invoicePrice: string;
  deliveryCharges: string;
  msrp: string;
}

export interface InventoryListRow {
  recId: number;
  orgId: number;
  locId: number;
  compId: number;
  vin: string | null;
  stockNum: string | null;
  orgRecId: number | null;
  numPhotos: number | null;
  phyLocation: string | null;
  scOpen: number | null;
  numTestDrives: number | null;
  trkGood: boolean | null;
  saleStatus: string | null;
  code3: string | null;
  color: string | null;
  mainLocation: number | null;
  samItems: number | null;
  locTime: number | null;
  recTime: number | null;
  invTime: number | null;
  milesIn: string | null;
  shortName: string | null;
  invAlerts: number | null;
  invType: string | null;
  vehStatus: string | null;
  year: string | null;
  make: string | null;
  model: string | null;
  bgPrinted: boolean | null;
  purDate?: string | null;
  soldPrice?: number | null;
  ror?: number | null;
  realDown?: number | null;
  iopDate?: string | null;
  dateIn?: string | null;
  chgOffCat?: string | null;
  buyerName?: string | null;
  vsStatus?: string | null;
  saleDate?: string | null;
  liveOnline?: boolean | null;
  sugDown?: number | null;
  sugPrice?: number | null;
  totCost?: number | null;
  invDays?: number | null;
  purFrom?: string | null;
  saleType?: string | null;
  day15?: string | null;
  day21?: string | null;
  day30?: string | null;
  day45?: string | null;
  day60?: string | null;
  ymm?: string | null;
  titleOutD?: string | null;
  mvi?: string | null;
  colType?: string | null;
  soldDate?: string | null;
  titleStatus?: string | null;
}

export class InventoryVehicle {
  constructor(
    public recId: number,
    public orgId?: number,
    public locId?: number,
    public sugPrice?: number,
    public stockNum?: string,
    public sugTerms?: string,
    public sugPmt?: number,
    public sugPercent?: number,
    public sugDown?: number,
    public sugTerm?: number,
    public year?: string,
    public make?: string,
    public model?: string,
    public vin?: string,
    public color?: string,
    public bodyStyle?: string,
    public scOpen?: boolean,
    public alert1?: boolean,
    public alert2?: boolean,
    public alert3?: boolean,
    public alert4?: boolean,
    public alert5?: boolean,
    public alert6?: boolean,
    public alert5N?: string,
    public tempTagNum?: string,
    public tempTagIss?: string,
    public tempTagExp?: string,
    public milesOut?: string,
    public saleStatus?: string,
    public appRecId?: string
  ) {}
}

export interface GeneralInformation {
  recId: number;
  orgId: number;
  purDate: string;
  dateIn: string | null;
  invDate: string | null;
  purFrom: string;
  purVendId: number;
  buyerEmp: string;
  psi: string;
  purType: string;
  invType: string;
  phyLocation: string;
  extraKeys: boolean;
  milesIn: string;
  milesOut: string;
  exceedsLim: boolean;
  notActual: boolean;
  exempt: boolean;
  alert1: boolean;
  alert2: boolean;
  alert3: boolean;
  alert4: boolean;
  alert5: boolean;
  alert6: boolean;
  alert5N?: string;
  saleStatus: string;
  locDate?: string;
}

export interface TransferOption {
  shortName: string;
  recId: number;
}

export interface PhysicalLocationLog {
  date: string;
  text: string;
}

export interface VehicleCost {
  recId: number;
  purPrice: number;
  psiAmt?: number;
  psi: string;
  aucFee?: number;
  paidForDate: string;
  paidForNum: string;
  buyerFee?: number;
  dpack?: number;
  totExp: number;
  transport: number;
  totCost: number;
  vehCost: number;
  saleStatus: string;
}

export interface BuyersGuideOptions {
  // recId: number | null;
  compId: number | null;
  bgType: string | null;
  fullW: boolean | null;
  limited: boolean | null;
  perLabor: number | null; //decimal
  perParts: number | null; //decimal
  system1: string | null;
  duration1: string | null;
  seeFor: string | null;
  servCont: boolean | null;
  asIs: boolean | null;
  asIsL: string | null;
  war: boolean | null;
  bgForm: string | null;
}

export interface BuyersGuideUpdate {
  recId: number | null;
  asIs: boolean | null;
  asIsL: string | null;
  war: boolean | null;
  fullW: boolean | null;
  limited: boolean | null;
  perLabor: number | null;
  perParts: number | null;
  system1: string | null;
  duration1: string | null;
  servCont: boolean | null;
  seeFor: string | null;
  bgType: string | null;
}

export interface BuyersGuideComplete {
  bgComplete: BuyersGuideOptions | null;
}
export interface BuyersGuideCurrent {
  bgType: string;
  asIs: boolean;
  asIsL: string;
  war: boolean;
  fullW: boolean;
  limited: boolean;
  perLabor: number;
  perParts: number;
  system1: string;
  duration1: string;
  servCont: boolean;
  seeFor: string;
  recId: number;
  yrMakeModel: string;
  stockNum: string;
  bgPrnumbered: boolean;
  bgUpdated: boolean;
  orgId: number;
  locId: number;
  compId: number;
  orgRecId: number;
  vin: string;
  year: string;
  make: string;
  model: string;
  bgTimeStamp: Date;
  bgUserName: string;
  updateBy: number;
  bfFormRecId_Unsold: number;
  bfFormRecId_UnsoldSp: number;
  bgForm: string;
  bgFormSp: string;
  bgBack: string;
  bgBackSp: string;
  bgAsImp: string;
  state: string;
}

export interface VehicleDetails {
  recId: number;
  stockNum?: string;
  vin?: string;
  year?: string;
  make?: string;
  model?: string;
  trim?: string;
  bodyStyle?: string;
  vehType?: string;
  driveTrain?: string;
  transmission?: string;
  carTruck: boolean;
  fuelType?: string;
  color?: string;
  interior?: string;
  weight?: string;
  engine?: string;
  cylinders?: string;
  horsepower?: string;
  vehSize?: string;
  fuelCap?: string;
  engine_Size?: string;
  mpgCity?: string;
  mpgHwy?: string;
  mpgCombined?: string;
  saleStatus?: string;
  nonVeh?: boolean;
}

export interface SuggestedSalesInfo {
  recId: number;
  sugPrice?: number;
  msrp?: number;
  sugDown?: number;
  internetPrice?: number;
  sugPercent?: number;
  sugTerms?: string;
  sugPayment?: number;
  sugTerm?: number;
  priceSheetMemo?: string;
  book1: string;
  bookValue1: number;
}

export interface SoldVehicleInfo {
  vehRecId?: number;
  saleDate?: string | Date;
  buyerName?: string;
  salesPrice?: number;
  saleType?: string;
  salesperson?: string;
}

export interface TitleAndTagInformation {
  invRecId: number;
  saleStatus?: string;
  titleNum?: string;
  titleSt?: string;
  altsVehTitleIssueDate?: DateString;
  titleOAss?: number;
  titleInD?: DateString;
  titleOutD?: DateString;
  titleDateComplete?: DateString;
  titleOutTo?: string;
  titleNotes?: string;
  salvageTitle?: boolean;
  titlePCopy?: boolean;
  atCustodian?: boolean;
  titleVer?: boolean;
  titleVByName?: string;
  tDelivered?: boolean;
  tDelDate?: DateString;
  tDelTo?: string;
  tDelMethod?: string;
  tempTagNum?: string;
  tempTagIss?: DateString;
  tempTagExp?: DateString;
  tagNum?: string;
  tagState?: string;
  tagExp?: DateString;
  inspNum?: string;
  inspDate?: DateString;
  inspExp?: DateString;
}

export const InvCosPolOptions = {
  'Dealership Expense': 'E',
  Warranty: 'W',
  CP: 'P',
  'Customer Pays': 'C',
};

export interface InventoryExpense {
  recId: number | null;
  vehRecId?: number;
  orgId?: number;
  docLocalName: string;
  docFileType: string;
  docName: string;
  docFlag: boolean;
  stockNum: string;
  internal: boolean;
  paidTo: string;
  vendRecId?: number;
  poNum: string;
  labor: number;
  parts: number;
  other: number;
  invCosPol: keyof typeof InvCosPolOptions | string;
  compId?: number;
  expDate: string;
  expType: string;
  expFor: string;
  expAmt: number;
  vendorName: string;
  ckNum: string;
  payVia: string | null;
  custAmt: number;
  dealAmt: number;
}

export interface InventoryExpenseDocument {
  docUrl?: string;
  docSize?: number;
  docName?: string;
  docFileType?: string;
  docLocalName?: string;
}

export class CheckInItem {
  constructor(
    public recId?: number,
    public orgId?: number,
    public locationId?: number | null,
    public compId?: number | null,
    public vehRecId?: number,
    public sOrder?: number,
    public ckItem: string = '',
    public fix: boolean = false,
    public comments: string = '',
    public fixed: boolean = false,
    public fixedOn?: DateString | null,
    public fixedById?: number | null,
    public fixedByName?: string
  ) {}
}

export class OptionsItem {
  constructor(
    public orgId?: number,
    public locIdAfc?: number,
    public recId?: number,
    public compIdAfc?: number,
    public compIdSolutions?: number,
    public locIdSolutions?: number,
    public vehRecId?: number,
    public invId?: number,
    public timeUploaded?: DateString,
    public userRecId?: number,
    public sOrder?: number,
    public option: string = '',
    public verified: boolean = false,
    public display: boolean = false,
    public vin?: string,
    public compId?: number,
    public locId?: number
  ) {}
}

export class CurrentInspection {
  constructor(
    public recId?: number,
    public ckInBy?: number,
    public ckInDate?: string,
    public optBy?: number,
    public optDate?: string
  ) {}
}

export class VehicleInspector {
  constructor(public shortName?: string, public recId?: number) {}
}

export class CheckInSubmission {
  constructor(public vehRecid?: number, public ckInBy?: number, public ckInDate?: string) {}
}

export class OptionsSubmission {
  constructor(public vehRecid?: number, public optBy?: number, public optDate?: string) {}
}

export class PrintBuyersGuide {
  constructor(public invRecId?: number, public printSpanish: boolean = false) {}
}
