import { toast } from "react-toastify";
import dayjs from "dayjs";
import { SystemService } from "@/services/systemService";
import { ApiResponse } from "@/interfaces/Api";
import { IturanDeviceActivityResponse, IturanDeviceDetailResponse, IturanLocation, TypicalIturanResponse } from "@/interfaces/Gps/Ituran";
import { GpsHistoryItemDeprec } from "@/interfaces";
import { DateFormat } from "@/utils/helpers/general";

const ituranLocationToGpsHistoryItem = (ituranLocation: IturanLocation) => ({
    address: ituranLocation.address,
    odometer: ituranLocation.odometer,
    lastPing: ituranLocation.date,
    course: '', // Doesn't seem to exist for Ituran - maybe `heading` is degrees ...?
    speed: (ituranLocation.speed ?? '').toString(),
    latitude: ituranLocation.lat,
    longitude: ituranLocation.lon,
});

export function augmentIturanCommands(cls: typeof SystemService): void {

    cls.prototype.getIturanDeviceActivity = async function (inventoryRecId: number) {
    try {
        const { data } = await this.axios.get<ApiResponse<IturanDeviceActivityResponse>>(
        "/System/GetIturanDeviceActivity",
        { params: { inventoryRecId } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to get Ituran device activity");
        throw e;
    }
    };

    cls.prototype.getIturanGpsHistory = async function (inventoryRecId: number) {
    const ituranActivityRes = await this.getIturanDeviceActivity(inventoryRecId);

    const standardGpsHistory: GpsHistoryItemDeprec[] = ituranActivityRes.deviceActivities.map((a: any) => {
        const { location } = a;
        return {
        address: location.address,
        odometer: location.odometer,
        lastPing: location.date,
        course: "",
        speed: (location.speed ?? "").toString(),
        latitude: location.lat,
        longitude: location.lon,
        locateType: "-",
        };
    });

    return standardGpsHistory;
    };

    cls.prototype.getIturanLastLocate = async function (inventoryRecId: number) {
    try {
        const ituranActivityRes = await this.getIturanDeviceActivity(inventoryRecId);
        if (!ituranActivityRes || !ituranActivityRes.deviceActivities) return undefined;

        const latestActivity = ituranActivityRes.deviceActivities.find((activity: any) => {
        const { location } = activity || {};
        return location.lat && location.lon && location.address && location.date;
        });

        if (!latestActivity) return undefined;

        const standardLastLocate: GpsHistoryItemDeprec = {
        address: latestActivity.location.address,
        odometer: latestActivity.location.odometer,
        lastPing: dayjs(latestActivity.location.date).format(DateFormat.SimpleDateTime),
        course: "",
        speed: (latestActivity.location.speed ?? "").toString(),
        latitude: latestActivity.location.lat,
        longitude: latestActivity.location.lon,
        };

        return standardLastLocate;
    } catch (e) {
        console.error(e);
        throw e;
    }
    };

    cls.prototype.sendIturanLocateCommand = async function sendIturanLocateCommand(inventoryRecId: number) {
        try {
        const { data } = await this.axios.post<ApiResponse<TypicalIturanResponse>>(
            '/System/SendIturanLocateCommand',
            {},
            {
            params: { inventoryRecId },
            }
        );
        if (!data.data?.success) throw new Error();
        return ituranLocationToGpsHistoryItem(data.data!.location);
        } catch (e) {
        console.error(e);
        toast.error('Unable to send Ituran locate commmand');
        throw e;
        }
    };

    cls.prototype.getIturanDeviceDetail = async function getIturanDeviceDetail(inventoryRecId: number) {
        try {
        const { data } = await this.axios.get<ApiResponse<IturanDeviceDetailResponse>>(
            '/System/GetIturanDeviceDetail',
            {
            params: { inventoryRecId },
            }
        );
        return data.data!;
        } catch (e) {
        console.error(e);
        toast.error('Unable to get Ituran device details');
        throw e;
        }
    };

    cls.prototype.sendIturanEnableDisable = async function sendIturanEnableDisable(inventoryRecId: number, enableVehicle: boolean) {
        try {
        const { data } = await this.axios.post<ApiResponse<TypicalIturanResponse>>(
            '/System/SendIturanEnableDisableVehicle',
            {},
            {
            params: { inventoryRecId, enableVehicle },
            }
        );
        if (!data.data?.success) throw new Error();
        } catch (e) {
        console.error(e);
        toast.error(`Unable to ${enableVehicle ? 'enable' : 'disable'} vehicle`);
        throw e;
        }
    };

    cls.prototype.turnOnIturanCollectionTool1 = async function turnOnIturanCollectionTool1(inventoryRecId: number) {
        try {
        const { data } = await this.axios.post<ApiResponse<TypicalIturanResponse>>(
            '/System/TurnOnIturanCollectionTool1',
            {},
            {
            params: { inventoryRecId },
            }
        );
        if (!data.data?.success) throw new Error();
        } catch (e) {
        console.error(e);
        toast.error('Unable to turn on Ituran collection tool #1');
        throw e;
        }
    };

    cls.prototype.turnOnIturanCollectionTool2 = async function turnOnIturanCollectionTool2(inventoryRecId: number) {
        try {
        const { data } = await this.axios.post<ApiResponse<TypicalIturanResponse>>(
            '/System/TurnOnIturanCollectionTool2',
            {},
            {
            params: { inventoryRecId },
            }
        );
        if (!data.data?.success) throw new Error();
        } catch (e) {
        console.error(e);
        toast.error('Unable to turn on Ituran collection tool #2');
        throw e;
        }
    };

    cls.prototype.turnOnIturanCollectionTool3 = async function turnOnIturanCollectionTool3(inventoryRecId: number) {
        try {
        const { data } = await this.axios.post<ApiResponse<TypicalIturanResponse>>(
            '/System/TurnOnIturanCollectionTool3',
            {},
            {
            params: { inventoryRecId },
            }
        );
        if (!data.data?.success) throw new Error();
        } catch (e) {
        console.error(e);
        toast.error('Unable to turn on Ituran collection tool #3');
        throw e;
        }
    };

    cls.prototype.turnOffIturanCollectionTool = async function turnOffIturanCollectionTool(inventoryRecId: number) {
        try {
        const { data } = await this.axios.post<ApiResponse<TypicalIturanResponse>>(
            '/System/TurnOffIturanCollectionTool',
            {},
            {
            params: { inventoryRecId },
            }
        );
        if (!data.data?.success) throw new Error();
        } catch (e) {
        console.error(e);
        toast.error('Unable to turn off Ituran collection tool');
        throw e;
        }
    };
}