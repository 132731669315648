import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@/components/button/Button";
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import { paymentService } from "@/services/paymentService";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { type SavedPmtMethodRes, RecurringStatus } from "@/interfaces/CreditCard";
// style
import styles from "./SavedPaymentMethods.module.scss";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Controller } from "react-hook-form";
import { DropdownInput, TextInput } from "@/components";
import { PhoneInput } from "@/components";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import SavedPaymentProvider, { useSavedPaymentsCtx } from "./SavedPaymentProvider";
import { DeactivateMpdReq, SaveMpdForm, SaveMpdReq, SendAuthReq } from "@/interfaces/payment";
import dayjs, { Dayjs } from "dayjs";
import { Modal } from "@/components/modals/Modal";
import { FileInput } from "@/components/inputs/fileInput/FileInput";
import { Spacer } from "@/components/spacer/Spacer";
import { systemService } from "@/services/systemService";
import useEmailFeatureFlag from "@/env/useEmailFeatureFlag";
import CommonModal from "@/mui/components/CommonModal";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import PmtFormProvider, { usePmtFormCtx } from "../PaymentForm/PmtFormProvider";
import SavedPaymentForm from "./SavedPaymentForm";
import { Icons } from "@/components/icons";

const modalSx: Grid2Props | undefined = {
  display: "flex",
  p: 3,
  overflow: "hidden",
  gap: 2,
  width: "80%",
  height: "60%",
};

function formatPhoneNumber(phoneNumber: string): string {
  // Use a regular expression to remove all non-numeric characters
  return phoneNumber.replace(/\D/g, "");
}

const SavedPaymentMethodForm: FC = () => {
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  const userInfo = accountInformation?.buyer ?? {};
  const appRecId = useAccountSelector((s) => getRouteParamNum(s.accountInformation?.appRecId));
  const savedPaymentRows = useSavedPaymentsCtx((s) => s.savedPaymentRows);
  const activeCard = useSavedPaymentsCtx((s) => s.activeCard);
  const setActiveCard = useSavedPaymentsCtx((s) => s.setActiveCard);
  const savedPaymentMethods = useSavedPaymentsCtx((s) => s.savedPaymentMethods);
  const loadingSavedPaymentMethods = useSavedPaymentsCtx((s) => s.savedPaymentMethods.isLoading);
  const cliqEnabled = usePmtFormCtx((s) => s.paymentProviders.value?.cliqEnabled);
  const [uploadDocModalOpen, setUploadDocModalOpen] = useState(false);
  const [deleteCardModalOpen, setDeleteCardModalOpen] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [fileDescriptionError, setFileDescriptionError] = useState(false);
  const [authFormDesc, setAuthFormDesc] = useState("");
  const [authFormFile, setAuthFormFile] = useState<File | null>(null);
  const [uploadingAuthFormFile, setuploadingAuthFormFile] = useState(false);
  const [printing, setPrinting] = useState(false); // will be used
  const today = dayjs().startOf("day");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    mpdId: string;
    recurringStatus: RecurringStatus;
  } | null>(null);
  const addingNewCard = useSavedPaymentsCtx((s) => s.addingNewCard);
  const setAddingNewCard = useSavedPaymentsCtx((s) => s.setAddingNewCard);
  const { control, handleSubmit, setValue, getValues } = useSavedPaymentsCtx(
    (s) => s.savedPaymentForm
  );
  const receiptUrl = usePmtFormCtx((s) => s.receiptUrl);

  const { StartDate_pending, recurringIntervalRecIdPending } = useSavedPaymentsCtx(
    (
      s //recurringAuthRequestSentUtc
    ) => s.savedPaymentForm.watch()
  );

  const isPending = activeCard
    ? activeCard.recurringStatusPending === "Active" ||
      (activeCard.recurringStatus === RecurringStatus.NotSetup &&
        activeCard.recurringStatusPending === null)
    : false;
  const isCurrent = activeCard ? activeCard.recurringStatus === RecurringStatus.Active : false;
  const isActive = activeCard
    ? activeCard.recurringStatus === RecurringStatus.NotSetup &&
      activeCard.recurringStatusPending === null
    : false;

  const cardStatus = activeCard
    ? activeCard.recurringStatus === RecurringStatus.NotSetup
      ? "Inactive"
      : activeCard.recurringStatus
    : null;
  const isPendingButtons = cardStatus === RecurringStatus.Pending;

  const recurringIntervalValues = {
    Weekly: 1,
    "Bi-Weekly": 2,
    "Semi-Monthly": 3,
    Monthly: 4,
  };

  type IntervalKey = keyof typeof recurringIntervalValues;

  const veiwAuthForm = async () => {
    setPrinting(true);
    try {
      let blobName;
      let fileUrl;

      if (!activeCard?.orgId) {
        toast.error("Invalid orgId.");
        return;
      }
      if (activeCard?.docName) {
        // Use fileNameCloud if available
        blobName = activeCard.docName;
        fileUrl = await systemService.getBlobReadUrl(activeCard?.orgId, blobName);
      } else if (activeCard?.docUrl) {
        // Fallback to fileURL if fileNameCloud is empty
        fileUrl = activeCard.docUrl;
      } else {
        throw new Error("No valid document reference found.");
      }

      // Open the determined URL
      window.open(fileUrl);
    } catch (error) {
      console.error(error);
      toast.error("Unable to print document");
    } finally {
      setPrinting(false);
    }
  };

  const handlePrintAuthForm = async () => {
    if (!activeCard?.mpdId) {
      toast.error("No active card selected.");
      return;
    }

    try {
      const authFormUrl = await paymentService.printAuthFormDoc({
        mpdId: Number(activeCard.recId),
        formId: 455,
      });
      window.open(authFormUrl);
    } catch (error: any) {
      console.error(error);
      toast.error("Failed to print auth form.");
    }
  };

  const handleDelete = (
    clickedPayment: {
      mpdId: string;
      recurringStatus: RecurringStatus;
    } | null
  ) => {
    if (
      !clickedPayment ||
      !clickedPayment.mpdId ||
      clickedPayment.recurringStatus === RecurringStatus.Active ||
      !clickedPayment.recurringStatus
    ) {
      toast.error("Cannot delete payment method used for recurring payments");
      setDeleteCardModalOpen(false);
      return;
    }
    if (typeof clickedPayment.mpdId !== "string") {
      toast.error("Invalid payment method ID.");
      setDeleteCardModalOpen(false);
      return;
    }
    setSelectedPaymentMethod(clickedPayment);
    setDeleteCardModalOpen(true);
  };

  const handleDeleteSubmit = () => {
    if (typeof appRecId !== "number") {
      toast.error("Account information is missing or invalid.");
      setDeleteCardModalOpen(false);
      return;
    }

    if (!selectedPaymentMethod?.mpdId) {
      toast.error("Payment method ID is missing or invalid.");
      setDeleteCardModalOpen(false);
      return;
    }
    paymentService
      .deleteSavedPaymentMethods(appRecId, selectedPaymentMethod.mpdId)
      .then(() => {
        toast.success("Payment method deleted successfully.");
        savedPaymentMethods.load();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to delete payment method.");
      })
      .finally(() => {
        setSelectedPaymentMethod(null);
        setDeleteCardModalOpen(false);
      });
  };

  const calculateDayDate = (schedule: number, startDate: Dayjs) => {
    if (schedule === 1 || schedule === 2) {
      // Weekly or Bi-Weekly
      const dateString = startDate.format("ddd");
      if (dateString === "Thu") {
        return "Thur";
      }
      return dateString;
    } else if (schedule === 3) {
      // Semi-Monthly
      let dom1 = startDate.date();
      let dom2;
      if (dom1 > 15) {
        dom2 = dom1 - 15;
      } else {
        dom2 = dom1 + 15;
      }
      return `${dom1},${dom2}`;
    } else {
      // Monthly
      return `${startDate.date()}`;
    }
  };

  const handleCardClick = (card: SavedPmtMethodRes) => {
    setActiveCard(card);
    setValue("amtPending", card.amt ?? card.amtPending ?? 0);
    setValue(
      "StartDate_pending",
      dayjs(
        card.startDatePending === "" ? card.startDate : card.startDatePending,
        "M/D/YYYY hh:mm:ssA"
      ) ?? dayjs()
    );
    setValue(
      "recurringIntervalRecIdPending",
      card.recurringIntervalRecId ?? card.recurringIntervalRecIdPending ?? 0
    );
    setAddingNewCard(false);
  };

  const handleSubmitForm = async (form: SaveMpdForm) => {
    if (!activeCard) {
      toast.error("There is an error with the selected card.");
      return;
    }
    if (!activeCard.recId || !userInfo?.cellPhone) {
      toast.error("Selected cards rec Id or phone number are null.");
    }
    const reqBody: SaveMpdReq = {
      ...form,
      recurringStatusPending: "Active",
      recurringStatus: "Pending",
      recId: activeCard.recId!,
      recurringAuthCell: formatPhoneNumber(userInfo?.cellPhone) ?? "0000000000",
      email: userInfo?.email ?? "",
      startDatePending: dayjs(form.StartDate_pending).format("YYYY-MM-DD"),
      dayDatePending: calculateDayDate(recurringIntervalRecIdPending, form.StartDate_pending),
      // recurringAuthRequestSentUtc: dayjs(),
      convFeePending: 0,
    };

    const res = await paymentService.updateMpdPending(reqBody);
    if (res === true) {
      toast.success("Successfully submitted recurring payment authorization request.");
      savedPaymentMethods.load();
    }
  };

  const handleDeactivatePayment = async () => {
    if (!activeCard) {
      toast.error("There is an error with the selected card.");
      return;
    }
    if (!activeCard.recId || !userInfo?.cellPhone) {
      toast.error("Selected cards rec Id or phone number are null.");
      return;
    }
    const reqBody: DeactivateMpdReq = {
      recId: activeCard.recId!,
      recurringAuthCell: formatPhoneNumber(userInfo?.cellPhone) ?? "0000000000",
    };

    const res = await paymentService.deactivateRecurringPayment(reqBody);
    if (res === true) {
      toast.success("Successfully deactivated recurring payment.");
      savedPaymentMethods.load();
    }
    useEffect(() => {
      const currentValue = getValues("recurringIntervalRecIdPending");
      if (!currentValue) {
        setValue("recurringIntervalRecIdPending", recurringIntervalValues["Weekly"]);
      }
    }, [setValue, getValues]);
  };

  const sendEcomAuth = async () => {
    if (!activeCard?.compId || !activeCard?.appRecId) {
      toast.error("Invalid company id or application record id");
      return;
    }
    const recurringIntervalMap = Object.entries(recurringIntervalValues).reduce(
      (acc, [key, value]) => {
        acc[value] = key;
        return acc;
      },
      {} as { [key: number]: string }
    );

    // Get the interval name
    const intervalId = activeCard?.recurringIntervalRecIdPending;
    const intervalName = intervalId ? recurringIntervalMap[intervalId] : null;

    const reqBody: SendAuthReq = {
      recId: activeCard?.recId as number,
      compId: activeCard?.compId,
      appRecId: activeCard?.appRecId,
      recurringAuthCell: formatPhoneNumber(userInfo?.cellPhone) ?? "0000000000",
      authMessage: `${activeCard?.custName} authorization required: $${
        activeCard?.amtPending
      } ${intervalName} beginning ${dayjs(StartDate_pending).format("YYYY-MM-DD")} on card ending ${
        activeCard?.last4
      }? Reply APPROVE to approve, or DECLINE`,
    };
    try {
      const res = await paymentService.recurringAuthSend(reqBody);
      if (res === true) {
        toast.success("Authorization text sent for recurring payment request.");
        savedPaymentMethods.load();
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
    return;
  };

  const uploadAuthFormFile = async () => {
    let hasErrors = false;

    if (!authFormFile) {
      setFileInputError(true);
      hasErrors = true;
    }
    if (!authFormDesc) {
      setFileDescriptionError(true);
      hasErrors = true;
    }

    if (hasErrors) return;

    const payload = {
      file: authFormFile!,
      orgId: activeCard?.orgId!,
      compId: activeCard?.compId!,
      locId: activeCard?.locId!,
      appRecId: activeCard?.appRecId ?? 0,
      mpdRecId: activeCard?.recId ?? 0,
      fileDesc: authFormDesc,
    };

    setuploadingAuthFormFile(true);
    try {
      const fileUpload = await paymentService.uploadAuthDoc(payload);
      // await loadScannedDocs(); // Refresh the list after upload
      setAuthFormFile(null);
      setAuthFormDesc("");
      setUploadDocModalOpen(false);
    } finally {
      setuploadingAuthFormFile(false);
      savedPaymentMethods.load();
    }
  };

  const handleFileDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileDescriptionError(false);
    setAuthFormDesc(e.target.value);
  };

  const handleAuthDocInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileInputError(false);
      setAuthFormFile(file);
    }
  };

  const columns: GridColDef[] = [
    { field: "acctType", headerName: "Type", flex: 1 },
    { field: "custName", headerName: "Name", flex: 1 },
    { field: "cardProcessor", headerName: "Processor", flex: 1 },
    { field: "last4", headerName: "Last 4", flex: 1 },
    {
      field: "recurringStatus",
      headerName: "Recurring",
      flex: 1,
      renderCell: (params) => (
        <div>{params.value === RecurringStatus.NotSetup ? "Inactive" : params.value}</div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className={styles.tdButton}>
          <Button
            themeColor="primary"
            secondary={true}
            label="Edit"
            fullWidth={false}
            onClick={() => handleCardClick(params.row)}
          />
          <Button
            themeColor="error"
            label={<Icons.Trash style={{ color: "white", width: "14px", height: "14px" }} />}
            disabled={params.row.recurringStatus === "Active"}
            fullWidth={false}
            onClick={() => {
              handleDelete({
                mpdId: params.row.mpdId,
                recurringStatus: params.row.recurringStatus,
              });
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    receiptUrl && setAddingNewCard(false);
  }, [receiptUrl]);

  useEffect(() => {
    const currentValue = getValues("recurringIntervalRecIdPending");
    if (!currentValue) {
      setValue("recurringIntervalRecIdPending", recurringIntervalValues["Weekly"]);
    }
  }, [setValue, getValues]);

  return (
    <AccountsMainPanel navBarTitle="">
      <div
        style={{
          width: "100%",
          padding: "1rem",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div
          className={styles.headerRow}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <h2>Saved Payment Methods</h2>
          {cliqEnabled && (
            <Button
              themeColor="primary"
              label="Add New"
              fullWidth={false}
              onClick={() => {
                if (savedPaymentRows.length >= 5) {
                  toast.error(
                    "You have reached the maximum number of saved cards. Please delete some before adding more."
                  );
                  return;
                }
                setAddingNewCard(true);
                setActiveCard(null);
              }}
            />
          )}
        </div>
        <DataGrid
          rows={savedPaymentRows.slice(0, 5)} // Display only the first 5 cards
          columns={columns}
          pagination
          pageSizeOptions={[5]}
          autoHeight
          loading={loadingSavedPaymentMethods}
          getRowId={(row) => row.mpdId || `unknown-${row.custName}-${row.last4}`}
          disableRowSelectionOnClick
          onRowClick={(params) => {
            handleCardClick(params.row);
          }}
        />
        {/* Existing Content for Active Card and Adding New Card */}
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          {activeCard && (
            <div
              style={{
                width: "100%",
                marginTop: "2rem",
                backgroundColor: "#f5f5f5",
                padding: "1rem",
              }}
            >
              <h3>
                Card Details - {"Card Ending in :"} {activeCard.last4} (
                {activeCard.recurringStatus === RecurringStatus.NotSetup
                  ? "Inactive"
                  : activeCard.recurringStatus}
                )
              </h3>
              <div
                className={styles.cardActions}
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  type="button"
                  disabled={!isPendingButtons}
                  themeColor="primary"
                  label="Send Auth. Via Ecom/Text"
                  fullWidth={false}
                  onClick={() => sendEcomAuth()}
                />
                <Button
                  type="button"
                  disabled={!isPendingButtons}
                  themeColor="primary"
                  label="Print Auth. Form"
                  fullWidth={false}
                  onClick={handlePrintAuthForm}
                />
                <Button
                  type="button"
                  disabled={!isPendingButtons}
                  themeColor="primary"
                  label="Upload Auth. Form"
                  fullWidth={false}
                  onClick={() => {
                    setUploadDocModalOpen(true);
                  }}
                />
                <Button
                  type="button"
                  disabled={cardStatus !== "Active"}
                  themeColor="primary"
                  label="View Auth. Form"
                  fullWidth={false}
                  onClick={veiwAuthForm}
                />
                {cardStatus === "Pending" && (
                  <Button
                    type="button"
                    themeColor="error"
                    label="Cancel"
                    fullWidth={false}
                    onClick={() => handleDeactivatePayment()}
                  />
                )}
              </div>
              {/* Updated cardAdditionalInfo section */}
              <div
                className={styles.cardAdditionalInfo}
                style={{
                  marginTop: "2rem",
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "2rem",
                }}
              >
                {/* Cardholder Details */}
                <div>
                  <h4>Cardholder Details</h4>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "1rem",
                    }}
                  >
                    <TextInput label="First Name" value={activeCard.fName} disabled />
                    <TextInput label="Last Name" value={activeCard.lName} disabled />
                    <TextInput label="Account Email" value={userInfo?.email ?? ""} disabled />
                    <PhoneInput
                      label="Account Cell Phone"
                      value={userInfo?.cellPhone ?? "(000) 000-0000"}
                      disabled
                    />
                  </div>
                </div>
                {/* Recurring Details */}
                <div>
                  {isCurrent && <h4>Current Recurring Settings</h4>}
                  {isPending && <h4>Pending Recurring Settings</h4>}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "1rem",
                    }}
                  >
                    <Controller
                      name="amtPending"
                      control={control}
                      disabled={!isActive}
                      rules={{ required: "Payment Amount is required" }}
                      render={({ field, fieldState }) => (
                        <CurrencyInput
                          label="Payment Amount*"
                          {...field}
                          errors={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="recurringIntervalRecIdPending"
                      control={control}
                      disabled={!isActive}
                      rules={{ required: "Schedule is required" }}
                      render={({ field, fieldState }) => (
                        <DropdownInput
                          {...field}
                          // Set value by finding the corresponding key based on field.value
                          value={
                            field.value
                              ? Object.keys(recurringIntervalValues).find(
                                  (key) => recurringIntervalValues[key as IntervalKey] === field.value
                                )
                              : Object.keys(recurringIntervalValues)[0] // Fallback to first option
                          }
                          // Set data to the keys of the recurringIntervalValues object
                          data={Object.keys(recurringIntervalValues)}
                          onChange={(event) => {
                            return (
                              // Cast event.value to IntervalKey to use it safely with recurringIntervalValues
                              field.onChange(recurringIntervalValues[event.value as IntervalKey])
                            );
                          }}
                          label="Schedule*"
                          containerStyle={{ width: "100%" }}
                          errors={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="StartDate_pending"
                      control={control}
                      rules={{
                        required: "Start Date is required",
                        validate: (value: Dayjs) =>
                          dayjs(value).isAfter(today, "day") || "Start Date must be a future date",
                      }}
                      disabled={!isActive}
                      render={({ field, fieldState: { error } }) => (
                        <DateInput
                          label="Start Date*"
                          {...field}
                          minDate={today.add(1, "day")}
                          errors={!!error}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* @todo - break this out into separate component */}
              {/* Buttons under the Recurring Details section */}
              <div
                className={styles.actionButtons}
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  type="button"
                  themeColor="primary"
                  label="Delete Saved Card"
                  disabled={cardStatus === "Active"}
                  fullWidth={false}
                  onClick={() => {
                    if (activeCard && activeCard.mpdId && activeCard.recurringStatus) {
                      handleDelete({
                        mpdId: activeCard.mpdId,
                        recurringStatus: activeCard.recurringStatus,
                      });
                    }
                  }}
                />

                <Button
                  type="button"
                  themeColor="primary"
                  label="Inactivate"
                  disabled={cardStatus === "Inactive"}
                  fullWidth={false}
                  onClick={() => handleDeactivatePayment()}
                />
                <Button
                  type="submit"
                  themeColor="primary"
                  label="Submit"
                  disabled={cardStatus !== "Inactive"}
                  fullWidth={false}
                />
                <Button
                  type="button"
                  themeColor="primary"
                  label="Cancel"
                  disabled={cardStatus !== "Inactive"}
                  fullWidth={false}
                  onClick={() => {
                    /* Handle cancel logic here */
                  }}
                />
              </div>
            </div>
          )}
          {/* @todo - add component for add new card here */}

          <CommonModal
            open={addingNewCard}
            onClose={() => setAddingNewCard(false)}
            containerProps={modalSx}
          >
            <h2
              style={{
                marginBottom: "16px",
                width: "100%",
                textAlign: "center",
                boxSizing: "border-box",
              }}
            >
              Authorize New Card
            </h2>
            <Grid
              container
              direction="row"
              gap={2}
              paddingRight={1}
              paddingLeft={1}
              sx={{
                overflowX: "scroll",
                overflowY: "scroll",
                width: "100%",
              }}
            >
              <SavedPaymentForm />
            </Grid>
          </CommonModal>

          {/* @todo - break this out into separate component */}
          {uploadDocModalOpen && (
            <Modal
              isOpen={true}
              onCloseButtonClick={() => setUploadDocModalOpen(false)}
              closeButton
              centerModal
              title="Upload Auth. Form"
              panelStyle={{ width: 600 }}
            >
              <div>
                <Spacer size={5} />
                <FileInput
                  onChange={handleAuthDocInputChange}
                  fileName={authFormFile?.name}
                  errors={fileInputError}
                />
                <Spacer size={20} />
                <TextInput
                  required
                  label="File Description"
                  value={authFormDesc}
                  horizontalLabel={false}
                  onChange={handleFileDescriptionChange}
                  errors={fileDescriptionError}
                />
                <Spacer size={20} />
                <div style={{ width: 100, marginLeft: "auto" }}>
                  <Button
                    type="button"
                    label="Submit"
                    loading={uploadingAuthFormFile}
                    onClick={uploadAuthFormFile}
                  />
                </div>
              </div>
            </Modal>
          )}
          {/* @todo - break this out into separate component */}
          {deleteCardModalOpen && (
            <Modal
              isOpen={true}
              centerModal
              title={`Delete saved card ending in ${activeCard?.last4}`}
            >
              <h3>{"Are you sure you want to delete this saved card?"}</h3>
              <div
                style={{ display: "flex", flexDirection: "row", gap: 50, justifyContent: "center" }}
              >
                <Button
                  type="button"
                  label="Cancel"
                  onClick={() => setDeleteCardModalOpen(false)}
                />
                <Button
                  type="button"
                  label="Delete Saved Card"
                  themeColor="warning"
                  onClick={handleDeleteSubmit}
                />
              </div>
            </Modal>
          )}
        </form>
      </div>
    </AccountsMainPanel>
  );
};

const SavedPaymentMethods: FC = () => {
  const emailFeatureFlag = useEmailFeatureFlag();

  return (
    <SavedPaymentProvider>
      <PmtFormProvider>
        <SavedPaymentMethodForm />
      </PmtFormProvider>
    </SavedPaymentProvider>
  );
};

export default SavedPaymentMethods;
