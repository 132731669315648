import { AccountDb, claimStatusMapping, laStatusMapping, recTypeMapping, saleTypeMapping, spStatusMapping } from './interfaces';

/**
 * @todo all fields with exhaustive values (i.e. categories) or\
 *   comparative values (i.e. numbers, dates, etc.) should be filterable.
 * @todo these values should be set on the backend and passed in thru swagger or page-load, as they currently require manual updating on the frontend, which is error prone and takes time.
 * @todo they should be a union of all of the possible filters applied to all subviews.
 */
export const allowedFilterFields = [
  'saleType',
  'spStatus',
  'dateSold',
  'chgOffDate',
  'status',
  'recurringStatus',
  'insuranceExpires',
  'claimStatus',
] as const;
// Constrain values to fields of the `account` interface
export type AllowedFilterKey = keyof Pick<AccountDb, (typeof allowedFilterFields)[number]>;

export const allowedCategoryFilterFields = ['saleType', 'spStatus', 'status','recurringStatus','claimStatus'] as const;
export type AllowedCategoryFilterKey = keyof Pick<
  AccountDb,
  (typeof allowedCategoryFilterFields)[number]
>;
export const allowedCategoryFiltersMap: Map<
  AllowedCategoryFilterKey,
  { label: string; optionsMap: Map<string, { value: string; label: string }> }
> = new Map<
  AllowedCategoryFilterKey,
  { label: string; optionsMap: Map<string, { value: string; label: string }> }
>([
  ['saleType', { label: 'Sale Type', optionsMap: saleTypeMapping }],
  ['spStatus', { label: 'Type', optionsMap: spStatusMapping }],
  ['status', { label: 'Status', optionsMap: laStatusMapping }],
  ['recurringStatus',   { label: 'Recurring Status', optionsMap: recTypeMapping }],
  ['claimStatus', { label: 'Claim Status', optionsMap: claimStatusMapping }],
]);

/**
 * @todo all fields with exhaustive values (i.e. categories) or\
 *   comparative values (i.e. numbers, dates, etc.) should be sortable.
 * @todo these values should be set on the backend and passed in thru swagger or page-load, as they currently require manual updating on the frontend, which is error prone and takes time.
 * @todo they should be a union of all of the possible sorts applied to all subviews.
 */
export const allowedSortFields = [
  'stockNum',
  'gpsCode',
  'buyers',
  'vehicle',
  'saleType',
  'dateSold',
  'amtDue',
  'daysLate',
  'sc',
  'shortName',
  'iopDate',
  'iopSaleDate',
  'code3',
  'residual',
  'status',
  'spStatus',
  'lastPmtPaid',
  'chgOffDate',
  'chgOffCat',
  'assignment',
  'daysLate',
] as const;
// Constrain values to fields of the `account` interface
export type AllowedSortKey = keyof Pick<AccountDb, (typeof allowedFilterFields)[number]>;
