import { FC } from 'react';
// kendo
import { Button } from '@/components/button/Button';
import { Icons } from '@/components/icons';
import { SectionHeader } from '@components/sectionHeader/SectionHeader';
// state
import { useSalesSelector } from '@/features/Sales/salesSlice';
// style
import styles from './Verification.module.scss';

/** @deprecated needs refactor */
export const VerificationItem: FC<{ goBackClick: () => void; Form: React.ReactNode }> = ({
  goBackClick,
  Form,
}) => {
  const verificationListItem = useSalesSelector((s) => s.verificationListItem);
  const saleData = useSalesSelector((s) => s.saleData);

  const verifiedList = [
    { isVerified: verificationListItem?.verifiedAddress, name: 'Address' },
    { isVerified: verificationListItem?.verifiedPhone, name: 'Phone' },
    { isVerified: verificationListItem?.verifiedEmployer, name: 'Employment' },
    { isVerified: verificationListItem?.verifiedContact, name: 'Call In Future' },
  ];
  const isVerified = verifiedList.filter((item) => item.isVerified);
  const isNotVerified = verifiedList.filter((item) => !item.isVerified);

  return (
    <div className={styles.verificationContainer}>
      <header
        className={styles.headerContainer}
        style={{ justifyContent: 'flex-start', gap: '17px' }}
      >
        <Button fillMode="flat" label="< Go Back" onClick={goBackClick} />
        <h2 className={styles.header}>
          Verification For -{' '}
          {verificationListItem?.contactFor === 'Co-Buyer'
            ? saleData.coBuyer?.b_FIRST + ' ' + saleData.coBuyer?.b_LAST
            : saleData.buyer?.b_FIRST + ' ' + saleData.buyer?.b_LAST}
        </h2>
      </header>
      <main className={styles.body} style={{overflow: "scroll"}}>
        <section className={styles.section}>
          <SectionHeader title="Verification Status" />
          <div className={styles.verifiedRow}>
            {isVerified.length !== 0 && (
              <div className={styles.row}>
                <Icons.FilledCheckmark />
                <p className={styles.verifiedText}>
                  Verified{' '}
                  {isVerified.map(
                    (item, index) => `${item.name}${index === isVerified.length - 1 ? '' : ', '}`
                  )}
                </p>
              </div>
            )}
            {isNotVerified.length !== 0 && (
              <div className={styles.row}>
                <Icons.XFilled />
                <p className={styles.verifiedText}>
                  {isNotVerified.map(
                    (item, index) => `${item.name}${index === isNotVerified.length - 1 ? '' : ', '}`
                  )}{' '}
                  Not Verified
                </p>
              </div>
            )}
          </div>
        </section>
        {Form}
      </main>
    </div>
  );
};
