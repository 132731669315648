import { FC, useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// components
import WsFormHeader from "./WsFormHeader";
import WholesaleMgmtTab from "./WholesaleMgmtTab";
import VehicleTab from "./VehicleTab";
import DocumentsTab from "./DocumentsTab";
import WsPaymentsView from "./WsPaymentsView";
import WsDetailHeader from "./WsDetailHeader";
import BackLink from "@/mui/components/buttons/BackLink";
// state
import { useSalesDetailCtx } from "../SalesDetailProvider";
import WholesaleViewProvider, { useWholesaleViewCtx } from "./WholesaleViewProvider";
import NavSidebar from "../NavSidebar";
import Header from "../Header";
import { ICON_GREY } from "@/mui/theme/colors";

const WholesalesFormLayout: FC = () => {
  const appRecId = useSalesDetailCtx((s) => s.appRecId);
  const setHeaderTitle = useSalesDetailCtx((s) => s.setHeaderTitle);
  const wholesale = useWholesaleViewCtx((s) => s.wholesale);

  // Initialize form on first render and anytime page changes
  useEffect(() => {
    setHeaderTitle(<WsDetailHeader />);

    // Cleanup: reset form states and tab state
    return () => {
      wholesale.setValue(null);
    };
  }, [appRecId]);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      overflow="hidden"
      // @todo Apply `svg` CSS rule to correct scope
      sx={{ flex: 1, p: 4, pt: 0, width: "100%", "&* svg": { color: ICON_GREY } }}
    >
      <BackLink />
      {/* @todo header should be managed individually, by each router-component */}
      <Grid sx={{ height: "64px", flexShrink: 0 , zIndex: 3}}>
        <Header />
      </Grid>
      {/* Body */}
      <Grid
        container
        direction="row"
        flex={1}
        sx={{ flexWrap: "nowrap", overflowX: "hidden", overflowY: "hidden" }}
      >
        <NavSidebar />
        <Grid container direction="column" flex={1} overflow="hidden" wrap="nowrap" pt={2}>
          <WsFormHeader />
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
  );
};

/** @note Place provider in right scope to prefetch data for 'Payment Summary' detail-view
 * On success:
 * 1. Nav to WS form view
 * 1. Load WS data
 * @todo add handleSuccess
 */
const WholesaleView: FC = () => (
  <WholesaleViewProvider>
    <Routes>
      <Route element={<WholesalesFormLayout />}>
        {/* Nav-tab routes */}
        <Route path="wholesale-management/*">
          <Route path="" element={<WholesaleMgmtTab />} />

          {/* "Take a payment" routes */}
          <Route path="payments/*" element={<WsPaymentsView />} />

          <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
        </Route>
        {/* <Route path="vehicle" element={<VehicleTab />} /> */}
        <Route path="documents" element={<DocumentsTab />} />
        {/* Fallbacks */}
        <Route path="/" element={<Navigate to="wholesale-management/" replace={true} />} />
        <Route path="*" element={<Navigate to="wholesale-management/" replace={true} />} />
      </Route>
    </Routes>
  </WholesaleViewProvider>
);

export default WholesaleView;
