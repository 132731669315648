import { GpsHistoryItemDeprec } from '@/interfaces';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import {
  Grid,
  GridColumn,
  GridColumnProps,
  GridNoRecords,
  GridPageChangeEvent,
  Loader,
} from '@progress/kendo-react-all';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GpsDevice } from './interfaces';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { systemService } from '@/services/systemService';
import { getRouteParamNum } from '@/utils/routing/formatting';
import { useGpsCtx } from './GpsProvider';

const columns: GridColumnProps[] = [
  {
    field: 'lastPing',
    title: 'Ping Date',
    cell: (props) => {
      const { lastPing } = props.dataItem as GpsHistoryItemDeprec;
      return (
        <td className="k-table-td">{formatDate(lastPing, { pattern: DateFormat.DateTime, utc: false })}</td>
      );
    },
  },
  {
    field: 'address',
    title: 'Address',
  },
  {
    field: 'latitude',
    title: 'Lat',
    width: 'auto',
  },
  {
    field: 'longitude',
    title: 'Long',
  },
  {
    field: 'odometer',
    title: 'Odometer',
  },
  {
    field: 'speed',
    title: 'Speed',
  },
  {
    field: 'locateType',
    title: 'Locate Type',
  },
];

export const GpsHistory = (props: { isInventory?: boolean, gpsDevice?: GpsDevice }) => {
  const { isInventory = false, gpsDevice } = props;
  const params = useParams();
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [loading, setLoading] = useState(false);
  const [gpsHistoryPaginated, setGpsHistoryPaginated] = useState<GpsHistoryItemDeprec[]>([]);
  const { gpsHistory, setGpsHistory } = useGpsCtx((s) => s);
  const [totalItems, setTotalItems] = useState(0);
  const { accountInformation } = useAccountSelector((state) => state);

  var paramId = isInventory ? params.invRecId : params.colRecId;
  const isPassTime = gpsDevice === GpsDevice.enum.PassTime;
  const isSpireon = gpsDevice === GpsDevice.enum.Spireon;
  const isIturan = gpsDevice === GpsDevice.enum.Ituran;
  const vehRecId = isInventory ? getRouteParamNum(params.invRecId) : accountInformation?.vehRecId;

  const getGpsHistory = async () => {
    setLoading(true);

    try {
      let gpsHistory: GpsHistoryItemDeprec[] = [];
      if(!vehRecId) return;

      if (isPassTime) {
        gpsHistory = await systemService.getPasstimeGpsHistory(vehRecId);
      }
      if (isSpireon) {
        gpsHistory = await systemService.getSpireonGpsHistory(vehRecId);
      }
      if (isIturan) {
        gpsHistory = await systemService.getIturanGpsHistory(vehRecId);
      }

      setGpsHistory(gpsHistory);
      setGpsHistoryPaginated(gpsHistory.slice(skip, take));
      setTotalItems(gpsHistory.length);
    } finally {
      setLoading(false);
    }
  };

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  useEffect(() => {
    if (gpsDevice) {
      if(gpsHistory && gpsHistory.length > 0) {
        setGpsHistoryPaginated(gpsHistory.slice(skip, (skip + take)));        
      } else {
        getGpsHistory();
      }
    }
  }, [skip, take]);

  useEffect(() => {
    if (gpsDevice) {
      getGpsHistory();
    }
  }, [paramId])

  return (
    <Grid
      data={gpsHistoryPaginated}
      skip={skip}
      take={take}
      total={totalItems}
      pageable={{
        buttonCount: 4,
        pageSizes: [10, 25, 50, 100],
      }}
      onPageChange={pageChange}
      style={{
        maxHeight: '100%',
      }}
    >
      <GridNoRecords>
        <div>{loading ? <Loader size="large" /> : 'No location history found'}</div>
      </GridNoRecords>
      {columns.map((column) => {
        return (
          <GridColumn
            field={column.field}
            title={column.title}
            key={column.field}
            width="auto"
            cell={column.cell}
          />
        );
      })}
    </Grid>
  );
};
