import { InventoryListRow } from '@/interfaces';
import { Column } from '@/components/table/TableInterface';
import {
  BoolCell,
  CurrencyCell,
  DateCell,
  PercentageCell,
  ServiceCell,
  LinkCell,
  InvDaysCell,
  VinCell,
} from './cellTypes';
import { InventorySubView } from '../state';

type TableColumnMapping = {
  [key in InventorySubView]: (Column & { field: keyof InventoryListRow })[];
};

// Define a common type for column objects
type InventoryListColumn = Column & { field: keyof InventoryListRow, flexGrow?: number, minWidth?: number };

export const columnPool: InventoryListColumn[] = [
  {
    field: 'stockNum',
    title: 'Stk. #',
    width: 90,
    cell: (props) => <LinkCell {...props} />,
  },
  {
    field: 'scOpen',
    title: 'SC',
    width: 70,
    cell: (props) => <ServiceCell {...props} />,
  },
  {
    field: 'trkGood',
    title: 'GPS',
    width: 80,
    cell: (props) => <BoolCell {...props} fieldCheck={'trkGood'} />,
  },
  {
    field: 'numTestDrives',
    title: 'TD',
    width: 70,
  },
  {
    field: 'samItems',
    title: 'SAM',
    width: 80,
  },
  {
    field: 'bgPrinted',
    title: 'BG',
    width: 70,
    cell: (props) => <BoolCell {...props} fieldCheck={'bgPrinted'} />,
  },
  {
    field: 'year',
    title: 'Year',
    width: 90,
  },
  {
    field: 'make',
    title: 'Make',
    width: 130,
  },
  {
    field: 'model',
    title: 'Model',
    width: 200,
  },
  {
    field: 'color',
    title: 'Color',
    width: 100,
  },
  {
    field: 'milesIn',
    title: 'Miles In',
    width: 110,
  },
  {
    field: 'vin',
    title: 'VIN',
    width: 200,
    cell: (props) => <VinCell {...props} />,
  },
  {
    field: 'invType',
    title: 'Type',
    width: 120,
  },
  {
    field: 'numPhotos',
    title: 'Photos',
    width: 90,
  },
  {
    field: 'invTime',
    title: 'Total Days',
    width: 120,
  },
  {
    field: 'recTime',
    title: 'Days Rec.',
    width: 100,
  },
  {
    field: 'locTime',
    title: 'Loc. Days',
    width: 100,
  },
  {
    field: 'code3',
    title: 'Lot',
    minWidth: 80, // Minimum width
    flexGrow: 1,  // Allow the column to expand and share available space
  },
  {
    field: 'saleDate',
    title: 'Pur. Date',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'purDate'} />,
  },
  {
    field: 'soldPrice',
    title: 'Sale Price',
    width: 120,
    cell: (props) => <CurrencyCell {...props} fieldCheck={'soldPrice'} />,
  },
  {
    field: 'ror',
    title: 'ROR',
    width: 100,
    cell: (props) => <PercentageCell {...props} fieldCheck={'ror'} />,
  },
  {
    field: 'realDown',
    title: 'Real Down',
    width: 120,
    cell: (props) => <CurrencyCell {...props} fieldCheck={'realDown'} />,
  },
  {
    field: 'liveOnline',
    title: 'Web',
    width: 70,
    cell: (props) => <BoolCell {...props} fieldCheck={'liveOnline'} />,
  },
  {
    field: 'purDate',
    title: 'Purchased',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'purDate'} />,
  },
  {
    field: 'vsStatus',
    title: 'Status',
    width: 120,
  },
  {
    field: 'buyerName',
    title: 'Buyer Name',
    width: 200,
  },
  {
    field: 'iopDate',
    title: 'IOP Date',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'iopDate'} />,
  },
  {
    field: 'dateIn',
    title: 'Date Available',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'dateIn'} />,
  },
  {
    field: 'chgOffCat',
    title: 'C/O Cat',
    width: 200,
  },
  {
    field: 'phyLocation',
    title: 'Phy. Location',
    width: 200,
  },
  {
    field: 'purFrom',
    title: 'Purchased From',
    width: 200,
  },
  {
    field: 'invDays',
    title: 'Days',
    width: 120,
  },
  {
    field: 'sugPrice',
    title: 'Suggested Price',
    width: 120,
    cell: (props) => <CurrencyCell {...props} fieldCheck={'sugPrice'} />,
  },
  {
    field: 'sugDown',
    title: 'Suggested Down',
    width: 120,
    cell: (props) => <CurrencyCell {...props} fieldCheck={'sugDown'} />,
  },
  {
    field: 'totCost',
    title: 'Total Cost',
    width: 120,
    cell: (props) => <CurrencyCell {...props} fieldCheck={'totCost'} />,
  },
  {
    field: 'saleType',
    title: 'Type',
    width: 120,
  },
  {
    field: 'day15',
    title: '15',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'day15'} />,
  },
  {
    field: 'day21',
    title: '21',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'day21'} />,
  },
  {
    field: 'day30',
    title: '30',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'day30'} />,
  },
  {
    field: 'day60',
    title: '60',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'day60'} />,
  },
  {
    field: 'titleStatus',
    title: 'Title',
    width: 120,
  },
  {
    field: 'purDate',
    title: 'Days',
    width: 60,
    cell: (props) => <InvDaysCell {...props} fieldCheck={'purDate'} />,
  },
  {
    field: 'titleOutD',
    title: 'Out',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'titleOutD'} />,
  },
  {
    field: 'ymm',
    title: 'Vehicle',
    width: 250,
  },
  {
    field: 'mvi',
    title: 'MVI',
    width: 120,
  },
  {
    field: 'colType',
    title: 'Type',
    width: 120,
  },
  {
    field: 'soldDate',
    title: 'Sold',
    width: 120,
    cell: (props) => <DateCell {...props} fieldCheck={'soldDate'} />,
  },
];

// Function to filter columns based on provided column names
const filterColumns = (columns: InventoryListColumn[], filter: string[]): InventoryListColumn[] => {
  // Create a map of field names to columns
  const columnMap: Record<string, InventoryListColumn> = {};
  for (const column of columns) {
    columnMap[column.field as string] = column;
  }

  // Filter columns based on the provided column names while maintaining the original order
  const filteredColumns: InventoryListColumn[] = [];
  for (const fieldName of filter) {
    const column = columnMap[fieldName];
    if (column) {
      filteredColumns.push(column);
    }
  }

  return filteredColumns;
};

const unsoldFields: (keyof InventoryListRow)[] = [
  'stockNum',
  'scOpen',
  'trkGood',
  'numTestDrives',
  'samItems',
  'bgPrinted',
  'year',
  'make',
  'model',
  'color',
  'milesIn',
  'vin',
  'invType',
  'locTime',
  'numPhotos',
  'invTime',
  'recTime',
  'code3',
  // Add more specific unsold column names if needed
];

const soldFields: (keyof InventoryListRow)[] = [
  'stockNum',
  'trkGood',
  'year',
  'make',
  'model',
  'milesIn',
  'vin',
  'saleType',
  'saleDate',
  'soldPrice',
  'invTime',
  'ror',
  'realDown',
  'code3',
  // Add more specific unsold column names if needed
];

const allFields: (keyof InventoryListRow)[] = [
  'stockNum',
  // 'liveOnline',
  'scOpen',
  'trkGood',
  'samItems',
  'year',
  'make',
  'model',
  'milesIn',
  'vin',
  'purDate',
  'saleDate',
  'invType',
  'vsStatus',
  'code3',
  'buyerName',
  // Add more specific unsold column names if needed
];

const repoFields: (keyof InventoryListRow)[] = [
  'stockNum',
  'trkGood',
  'samItems',
  'year',
  'make',
  'model',
  'color',
  'milesIn',
  'vin',
  'iopDate',
  'dateIn',
  'chgOffCat',
  'phyLocation',
  'code3',
  // Add more specific unsold column names if needed
];

const receivedFields: (keyof InventoryListRow)[] = [
  'stockNum',
  'samItems',
  'year',
  'make',
  'model',
  'milesIn',
  'vin',
  'purDate',
  'purFrom', // find
  'code3',
  // Add more specific unsold column names if needed
];

const retailFields: (keyof InventoryListRow)[] = [
  'stockNum',
  // 'liveOnline',
  'scOpen',
  'trkGood',
  'year',
  'make',
  'model',
  'milesIn', // find called mileage in alpha
  'vin',
  'invDays', // find
  'sugPrice', // find
  'sugDown', // find
  'totCost', // find
  'phyLocation',
  'code3',
  // Add more specific unsold column names if needed
];

const disposalFields: (keyof InventoryListRow)[] = [
  'stockNum',
  'year',
  'make',
  'model',
  'milesIn',
  'vin',
  'titleStatus', // find
  'purDate', // find
  'saleDate', // find
  'phyLocation', // find
  'code3',
  // Add more specific unsold column names if needed
];

const titleFields: (keyof InventoryListRow)[] = [
  'stockNum',
  'vin',
  'ymm', // find vehicle
  'titleOutD', // find
  'soldDate', // find
  'day15', // find
  'day21', // find
  'day30', // find
  'day45', // find
  'day60', // find
  'colType',
  'mvi', // find
  'code3',
  // Add more specific unsold column names if needed
];

// Define specific columns for unsold items
export const unsoldColumns: InventoryListColumn[] = filterColumns(columnPool, unsoldFields);

export const soldColumns: InventoryListColumn[] = filterColumns(columnPool, soldFields);

export const allColumns: InventoryListColumn[] = filterColumns(columnPool, allFields);

export const repoColumns: InventoryListColumn[] = filterColumns(columnPool, repoFields);

export const receivedColumns: InventoryListColumn[] = filterColumns(columnPool, receivedFields);

export const retailColumns: InventoryListColumn[] = filterColumns(columnPool, retailFields);

export const disposalColumns: InventoryListColumn[] = filterColumns(columnPool, disposalFields);

export const titleColumns: InventoryListColumn[] = filterColumns(columnPool, titleFields);

export const columnMappings: TableColumnMapping = {
  unsold: unsoldColumns,
  sold: soldColumns,
  iop: repoColumns,
  all: allColumns,
  alllotsretail: retailColumns,
  notReceived: receivedColumns,
  disposal: disposalColumns,
  titlelogs: titleColumns,
};
